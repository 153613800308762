import Trucker from './trucker'
import Appointment from './appointment'
import Clients from './clients'
import GeneralParameters from './generalParameters'

const API = {
    Trucker,
    Appointment,
    Clients,
    GeneralParameters
}
export default API;