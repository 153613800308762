import { combineReducers } from 'redux'
import appointment from './appointment'
import appointments from './appointments'
import generalParameters from './generalParameters'
import generalError from './generalError'

const rootReducer = combineReducers({
    appointment,
    appointments,
    generalParameters,
    generalError
})

export default rootReducer