export const SET_APPOINTMENT_FETCHING = 'SET_APPOINTMENT_FETCHING'
export const SET_APPOINTMENT_FETCHED = 'SET_APPOINTMENT_FETCHED'
export const SET_APPOINTMENT_PARAMETERS = 'SET_APPOINTMENT_PARAMETERS'
export const SET_NEW_APPOINTMENT_STEP = 'SET_NEW_APPOINTMENT_STEP'
export const SET_APPOINTMENT_TRUCKER = 'SET_APPOINTMENT_TRUCKER'
export const CLEAR_APPOINTMENT_TRUCKER = 'CLEAR_APPOINTMENT_TRUCKER'
export const CLEAR_APPOINTMENT_PARAMETERS = 'CLEAR_APPOINTMENT_PARAMETERS'
export const SET_APPOINTMENT_YARDID = 'SET_APPOINTMENT_YARDID'
export const CLEAR_APPOINTMENT_YARD = 'CLEAR_APPOINTMENT_YARD'
export const SET_APPOINTMENT_ATTACHMENTS = 'SET_APPOINTMENT_ATTACHMENTS'
export const SET_APPOINTMENT = 'SET_APPOINTMENT'
export const CLEAR_APPOINTMENT_ATTACHMENTS = 'CLEAR_APPOINTMENT_ATTACHMENTS'
export const DELETE_APPOINTMENT_ATTACHMENT = 'DELETE_APPOINTMENT_ATTACHMENT'
export const SET_APPOINTMENT_TIME_SLOT = 'SET_APPOINTMENT_TIME_SLOT'
export const SET_APPOINTMENT_DOCUMENTS_CONTACT = 'SET_APPOINTMENT_DOCUMENTS_CONTACT'


export function setFetchingAppointment() {
    return {
        type: SET_APPOINTMENT_FETCHING,
    }
}
export function setFetchedAppointment() {
    return {
        type: SET_APPOINTMENT_FETCHED,
    }
}



export function setAppointment(appointment) {
    return {
        type: SET_APPOINTMENT,
        appointment
    }
}

export function setAppointmentParameters(appointment) {
    return {
        type: SET_APPOINTMENT_PARAMETERS,
        appointment
    }
}

export function setNewAppointmentStep(step) {
    return {
        type: SET_NEW_APPOINTMENT_STEP,
        step
    }
}

export function setNewAppointmentTrucker(trucker) {
    return {
        type: SET_APPOINTMENT_TRUCKER,
        trucker
    }
}

export function setAppointmentYard(yardId) {
    return {
        type: SET_APPOINTMENT_YARDID,
        yardId
    }
}

export function clearAppointmentYard(yardId) {
    return {
        type: CLEAR_APPOINTMENT_YARD
    }
}

export function clearTrucker() {
    return {
        type: CLEAR_APPOINTMENT_TRUCKER
    }
}

export function clearAppointmentParameters() {
    return {
        type: CLEAR_APPOINTMENT_PARAMETERS
    }
}

export function addAppointmentAttachments(attachments) {
    return {
        type: SET_APPOINTMENT_ATTACHMENTS,
        attachments
    }
}

export function clearAppointmentAttachments() {
    return {
        type: CLEAR_APPOINTMENT_ATTACHMENTS
    }
}
export function deleteAppointmentAttachment(attachmentId) {
    return {
        type: DELETE_APPOINTMENT_ATTACHMENT,
        attachmentId
    }
}

export function setAppointmentTimeSlot(dateTimeSlot) {
    return {
        type: SET_APPOINTMENT_TIME_SLOT,
        dateTimeSlot
    }
}

export function setAppointmentDocumentsContact(contact){
    return {
        type:SET_APPOINTMENT_DOCUMENTS_CONTACT,
        contact
    }
}

