import React, { useState, useEffect } from 'react';
import {
    useDispatch,
    useSelector
} from 'react-redux'
import useReactRouter from 'use-react-router';
import { green } from '@material-ui/core/colors';
import {
    Close,
} from '@material-ui/icons';

import {
    Typography,
    Dialog,
    DialogContent,
    Button,
    Card,
    CardContent,
    IconButton,
    makeStyles
} from '@material-ui/core';


import TruckerForm from './TruckerForm'

const useStyles = makeStyles((theme) => ({
    subSections: theme.subSections,
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
        width: 'fit-content'
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    trucker__resume__content: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: 16,
        paddingBottom: '16px !important'
    },
    edit__dialog__paper: {
        width: '100%'
    },
    edit__dialog__buttons: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 20,
        marginBottom: 10
    },
    link: {
        cursor: 'pointer',
    },
    edit__dialog__content: {
        padding: '0'
    },
    dialog__header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline',
        padding: 16
    },
}));

const Trucker = (props) => {
    const { currentStep, readonly } = props,
        classes = useStyles(),
        { history, location } = useReactRouter(),
        appointment = useSelector(state => state.appointment),
        [appointmentFetched, setAppointmentFetched] = useState(false),
        [openEditPopup, setOpenEditPopup] = useState(false),
        [inputs, setInputs] = useState(appointment.trucker ? {
            camioneroNombre: appointment.trucker.firstName,
            camioneroApellido: appointment.trucker.lastName,
            camioneroCelular: appointment.trucker.phone
        } : {}),
        yardId = useSelector(state => state.appointment.yardId),
        dispatch = useDispatch(),
        createMode = currentStep === 1 ? true : false;

    useEffect(() => {
        if (!yardId) {
            history.push('/');
        }
        let queryParams = new URLSearchParams(location.search)

        if (queryParams.get("appointmentId") && appointment.trucker && !appointmentFetched) {
            setInputs({
                ...inputs,
                camioneroId: appointment.trucker.truckerId,
                camioneroCelular: appointment.trucker.phone,
                camioneroNombre: appointment.trucker.firstName,
                camioneroApellido: appointment.trucker.lastName
            })

            setAppointmentFetched(true);
        }
    }, [yardId, history, appointment, dispatch, inputs, location, appointmentFetched])

    if (!yardId) return null;



    const onExitedEdit = () => {
        if (appointment.trucker) {
            setInputs({
                ...inputs,
                camioneroNombre: appointment.trucker.firstName,
                camioneroApellido: appointment.trucker.lastName,
                camioneroCelular: appointment.trucker.phone
            })
        }
    }

    const cancelTruckerEdit = () => {
        setOpenEditPopup(false);
    }


    let trucker = (!appointment || (appointment && !appointment.trucker)) ? null : {
        name: `${appointment.trucker.firstName} ${appointment.trucker.lastName}`,
        phone: `${appointment.trucker.phone}`
    }


    if (createMode) {
        return (
            <TruckerForm {...props} createMode={createMode} />
        )
    } else {
        return (
            <>
                <Card className={classes.subSections}>
                    <CardContent className={classes.trucker__resume__content}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography color='textSecondary' style={{ marginBottom: 8 }}>
                                Conductor
                            </Typography>
                            {trucker && (
                                <h5 className="font-weight-normal" style={{ marginBottom: 0 }}>
                                    {`${trucker.name}`}
                                    <br />
                                    {`${trucker.phone}`}
                                </h5>
                            )}
                        </div>
                        {!readonly && (
                            <Button
                                size='small'
                                variant='outlined'
                                onClick={() => {
                                    setOpenEditPopup(true);
                                }}>
                                Cambiar
                            </Button>
                        )}
                    </CardContent>
                </Card>
                <Dialog
                    classes={{
                        paper: classes.edit__dialog__paper
                    }}
                    maxWidth='xs'
                    open={openEditPopup}
                    onExited={onExitedEdit}
                >
                    <div className={classes.dialog__header}>
                        <h5 className="font-weight-normal">
                            Cambiar camionero
                        </h5>
                        <IconButton
                            size='small'
                            onClick={cancelTruckerEdit}>
                            <Close />
                        </IconButton>
                    </div>
                    <DialogContent className={classes.edit__dialog__content}>
                        <TruckerForm {...props} createMode={createMode} onClose={cancelTruckerEdit} />
                    </DialogContent>
                </Dialog>
            </>
        )
    }
}

export default Trucker;