import React, { useEffect } from 'react';
import {
    useDispatch,
    useSelector
} from 'react-redux'
import useReactRouter from 'use-react-router';
import Button from '@material-ui/core/Button';
import { Row, Col } from 'reactstrap';
import { Divider } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core'

import {
    setAppointmentParameters,
    clearTrucker,
    clearAppointmentYard
} from 'actions/appointmentActions'

const useStyles = makeStyles((theme) => ({
    subSections: theme.subSections,
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    }
}));



const NewAppointment = (props) => {
    const classes = useStyles();
    const { setStep } = props;
    const theme = useTheme(),
        dispatch = useDispatch(),
        { history } = useReactRouter(),
        appointment = useSelector(state => state.appointment),
        dateTimeSlot = useSelector(state => state.appointment.dateTimeSlot),
        generalParameters = useSelector(state => state.generalParameters),
        yardId = useSelector(state => state.appointment.yardId);

    let { patios } = generalParameters;

    useEffect(() => {
        window.scrollTo(0, 0);
    })

    const backToNewAppointment = () => {
        dispatch(clearTrucker())
        dispatch(clearAppointmentYard())
        dispatch(setAppointmentParameters(null))
        setStep('');
        history.push(`/`)
    }

    if (!appointment || (appointment && !appointment.appointment) || !dateTimeSlot) return null

    let liner = appointment.appointment.linerCode ? generalParameters.liners.find(liner => liner.code === appointment.appointment.linerCode).name : '',
        operation = generalParameters.operationsTypes.find(opt => opt.code === appointment.appointment.operationType).name


    return <ThemeProvider theme={theme}>
        <section className="section  bg-light" style={{ paddingTop: 0, minHeight: '73vh', }}>
            <div className="container">

                <Row className="align-items-center">
                    <Col lg="3"></Col>
                    <Col lg="6">
                        <div className={`features-content ${classes.subSections}`}>
                            <div className="title-heading mb-5">
                                <h4 className="font-weight-normal text-dark mb-3 mt-4">Patio de {yardId ? patios.find(p => p.id === yardId).name : ''}</h4>
                                <h4 className="font-weight-normal text-dark mb-3 mt-4">Cita Confirmada</h4>
                                <div className="title-border-simple position-relative"></div>
                            </div>
                            <Typography variant='h6' style={{ marginBottom: 8 }}>
                                Su cita ha sido confirmada
                            </Typography>
                            <hr />
                        </div>
                    </Col>

                </Row>
                <Row>
                    <Col lg="3"></Col>
                    <Col lg="6">

                        <div className={classes.subSections} style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: '16px'
                        }}>
                            <Typography variant='h6' style={{ marginBottom: 0 }}>
                                {dateTimeSlot.day} - {dateTimeSlot.slot}
                            </Typography>
                            <Button size='small'
                                variant='outlined'
                                onClick={() => {
                                    setStep(4)
                                    history.push(`/appointment/step/4?appointmentId=${appointment.appointment.id}`)
                                }}>
                                Cambiar
                            </Button>
                        </div>

                        <div className={classes.subSections}>
                            <Typography color='textSecondary'>
                                Conductor
                            </Typography>
                            <Typography variant='h6'>
                                {`${appointment.trucker.firstName} ${appointment.trucker.lastName}`}
                                <br />
                                {`${appointment.trucker.phone}`}
                            </Typography>

                            <div style={{ margin: '1rem' }} />


                            <Typography variant='h6'  >
                                {`${liner ? liner : ''}
                            ${operation ? `- ${operation}` : ''} `}
                            </Typography>


                            {(appointment.appointment.operationType === 'expo' || appointment.appointment.operationType === 'impo') &&
                                <div style={{ marginTop: '8px' }}>
                                    {appointment.appointment.operationType === 'impo' &&
                                        <Typography variant='h6' style={{ marginBotom: '8px' }}>
                                            {'Comodato'}
                                        </Typography>
                                    }
                                    {appointment.appointment.containerNumber && (
                                        <Typography variant='body1'>
                                            {`${appointment.appointment.containerNumber && appointment.appointment.operationType === 'impo' ? appointment.appointment.containerNumber : ''} ${appointment.appointment.containerType ?
                                                appointment.appointment.containerType : appointment.appointment.containerType}${appointment.appointment.containerSize ? appointment.appointment.containerSize : appointment.appointment.containerSize}`}
                                        </Typography>
                                    )}


                                    {appointment.appointment.bookingBlNumber && (
                                        <Typography variant='body1'>
                                            <span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>{appointment.appointment.operationType === 'impo' ? 'BL#' : 'Booking Number'}</span> {` ${appointment.appointment.bookingBlNumber}`}
                                        </Typography>
                                    )}

                                    {appointment.appointment.returnYard && appointment.appointment.operationType === 'impo' && (
                                        <Typography variant='body1'>
                                            <span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Sitio de devolución</span> {` ${appointment.appointment.returnYard}`}
                                        </Typography>
                                    )}
                                    {appointment.appointment.clientName && appointment.appointment.clientRif && (
                                        <Typography variant='body1'>
                                            {` ${appointment.appointment.clientName} ${appointment.appointment.clientRif}`}
                                        </Typography>
                                    )}

                                    {appointment.appointment.operationType === 'impo' &&
                                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 16 }}>
                                            {appointment.attachments && appointment.attachments.filter(attach => attach.fileType === 'comodato').length > 0 ?
                                                appointment.attachments.filter(attach => attach.fileType === 'comodato').map((attach, i) => (
                                                    <Typography
                                                        key={i}
                                                        variant='body1'
                                                        noWrap
                                                        style={{ display: 'flex', alignItems: 'center' }}>

                                                        <a href={attach.fileUrlS3} title="Apointment Attach" target="_blank" rel="noopener noreferrer">
                                                            {decodeURI(attach.fileNameS3)}
                                                        </a>
                                                    </Typography>
                                                )) : (
                                                    <Button size='small'
                                                        variant='outlined'
                                                        style={{
                                                            marginLeft: 'auto',
                                                            width: 220,
                                                            borderColor: 'red'
                                                        }}
                                                        onClick={() => {
                                                            setStep(2)
                                                            history.push(`/appointment/step/2?appointmentId=${appointment.appointment.id}`)
                                                        }}>
                                                        Falta el Comodato
                                                    </Button>
                                                )}
                                        </div>
                                    }
                                </div>
                            }

                            {appointment.appointment.operationType === 'expo' && (
                                <div style={{ margin: '1rem' }} />
                            )}

                            {(appointment.appointment.operationType === 'expo' || appointment.appointment.operationType === 'repo') &&
                                <div>
                                    <Typography variant='h6' style={{ marginBotom: '8px' }}>
                                        {'Orden de cargue'}
                                    </Typography>

                                    <Typography variant='body1'>
                                        <span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Placa</span> {appointment.appointment.truckPlate}
                                    </Typography>


                                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 16 }}>
                                        {appointment.attachments && appointment.attachments.filter(attach => attach.fileType === 'ordenDeCargue').length > 0 ?
                                            appointment.attachments.filter(attach => attach.fileType === 'ordenDeCargue').map((attach, i) => (
                                                <Typography
                                                    key={i}
                                                    variant='body1'
                                                    noWrap
                                                    style={{ display: 'flex', alignItems: 'center' }}>

                                                    <a href={attach.fileUrlS3} title="Apointment Attach" target="_blank" rel="noopener noreferrer">
                                                        {decodeURI(attach.fileNameS3)}
                                                    </a>
                                                </Typography>
                                            )) : (
                                                <Button size='small'
                                                    variant='outlined'
                                                    style={{
                                                        width: 220,
                                                        marginLeft: 'auto',
                                                        borderColor: 'red'
                                                    }}
                                                    onClick={() => {
                                                        setStep(2)
                                                        history.push(`/appointment/step/2?appointmentId=${appointment.appointment.id}`)
                                                    }}>
                                                    Falta la Orden de Cargue
                                                </Button>
                                            )}
                                    </div>
                                </div>
                            }

                            {appointment.appointment.operationType === 'expo' && (
                                <div style={{ margin: '1rem' }} />
                            )}

                            {appointment.appointment.operationType === 'expo' &&
                                <div>
                                    <Typography variant='h6' style={{ marginBotom: '8px' }}>
                                        {'Carta Cliente'}
                                    </Typography>

                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        marginTop: 16
                                    }}>
                                        {appointment.attachments && appointment.attachments.filter(attach => attach.fileType === 'cartaCliente').length > 0 ?
                                            appointment.attachments.filter(attach => attach.fileType === 'cartaCliente').map((attach, i) => (
                                                <Typography
                                                    key={i}
                                                    variant='body1'
                                                    noWrap
                                                    style={{ display: 'flex', alignItems: 'center' }}>

                                                    <a href={attach.fileUrlS3} title="Apointment Attach" target="_blank" rel="noopener noreferrer">
                                                        {decodeURI(attach.fileNameS3)}
                                                    </a>
                                                </Typography>
                                            )) : (
                                                <Button size='small'
                                                    variant='outlined'
                                                    style={{
                                                        width: 220,
                                                        marginLeft: 'auto',
                                                        borderColor: 'red'
                                                    }}
                                                    onClick={() => {
                                                        setStep(2)
                                                        history.push(`/appointment/step/2?appointmentId=${appointment.appointment.id}`)
                                                    }}>
                                                    Falta la Carta Cliente
                                                </Button>
                                            )}
                                    </div>
                                </div>
                            }
                        </div>

                    </Col>
                </Row>

                <Row style={{ marginTop: 20 }}>
                    <Col lg="3"></Col>
                    <Col lg="6" className={classes.subSections}>
                        <Divider />
                        <Row className={classes.subSections} style={{ padding: '0 8px', justifyContent: 'flex-end' }}>
                            <div className={classes.root}>
                                <div className={classes.wrapper}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        style={{ width: 210 }}
                                        onClick={backToNewAppointment}
                                    >
                                        Finalizar
                                    </Button>
                                </div>
                            </div>

                        </Row>
                    </Col>
                    <Col lg="3"></Col>
                </Row>

            </div>
        </section>
    </ThemeProvider>
}

export default NewAppointment