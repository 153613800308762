import React, {
    useState,
    useEffect
} from 'react';
import {
    useDispatch,
    useSelector
} from 'react-redux'
import useReactRouter from 'use-react-router';
import { useForm } from "react-hook-form";
import SwipeableViews from 'react-swipeable-views';

import {
    Link,
    makeStyles,
    TableHead
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';

import {
    NavigateNext as NavigateNextIcon,
    // Close,
    KeyboardArrowLeft,
    Info,
    Search
} from '@material-ui/icons';

import {
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Button,
    CircularProgress,
    Card,
    CardContent,
    IconButton,
    Popover,
    Box,
    Tabs,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableContainer,
    withStyles,
    Divider
} from '@material-ui/core';

import { utils } from 'lib'

import API from 'services/api'


import {
    setNewAppointmentTrucker,
    setAppointmentParameters,
    clearTrucker,
} from 'actions/appointmentActions'


const useStyles = makeStyles((theme) => ({
    subSections: theme.subSections,
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
        width: 'fit-content'
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    trucker__resume__content: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: 16,
        paddingBottom: '16px !important'
    },
    edit__dialog__paper: {
        width: '100%'
    },
    edit__dialog__buttons: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 20,
        marginBottom: 10
    },
    link: {
        cursor: 'pointer',
    },
    form__create: {
        width: '100%'
    },
    find__trucker__container: {
        display: 'flex',
        alignItems: 'center',
    },

    truckers__title: {
        marginBottom: 15,
        marginTop: 10,
        textAlign: 'center'
    },
    truckers__container: {
        //maxWidth: 280,
        paddingTop: 10
    },
    trucker__row: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    'trucker__row--selected': {
        background: '#fffcec'
    },
    new__trucker__title: {
        marginBottom: 15,
        marginTop: 20
    },
    new__trucker__container: {
        //maxWidth: 280,
        paddingTop: 10
    },
    trucker__container: {
        // maxWidth: 280,
        padding: 10
    },
    popover: {
        padding: 10
    },
    tabPanel: {
        border: 'solid thin #ddd',
        minHeight: 200
    },
    tab: { border: 'solid thin #ddd' },
    goBack__container: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 10
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const TruckerForm = (props) => {
    const { setStep, currentStep, createMode } = props,
        classes = useStyles(),
        { handleSubmit, errors, setError, clearError } = useForm(),
        { history,
        } = useReactRouter(),
        [anchorEl, setAnchorEl] = useState(null),
        [loading, setLoading] = useState(false),
        [truckerSearched, setTruckerSearched] = useState(false),
        [truckersFound, setTruckersFound] = useState(null),
        [camioneroNuevo, setCamioneroNuevo] = useState(false),
        [confirmAppointmentCreation, setConfirmAppointmentCreation] = useState(false),
        [truckerSelected, setTruckerSelected] = useState(null),
        appointment = useSelector(state => state.appointment),
        [thereIsCamionero, setThereIsCamionero] = useState(appointment && appointment.trucker && appointment.trucker.truckerId ? true : false),
        [fieldsValidationErrors, setFieldsValidationErrors] = useState({}),
        [selectedTab, setSelectedTab] = useState(0),
        [currentView, setCurrentView] = useState(0),
        [inputs, setInputs] = useState(appointment.trucker ? {
            camioneroNombre: appointment.trucker.firstName,
            camioneroApellido: appointment.trucker.lastName,
            camioneroCelular: appointment.trucker.phone
        } : {}),
        yardId = useSelector(state => state.appointment.yardId),
        dispatch = useDispatch();

    //const trucker = appointment?.trucker;

    const open = Boolean(anchorEl);

    const handleChangeTab = (event, newValue) => {
        setSelectedTab(newValue);
    }

    useEffect(() => {
        if (!createMode && appointment?.trucker) {
            // setTruckerSearched(true)
            // setTruckerSelected({ ...appointment.trucker, id: appointment.trucker.truckerId });
            // setTruckersFound([{ ...appointment.trucker, id: appointment.trucker.truckerId }]);
        }
    }, [appointment, createMode])

    const handleClickInfo = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseInfo = () => {
        setAnchorEl(null);
    };

    const validateSearchInput = () => {
        let valid = true;
        clearError('search')
        clearError('camioneroCelular')
        setFieldsValidationErrors({
            search: null
        })

        if (!inputs?.search?.trim() || inputs?.search?.trim() === '') {
            valid = false;
            setError('search')
        }
        if (inputs?.search?.trim().length < 3) {
            valid = false;
            setError('search')
            setFieldsValidationErrors({
                search: "Ingrese al menos 3 caracteres"
            })
        }


        if (utils.stringIsANumber(inputs?.search?.trim())) {
            let validPhone = utils.phoneIsValid(inputs.search.trim()) ? true : false;
            valid = validPhone;
            if (!validPhone) {
                setError('search');
                setFieldsValidationErrors({
                    search: "Ingrese a celular válido"
                })
            }
        }
        return valid;
    }

    const validateTruckerForm = () => {
        let valid = true;
        if (!inputs?.camioneroApellido?.trim() || inputs?.camioneroApellido?.trim() === '') {
            valid = false;
            setError('camioneroApellido')
        }
        if (!inputs?.camioneroNombre?.trim() || inputs?.camioneroNombre?.trim() === '') {
            valid = false;
            setError('camioneroNombre')
        }
        if (!inputs.camioneroCelular || !utils.phoneIsValid(inputs.camioneroCelular)) {
            valid = false;
            setError('camioneroCelular')
        }
        return valid;
    }

    const searchTrucker = () => {
        if (validateSearchInput()) {
            setLoading(true);
            setInputs({
                ...inputs,
                camioneroCelular: null,
                camioneroNombre: null,
                camioneroApellido: null,
            })
            setCamioneroNuevo(false)
            setTruckerSelected(null)
            API.Trucker.searchTrucker({ search: inputs.search })
                .then(response => {
                    setTruckerSearched(true);
                    if (response.success) {

                        setTruckersFound(response.truckers)
                        /*if (response.truckers.length === 1) {
                            setThereIsCamionero(true)
                            setTruckerSelected(response.truckers[0])
                            dispatch(setNewAppointmentTrucker({ ...response.truckers[0], truckerId: response.truckers[0].id }))
                            setInputs({
                                ...inputs,
                                camioneroCelular: response.truckers[0].phone,
                                camioneroNombre: response.truckers[0].firstName,
                                camioneroApellido: response.truckers[0].lastName
                            })
                            //setCurrentView(1)
                        } else */
                        if (response.truckers.length === 0) {
                            if (utils.stringIsANumber(inputs?.search?.trim()) && utils.phoneIsValid(inputs.search.trim())) {
                                setTruckerSelected({
                                    phone: inputs.search.trim()
                                })
                                setInputs({
                                    ...inputs,
                                    camioneroCelular: inputs.search.trim()
                                })
                            } else {
                                setTruckerSelected({})
                            }
                            setThereIsCamionero(false)
                            setCamioneroNuevo(true)
                        }
                    } else {
                        console.log(response)
                    }
                })
                .catch(err => {
                    console.log(err)
                })
                .then(_ => {
                    setLoading(false);
                })
        }
    }

    const findTruckerByPhone = () => {
        if (inputs.camioneroCelular && utils.phoneIsValid(inputs.camioneroCelular)) {
            setLoading(true);
            API.Trucker.validateTrucker({
                phone: inputs.camioneroCelular
            })
                .then(response => {
                    if (response.success) {
                        dispatch(setNewAppointmentTrucker({ ...response.trucker, truckerId: response.trucker.id }))
                        setThereIsCamionero(true)
                        setInputs({
                            ...inputs,
                            camioneroCelular: inputs.camioneroCelular,
                            camioneroNombre: response.trucker.firstName,
                            camioneroApellido: response.trucker.lastName
                        })
                    }
                }).catch(err => {
                    console.log(err)
                })
                .then(_ => {
                    setLoading(false);
                })
        }
    }

    const onSubmitStep1 = data => {
        if (validateTruckerForm()) {

            if (camioneroNuevo || thereIsCamionero) {
                return setConfirmAppointmentCreation(true);
            }

            if (!loading) {
                setLoading(true);
                API.Trucker.validateTrucker({
                    phone: inputs.camioneroCelular
                })
                    .then(response => {
                        setLoading(false);
                        if (response.success) {
                            dispatch(setNewAppointmentTrucker({ ...response.trucker, truckerId: response.trucker.id }))
                            if (!appointment.appointment || (appointment.appointment && !appointment.appointment.id)) {
                                setThereIsCamionero(true)
                                setInputs({
                                    ...inputs,
                                    camioneroCelular: inputs.camioneroCelular,
                                    camioneroNombre: response.trucker.firstName,
                                    camioneroApellido: response.trucker.lastName
                                })
                            } else {
                                setStep(2)
                                history.push(`/appointment/step/2?appointmentId=${appointment.appointment.id}`)
                            }
                        } else {
                            dispatch(clearTrucker())
                            setCamioneroNuevo(true)
                        }
                    }).catch(err => {
                        console.log(err)
                    })
                    .then(_ => {
                        setLoading(false);
                    })
            }
        }
    }


    const createNewCamionero = () => {
        setLoading(true);

        API.Trucker.createTrucker({
            phone: inputs.camioneroCelular,
            firstName: inputs.camioneroNombre,
            lastName: inputs.camioneroApellido
        })
            .then(response => {
                setLoading(false);
                if (response.success) {
                    setCamioneroNuevo(false)
                    dispatch(setNewAppointmentTrucker({ ...response.trucker, truckerId: response.trucker.id }))

                    //si no existe un appointment lo creo
                    if (!appointment.appointment) {
                        createAppointment({ trucker: response.trucker })
                    } else {
                        //si existe un appointment, le cambio el camionero
                        updateTruckerAppointment(response.trucker.truckerId)
                    }
                }
            }).catch(err => {
                console.log(err)
            })
            .then(_ => {
                setLoading(false);
            })
    }


    const updateTruckerAppointment = (truckerId) => {
        setLoading(true);
        API.Appointment.updateAppointment({
            appointmentId: appointment.appointment.id,
            parameters: {
                ...appointment.appointment,
                truckerId: truckerId ? truckerId : appointment.trucker.truckerId
            }
        })
            .then(response => {
                if (response.success) {
                    dispatch(setAppointmentParameters(response.appointment))
                    setConfirmAppointmentCreation(false);

                    //Si estamos en el paso 1 y agergamos o actualizamos el camionero, vamos al paso 2
                    if (currentStep === 1) {
                        setStep(2);
                        history.push(`/appointment/step/2?appointmentId=${response.appointment.id}`)
                    }
                }
            }).catch(err => {
                console.log(err)
            })
            .then(_ => {
                setLoading(false);
            })
    }

    const createAppointment = (e) => {
        setLoading(true);
        API.Appointment.createAppointment({
            yardId,
            camioneroId: e && e.trucker ? e.trucker.truckerId : appointment.trucker.truckerId
        })
            .then(response => {
                if (response.success) {
                    dispatch(setAppointmentParameters(response.appointment))
                    setConfirmAppointmentCreation(false);
                    setStep(2);
                    history.push(`/appointment/step/2?appointmentId=${response.appointment.id}`)
                }
            }).catch(err => {
                console.log(err)
            })
            .then(_ => {
                setLoading(false);
            })

    }

    const handlerAppointment = () => {
        if (camioneroNuevo) {
            return createNewCamionero()
        } else {
            if (!appointment.appointment) {
                createAppointment(truckerSelected && truckerSelected.truckerId ? { trucker: { ...truckerSelected, truckerId: truckerSelected.truckerId } } : null)
            } else {
                updateTruckerAppointment()
            }
        }
    }

    const renderPhoneFieldComponent = () => {
        return (
            <div>
                <TextField
                    variant="outlined"
                    style={{
                        width: 180,
                    }}
                    label="Celular"
                    name="camioneroCelular"
                    onChange={(e) => {
                        clearError('camioneroCelular')
                        let value = e.target.value;
                        if (utils.stringIsANumber(value) && value.length > 10) return
                        setInputs({
                            ...inputs,
                            camioneroCelular: value
                        })
                    }}
                    fullWidth
                    type='number'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onBlur={findTruckerByPhone}
                    disabled={loading}
                    onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                    }}
                    value={inputs.camioneroCelular}
                    error={errors.camioneroCelular || errors.celularInvalido ? true : false}
                />
                {errors.camioneroCelular && inputs.camioneroCelular && (
                    <div style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <Typography color='error' variant='body2'>
                            Ingrese un celular válido
                        </Typography>
                        <Info style={{
                            color: '#ccc',
                            fontSize: 16,
                            marginLeft: 5,
                            cursor: 'pointer'
                        }}
                            onClick={handleClickInfo} />
                        <Popover
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleCloseInfo}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <Typography variant='body2'
                                color='textSecondary'
                                className={classes.popover}>
                                - Ingrese un número de teléfono válido<br />
                                - El teléfono debe comenzar por 3<br />
                                - El número debe tener 10 dígitos<br />
                            </Typography>
                        </Popover>
                    </div>
                )}
            </div>
        )
    }
    const renderFirstLastNameFieldsComponent = () => {
        return (
            <div style={{ display: 'flex' }}>
                <TextField
                    variant="outlined"
                    label="Nombre"
                    name="camioneroNombre"
                    placeholder=""
                    fullWidth
                    onChange={(e) => {
                        clearError('camioneroNombre')
                        setInputs({
                            ...inputs,
                            camioneroNombre: e.target.value
                        })
                    }}
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={inputs.camioneroNombre}
                    error={errors.camioneroNombre}
                />
                <TextField
                    variant="outlined"
                    style={{
                        marginLeft: 5,
                    }}
                    label="Apellido"
                    name="camioneroApellido"
                    onChange={(e) => {
                        clearError('camioneroApellido')
                        setInputs({
                            ...inputs,
                            camioneroApellido: e.target.value
                        })
                    }}
                    placeholder=""
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={inputs.camioneroApellido}
                    error={errors.camioneroApellido}
                />
            </div>
        )
    }

    const updateTrucker = () => {
        setFieldsValidationErrors({
            celular: null
        })

        if (!inputs.camioneroCelular || !utils.phoneIsValid(inputs.camioneroCelular)) {
            setFieldsValidationErrors({
                celular: "Ingrese un celular válido"
            })
            return
        }
        if (!inputs.camioneroNombre) {
            setError("camioneroNombre", "invalid", "")
            return
        }
        if (!inputs.camioneroApellido) {
            setError("camioneroApellido", "invalid", "")
            return
        }
        setLoading(true);
        API.Trucker.saveTrucker({
            phone: inputs.camioneroCelular,
            firstName: inputs.camioneroNombre,
            lastName: inputs.camioneroApellido
        }).then(response => {
            setLoading(false);
            if (response.success) {
                dispatch(setNewAppointmentTrucker({ ...response.trucker, truckerId: response.trucker.id }))
                if (appointment.appointment) {
                    updateTruckerAppointment(response.trucker.truckerId)
                }
                props.onClose()
                // cancelTruckerEdit();
            }
        }).catch(err => {
            console.log(err)
        }).then(_ => {
            setLoading(false);
        })
    }


    const renderNewTruckerLinkComponent = () => {
        return (
            <div className={classes.trucker__row}>
                <div>
                    <Link href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            setInputs({
                                ...inputs,
                                camioneroNombre: "",
                                camioneroApellido: "",
                                camioneroCelular: ""
                            })
                            setTruckerSelected({})
                            dispatch(setNewAppointmentTrucker(null))
                            setThereIsCamionero(false)
                            setCamioneroNuevo(true);
                            setCurrentView(1);
                        }}>
                        Crear nuevo camionero
                        </Link>
                </div>
            </div>
        )
    }

    const renderTruckerForm = () => (
        <form onSubmit={truckerSearched && createMode ?
            handleSubmit(onSubmitStep1) :
            (!createMode ? handleSubmit(updateTrucker) :
                (e) => e.preventDefault())}
            className={classes.form__create}
            autoComplete="off">
            <SwipeableViews
                axis={'x'}
                index={currentView}
            >
                <div style={{
                    height: currentView === 0 ? 'auto' : 0,
                    marginTop: 10,
                }}>
                    <div>
                        <div className={classes.find__trucker__container}>
                            <TextField
                                variant="outlined"
                                style={{
                                    width: 180,
                                }}
                                label="Celular o Apellido"
                                name="search"
                                onChange={(e) => {
                                    let value = e.target.value;

                                    if (utils.stringIsANumber(value) && value.length > 10) return

                                    setInputs({
                                        ...inputs,
                                        search: value,
                                    })
                                }}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onKeyPress={ev => {
                                    if (ev.key === 'Enter') {
                                        ev.preventDefault();
                                        searchTrucker();
                                    }
                                }}
                                disabled={loading}
                                value={inputs.search}
                                error={errors.search ? true : false}
                            />
                            <IconButton
                                style={{ marginLeft: 5 }}
                                size='small'
                                disabled={loading}

                                onClick={searchTrucker}>
                                {!loading ? <Search /> : <CircularProgress size={20} />}
                            </IconButton>
                        </div>
                        {fieldsValidationErrors.search && (
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: 10
                            }} >
                                <Typography color='error' variant='body2'>
                                    {fieldsValidationErrors.search}
                                </Typography>
                                <Info style={{
                                    color: '#ccc',
                                    fontSize: 16,
                                    marginLeft: 5,
                                    cursor: 'pointer'
                                }}
                                    onClick={handleClickInfo} />
                                <Popover
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleCloseInfo}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                >
                                    <Typography variant='body2'
                                        color='textSecondary'
                                        className={classes.popover}>
                                        - Ingrese un número de teléfono válido<br />
                                        - El teléfono debe comenzar por 3<br />
                                        - El número debe tener 10 dígitos<br />
                                        - O ingrese al menos 3 caracteres del apellido
                                    </Typography>
                                </Popover>
                            </div>
                        )}
                    </div>


                    {truckerSearched && (
                        <>

                            {truckersFound?.length >= 1 && (
                                <div className={classes.truckers__container}>
                                    <Typography variant='body1'
                                        className={classes.truckers__title}>
                                        Seleccione el camionero para la cita
                                    </Typography>

                                    <TableContainer style={{ border: 'solid thin #ccc' }}>
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        Nombre
                                                    </TableCell>
                                                    <TableCell>
                                                        Teléfono
                                                    </TableCell>
                                                    <TableCell>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {truckersFound.map((trucker, i) => (
                                                    <StyledTableRow key={i}>
                                                        <TableCell>
                                                            {`${trucker.firstName} ${trucker.lastName}`}<br />
                                                            <Typography color='textSecondary' variant='body2'>
                                                                {trucker.appointmentsCount?`${trucker.appointmentsCount} citas`:'Sin citas'}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            {trucker.phone}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Link href="#"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setInputs({
                                                                        ...inputs,
                                                                        camioneroNombre: trucker.firstName,
                                                                        camioneroApellido: trucker.lastName,
                                                                        camioneroCelular: trucker.phone
                                                                    })
                                                                    setTruckerSelected(trucker)
                                                                    dispatch(setNewAppointmentTrucker({
                                                                        ...trucker,
                                                                        truckerId: trucker.truckerId
                                                                    }))
                                                                    setThereIsCamionero(true)
                                                                    setCamioneroNuevo(false);
                                                                    setCurrentView(1);
                                                                }}>
                                                                Seleccionar
                                                            </Link>
                                                        </TableCell>
                                                    </StyledTableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                    <Typography className={classes.truckers__title} style={{ textAlign: 'left', marginTop: 20 }}>
                                        Si el camionero no existe, cree un nuevo registro de camionero:
                                    </Typography>

                                    {renderNewTruckerLinkComponent()}

                                </div>
                            )}
                            {truckersFound?.length === 0 && (
                                <div className={classes.truckers__container}>
                                    <Typography className={classes.truckers__title} style={{ textAlign: 'left', marginTop: 20 }}>
                                        No se encontro ningun camionero, cree un nuevo registro de camionero:
                                    </Typography>

                                    {renderNewTruckerLinkComponent()}
                                </div>
                            )}


                        </>
                    )}
                </div>

                <div style={{
                    height: currentView === 1 ? 'auto' : 0
                }}>
                    <div className={classes.goBack__container} >
                        <IconButton size='small' style={{ marginRight: 5 }} onClick={() => {
                            setCurrentView(0);
                        }}>
                            <KeyboardArrowLeft />
                        </IconButton>
                        <Typography>Buscar otro camionero</Typography>
                    </div>
                    <Divider />
                    {camioneroNuevo && (
                        <div className={classes.new__trucker__container}>
                            <Typography variant='body1' className={classes.new__trucker__title}>
                                Registrar nuevo camionero
                            </Typography>

                            {truckerSelected && truckerSelected.phone ?
                                (
                                    <Typography color='textPrimary' variant='body1'>
                                        {truckerSelected.phone}
                                    </Typography>
                                ) :
                                (renderPhoneFieldComponent())
                            }
                            {renderFirstLastNameFieldsComponent()}
                        </div>
                    )}

                    {truckerSelected && truckerSelected.truckerId && (
                        <div className={classes.trucker__container}>
                            <Typography color='textSecondary' style={{
                                marginBottom: 20
                            }}>
                                Camionero seleccionado para cita:
                            </Typography>
                            <h5 className="font-weight-normal">
                                {inputs.camioneroNombre} {inputs.camioneroApellido}
                            </h5>
                            <h5 className="font-weight-normal">
                                {inputs.camioneroCelular}
                            </h5>
                            {truckerSelected.appointmentsCount && (
                                <Typography variant='body1'>
                                    {`Tiene ${truckerSelected.appointmentsCount} citas registradas`}<br />
                                    {`Placa camión ${truckerSelected.licensePlate}`}<br />
                                </Typography>
                            )}
                        </div>
                    )}
                    {(truckerSelected) && createMode && (
                        <div className={classes.root}>
                            <div className={classes.wrapper} style={{
                                margin: 0,
                                marginTop: 10
                            }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{ width: 210 }}
                                    disabled={loading}
                                    type="submit"
                                    endIcon={<NavigateNextIcon />}>
                                    Continuar
                            </Button>
                                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </div>
                        </div>
                    )}
                </div>
            </SwipeableViews>

            {(truckerSelected) && !createMode && (
                <div className={classes.edit__dialog__buttons}>
                    <Button
                        disabled={loading}
                        variant='outlined'
                        onClick={() => {
                            props.onClose && props.onClose();
                        }}>
                        Cancelar
                    </Button>
                    <Button
                        disabled={loading}
                        variant='contained'
                        color='primary'
                        type='submit'>
                        Guardar
                    </Button>
                </div>
            )}
        </form>
    );

    return (
        <>
            {createMode && (
                <>
                    <Card className={classes.subSections}>
                        <CardContent className={classes.trucker__resume__content}>
                            {renderTruckerForm()}
                        </CardContent>
                    </Card>

                    <Dialog
                        open={confirmAppointmentCreation}
                        onClose={() => {
                            !loading && setConfirmAppointmentCreation(false);
                        }}
                    >
                        <DialogTitle id="alert-dialog-title">
                            Confirmar datos de camionero
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {(camioneroNuevo || (appointment.trucker && !appointment.trucker.phoneConfirmedSMS)) && <>
                                    Invitaremos al camionero a confirmar su número de celular.
                                    <br />
                                    <br />
                                </>}

                                {`Desea continuar el proceso para el camionero ${appointment.trucker ? appointment.trucker.firstName : inputs.camioneroNombre} ${appointment.trucker ? appointment.trucker.lastName : inputs.camioneroApellido}, con el número celular ${appointment.trucker ? appointment.trucker.phone : inputs.camioneroCelular}?`}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                disabled={loading}
                                onClick={() => {
                                    setConfirmAppointmentCreation(false);
                                }} color="primary">
                                No, cancelar
                        </Button>
                            <Button
                                disabled={loading}
                                onClick={handlerAppointment}
                                color="primary"
                                autoFocus>
                                Confirmar
                            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            )}
            {!createMode && (
                <>
                    <Tabs
                        value={selectedTab}
                        onChange={handleChangeTab}
                        indicatorColor="primary"
                        textColor="primary"
                    //variant="fullWidth"
                    >
                        <Tab label='Actualizar'
                            value={0}
                            className={classes.tab} />
                        <Tab label='Cambiar'
                            value={1}
                            className={classes.tab} />
                    </Tabs>


                    <TabPanel value={selectedTab}
                        index={0}
                        className={classes.tabPanel}>
                        <div>
                            <form onSubmit={handleSubmit(updateTrucker)} autoComplete="off">
                                {renderPhoneFieldComponent()}
                                {renderFirstLastNameFieldsComponent()}
                                <div className={classes.edit__dialog__buttons}>
                                    <Button
                                        disabled={loading}
                                        variant='outlined'
                                        onClick={() => {
                                            props.onClose && props.onClose();
                                        }}>
                                        Cancelar
                                    </Button>
                                    <Button
                                        disabled={loading}
                                        variant='contained'
                                        color='primary'
                                        type='submit'>
                                        Actualizar
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </TabPanel>

                    <TabPanel value={selectedTab}
                        index={1}
                        className={classes.tabPanel}>
                        <div>
                            <div>
                                {renderTruckerForm()}
                            </div>
                        </div>
                    </TabPanel>

                </>
            )}
        </>

    )
}


export default TruckerForm;