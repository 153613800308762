import React from 'react'

export default function Help() {
    return (
        <section
            style={{ minHeight: '100vh' }}>
            <div style={{
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex'
            }}>
                <img src="/images/pcc_logo_whitebg.png" alt="Patio de Contenedores de Colombia Logo" height="80" />
            </div>
            <div style={{
                paddingTop: 5,
                paddingRight : 15,
                paddingLeft: 15,
                paddingBottom: 20,
                maxWidth: 430,
                margin: 'auto'
            }}>    <h5>Documentación Electrónica PCC</h5>
                <br /> 
                <p>
                    PCC Patios de Contenedores lo invita a que nos envíe digitalmente toda la documentación de su cita.
                </p>
                <p>
                    Este nuevo proceso permitirá que resolvamos cualquier novedad antes de su llegada al patio, y así agilizar su tiempo de atención.
                </p>
                <p>
                    Envíenos las fotos requeridas, y nuestro personal comenzará la revisión de la documentación.
                </p>
            </div>
        </section>
    )
}
