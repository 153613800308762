import { createStore, compose } from 'redux'
import rootReducer from './reducers/rootReducer'

const defaultState = {
    appointment: {},
    appointments: [],
    generalError: {},
    generalParameters: {
        liners: [
            { code: 'MSK', name: 'MAERSK' },
            { code: 'SEA', name: 'MAERSK SEALAND' },
            { code: 'SUD', name: 'HAMBURG SUD' },
            { code: 'EVE', name: 'EVERGREEN SHIPPING AGENCY' },
            { code: 'ONE', name: 'OCEAN NETWORK EXPRESS' },
            { code: 'PIL', name: 'PIL-PACIFIC INTERNATIONAL LINE' },
        ],
        sizes: [
            { code: '20', name: '20' },
            { code: '40', name: '40' },
        ],
        operationsTypes: [
            { code: 'impo', name: 'Importación' },
            { code: 'expo', name: 'Exportación' },
            { code: 'repo', name: 'Reposición' }
        ],
        containerTypes: [
            { name: 'DR20', code: 'DR20', type: 'DR', size: '20' },
            { name: 'DR40', code: 'DR40', type: 'DR', size: '40' },
            { name: 'HC20', code: 'HC20', type: 'HC', size: '20' },
            { name: 'HC40', code: 'HC40', type: 'HC', size: '40' },
            { name: 'RF20', code: 'RF20', type: 'RF', size: '20' },
            { name: 'RF40', code: 'RF40', type: 'RF', size: '40' },
            { name: 'RH20', code: 'RH20', type: 'RH', size: '20' },
            { name: 'RH40', code: 'RH40', type: 'RH', size: '40' },

        ],
        containerStatuses: [
            { name: 'Daño Express', value: 1, id: 1, active: false },
            { name: 'Daño Taller', value: 2, id: 2, active: false },
            { name: 'Grado C', value: 3, id: 3, active: false },
            { name: 'Apto Alimentos', value: 4, id: 4, active: true },
            { name: 'Carga General', value: 5, id: 5, active: true },
        ],
        patios: [
            {
                name: 'Bogotá',
                id: 1,
                disabled: true
            },
            {
                name: 'Cali',
                id: 4,
                disabled: true
            },
            {
                name: 'Medellín',
                id: 5,
                disabled: true
            },
            {
                name: 'Pruebas',
                id: 3,
                disabled: false
            }
        ],
        cancelationTypes: [
            { value: 1, name: 'Camionero enfermo' },
            { value: 2, name: 'Camión dañado' },
            { value: 3, name: 'Cancelado por cliente' },
            { value: 4, name: 'Desastre natural' },
            { value: 5, name: 'Imposibilidad de asistir a la cita' },
            { value: 6, name: 'Otro.' },
        ],
        documentTypes: [
            { value: 'comodato', name: 'Comodato' },
            { value: 'ordenDeCargue', name: 'Orden de cargue' },
            { value: 'cartaCliente', name: 'Carta cliente' },
            { value: 'placaCamion', name: 'Placa camión' },
        ]
    }
}

const enhancers = compose(
    window.window.__REDUX_DEVTOOLS_EXTENSION__ ? window.window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
);


const store = createStore(
    rootReducer,
    defaultState,
    enhancers);
export default store;