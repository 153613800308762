import config from 'config';
import { utils } from 'lib'
const Trucker = {
    validateUser: () => {
        if (!localStorage.getItem('pcc_account_id')) {
            localStorage.removeItem('pcc_jwt_token')
            localStorage.removeItem('pcc_account_id')
            localStorage.removeItem('pcc_account_transportador_id')
            localStorage.removeItem('pcc_account_profileId')
            return window.location.href = `${config.web.url}`;
        }
        return true;
    },
    searchTrucker: ({ search }) => {
        Trucker.validateUser();
        return new Promise((resolve, reject) => {
            var headers = new Headers()
            headers.append("x-api-key", config.apiGateway.api_key);
            headers.append("Authorization", localStorage.getItem('pcc_jwt_token'));
            headers.append("x-user-id", localStorage.getItem('pcc_account_id'));
            let transportistaId = localStorage.getItem('pcc_account_transportador_id');
            let url = `${config.apiGateway.url}/v1/citas/camionero?search=${search}&transportadorId=${transportistaId}`;

            fetch(url, {
                headers
            }).then(function (response) {
                if (response.ok) {
                    return response.json();
                }

                switch (response.status) {
                    case 401:
                    case 403:
                        window.location.href = '/sessionExpired';
                        break;
                    default:
                        console.log("Response error", response)
                        break;
                }
            }).then(function (jsonResponse) {
                utils.validateAndShowApiError(jsonResponse)
                resolve(jsonResponse);
            }).catch(err => {
                utils.showGeneralError({ message: err.message || "Unhandled error", error: err });
                reject(err);

            });
        })
    },
    validateTrucker: ({ phone }) => {
        Trucker.validateUser();
        return new Promise((resolve, reject) => {

            let url = `${config.apiGateway.url}/v1/citas/camionero/${phone}`;
            var headers = new Headers()
            headers.append("x-api-key", config.apiGateway.api_key);
            headers.append("Authorization", localStorage.getItem('pcc_jwt_token'));
            headers.append("x-user-id", localStorage.getItem('pcc_account_id'));

            fetch(url, {
                headers
            }).then(function (response) {
                if (response.ok) {
                    return response.json();
                }

                switch (response.status) {
                    case 401:
                    case 403:
                        window.location.href = '/sessionExpired';
                        break;
                    default:
                        console.log("Response error", response)
                        break;
                }
            }).then(function (jsonResponse) {
                utils.validateAndShowApiError(jsonResponse)
                resolve(jsonResponse);
            }).catch(err => {
                utils.showGeneralError({ message: err.message || "Unhandled error", error: err });
                reject(err);

            });
        })
    },
    createTrucker: ({ firstName, lastName, phone }) => {
        Trucker.validateUser();
        return new Promise((resolve, reject) => {

            let url = `${config.apiGateway.url}/v1/citas/camionero`;
            var headers = new Headers()

            headers.append("x-api-key", config.apiGateway.api_key);
            headers.append("Authorization", localStorage.getItem('pcc_jwt_token'));
            headers.append("x-user-id", localStorage.getItem('pcc_account_id'));

            let parameters = {
                firstName,
                lastName,
                phone
            };

            fetch(url, {
                headers,
                body: JSON.stringify(parameters),
                method: 'POST',
            })
                .then(function (response) {
                    if (response.ok) {
                        return response.json();
                    }

                    switch (response.status) {
                        case 401:
                        case 403:
                            window.location.href = '/sessionExpired';
                            break;
                        default:
                            console.log("Response error", response)
                            break;
                    }
                })
                .then(function (jsonResponse) {
                    utils.validateAndShowApiError(jsonResponse)
                    resolve(jsonResponse);
                }).catch(err => {
                    utils.showGeneralError({ message: err.message || "Unhandled error", error: err });
                    reject(err);
                });
        })
    },
    updateTrucker: ({ truckerId, firstName, lastName, phone }) => {
        Trucker.validateUser();
        return new Promise((resolve, reject) => {

            let url = `${config.apiGateway.url}/v1/citas/camionero/details/${truckerId}`;
            var headers = new Headers()

            headers.append("x-api-key", config.apiGateway.api_key);
            headers.append("Authorization", localStorage.getItem('pcc_jwt_token'));
            headers.append("x-user-id", localStorage.getItem('pcc_account_id'));

            let parameters = {
                firstName,
                lastName,
                phone,
            };

            fetch(url, {
                headers,
                body: JSON.stringify(parameters),
                method: 'PUT',
            })
                .then(function (response) {
                    if (response.ok) {
                        return response.json();
                    }

                    switch (response.status) {
                        case 401:
                        case 403:
                            window.location.href = '/sessionExpired';
                            break;
                        default:
                            console.log("Response error", response)
                            break;
                    }
                })
                .then(function (jsonResponse) {
                    utils.validateAndShowApiError(jsonResponse)
                    resolve(jsonResponse);
                }).catch(err => {
                    utils.showGeneralError({ message: err.message || "Unhandled error", error: err });
                    reject(err);
                });
        })
    },
    createAppointment: ({ yardId, camioneroId }) => {
        Trucker.validateUser();
        return new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/v1/citas/camionero/cita`;
            var headers = new Headers();
            headers.append("x-api-key", config.apiGateway.api_key);
            headers.append("Authorization", localStorage.getItem('pcc_jwt_token'));
            headers.append("x-user-id", localStorage.getItem('pcc_account_id'));
            let transportistaId = localStorage.getItem('pcc_account_transportador_id');

            let parameters = {
                truckerId: camioneroId,
                yardId,
                transportadorId: transportistaId,
            };

            fetch(url, {
                headers,
                body: JSON.stringify(parameters),
                method: 'POST',
            })
                .then(function (response) {
                    if (response.ok) {
                        return response.json();
                    }

                    switch (response.status) {
                        case 401:
                        case 403:
                            window.location.href = '/sessionExpired';
                            break;
                        default:
                            console.log("Response error", response)
                            break;
                    }
                })
                .then(function (jsonResponse) {
                    utils.validateAndShowApiError(jsonResponse)
                    resolve(jsonResponse);
                }).catch(err => {
                    utils.showGeneralError({ message: err.message || "Unhandled error", error: err });
                    reject(err);
                });
        })
    },
    saveTrucker: ({ firstName, lastName, phone }) => {
        Trucker.validateUser();
        return new Promise((resolve, reject) => {

            let url = `${config.apiGateway.url}/v1/citas/camionero/details/updateByPhone`;
            var headers = new Headers()

            headers.append("x-api-key", config.apiGateway.api_key);
            headers.append("Authorization", localStorage.getItem('pcc_jwt_token'));
            headers.append("x-user-id", localStorage.getItem('pcc_account_id'));

            let parameters = {
                firstName,
                lastName,
                phone,
            };

            fetch(url, {
                headers,
                body: JSON.stringify(parameters),
                method: 'PATCH',
            })
                .then(function (response) {
                    if (response.ok) {
                        return response.json();
                    }

                    switch (response.status) {
                        case 401:
                        case 403:
                            window.location.href = '/sessionExpired';
                            break;
                        default:
                            console.log("Response error", response)
                            break;
                    }
                })
                .then(function (jsonResponse) {
                    utils.validateAndShowApiError(jsonResponse)
                    resolve(jsonResponse);
                }).catch(err => {
                    utils.showGeneralError({ message: err.message || "Unhandled error", error: err });
                    reject(err);
                });
        })
    },
    validatePhone: ({ truckerId, code, operationId }) => {
        return new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/v1/trucker/${truckerId}/smsv2/${code}?oid=${operationId}`

            var headers = new Headers();

            fetch(url, {
                headers,
                method: 'POST',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    console.log(err)
                    reject(err);
                });


        })
    }
}

export default Trucker;