import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import config from 'config'
import GeneralError from 'components/generalError'

class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = { Tab: '' };
    }

    /**
     * Sets active tab
     */
    setActiveTab = (tab, e) => {
        this.setState({ Tab: tab });
    }

    logout = () => {
        localStorage.removeItem('pcc_jwt_token')
        localStorage.removeItem('pcc_account_id')
        localStorage.removeItem('pcc_account_transportador_id')
        localStorage.removeItem('pcc_account_profileId')
        window.location.href = `${config.web.url}`;
    }

    render() {
        const { location } = this.props;
        let profileId = parseInt(localStorage.getItem('pcc_account_profileId'));


        return (
            <React.Fragment>
                <GeneralError />
                <nav id="main_navbar" className="navbar navbar-expand-lg  fixed-top navbar-custom sticky sticky-dark">
                    <div className="container">
                        <Link className="navbar-brand logo" to={profileId !== 1 ? "/" : '/dashboard'}>
                            <img src="/images/pcc_logo_whitebg.png" alt="Patio de Contenedores de Colombia Logo" height="40" />
                        </Link>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="mdi mdi-menu"></i>
                        </button>


                        <div className="collapse navbar-collapse" id="navbarCollapse">
                            {location.pathname === "/sessionExpired" ? null : (
                                <ul className="navbar-nav ml-auto navbar-center" id="mySidenav">
                                    <li className="nav-item active"><a href={profileId !== 1 ? "/" : '/dashboard'} className="nav-link">Home</a></li>
                                    {profileId !== 1 && (
                                        <>
                                            <li className="nav-item"><a href='/' className="nav-link">Citas</a></li>
                                            <li className="nav-item"><a href='/' className="nav-link">Mi Cuenta</a></li>
                                            <li className="nav-item"><a href='/' className="nav-link">Contacto</a> </li>
                                        </>
                                    )}
                                    <li className="nav-item"><a href='/logout' onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        this.logout();
                                    }} className="nav-link">Salir</a> </li>
                                </ul>
                            )}
                        </div>
                    </div>
                </nav>
            </React.Fragment>
        );
    }

}

export default withRouter(Navbar);