import React, { useEffect } from 'react';
import {
    useSelector
} from 'react-redux'
import queryString from 'query-string';

import { Row, Col } from 'reactstrap';
import {
    ThemeProvider,
    useTheme,
    withWidth,
    makeStyles
} from '@material-ui/core'
import { OperationInfo } from './components'
import { Trucker } from './components'

import Scroll from 'react-scroll'
var Element = Scroll.Element;
var scroller = Scroll.scroller;

const useStyles = makeStyles((theme) => ({
    subSections: theme.subSections,

}));
const Step2 = (props) => {
    const { setStep } = props,
        theme = useTheme(),
        classes = useStyles(),
        yardId = useSelector(state => state.appointment.yardId),
        generalParameters = useSelector(state => state.generalParameters)

    useEffect(() => {
        let params = queryString.parse(window.location.search);
        if (params?.target) {
            scroller.scrollTo(params?.target, {
                duration: 800,
                delay: 100,
                smooth: true,
                offset: 200,
            })
        } else {
            window.scrollTo(0, 0);
        }
    })

    let { patios } = generalParameters;

    return <ThemeProvider theme={theme}>
        <section className="section  bg-light" style={{ paddingTop: 0, minHeight: '73vh', }}>
            <div className="container" style={{
                minHeight: 1200
            }}>
                <Row className="align-items-center">
                    <Col lg="3"></Col>
                    <Col lg="6">
                        <div className={`features-content ${classes.subSections} `}>
                            <div className="title-heading mb-5">
                                <h4 className="font-weight-normal text-dark mb-3 mt-4">Patio de {yardId ? patios.find(p => p.id === yardId).name : ''}</h4>
                                <h4 className="font-weight-normal text-dark mb-3 mt-4">Nueva Cita - Operación</h4>
                                Paso 2 de 4
                                <div className="title-border-simple position-relative"></div>
                            </div>

                        </div>
                    </Col>

                </Row>
                <Element name="trucker">
                    <Row style={{ marginBottom: 20 }}>
                        <Col lg="3"></Col>
                        <Col className="col">
                            <Trucker setStep={setStep} currentStep={2} />
                        </Col>
                        <Col lg="3"></Col>
                    </Row>
                </Element>

                <Element name="operation">
                    <OperationInfo
                        setStep={setStep}
                    />
                </Element>

            </div>
        </section>
    </ThemeProvider>
}

export default withWidth()(Step2)