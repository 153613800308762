import React, { useState } from 'react';
import {
    useDispatch,
    useSelector
} from 'react-redux'

import useReactRouter from 'use-react-router';
import {
    ThemeProvider,
    useTheme,
    Card,
    CardContent,
    Typography,
    withWidth,
    Divider,
    Button,
    CircularProgress,
    makeStyles
} from '@material-ui/core'


import {
    addAppointmentAttachments,
} from 'actions/appointmentActions'
import API from 'services/api'


const useStyles = makeStyles((theme) => ({
    subSections: theme.subSections,
}));

const OperationReadOnly = (props) => {
    const { setStep } = props,
        theme = useTheme(),
        classes = useStyles(),
        dispatch = useDispatch(),
        [savingoc, setSavingoc] = useState(false),
        [savingcc, setSavingcc] = useState(false),
        [saving, setSaving] = useState(false),
        { history } = useReactRouter(),
        appointment = useSelector(state => state.appointment),
        generalParameters = useSelector(state => state.generalParameters);


    if (!appointment || (appointment && !appointment.appointment)) return null
    let liner = appointment.appointment.linerCode ? generalParameters.liners.find(liner => liner.code === appointment.appointment.linerCode).name : '';//,
    // operation = appointment.appointment.operationType ? generalParameters.operationsTypes.find(opt => opt.code === appointment.appointment.operationType).name : '';

    const getOperationLonName = (operationType) => {
        return generalParameters.operationsTypes.find(opt => opt.code === operationType).name
    }

    const handleChooseFile = (e) => {
        e.stopPropagation();

        if (e.target.files && e.target.files.length > 0 && appointment) {
            switch (e.target.name) {
                case 'comodato':
                    setSaving(true)
                    break;
                case 'cartaCliente':
                    setSavingcc(true)
                    break;
                case 'ordenDeCargue':
                    setSavingoc(true)
                    break;
                default:
                    setSaving(true)
                    break;
            }
            API.Appointment.uploadAppointmentAttachments({
                documentType: e.target.name,
                filesAttachments: e.target.files,
                appointmentId: appointment.appointment.id
            })
                .then(response => {
                    if (response.success) {
                        dispatch(addAppointmentAttachments(response.data))
                        setSaving(false)
                        setSavingoc(false)
                        setSavingcc(false)
                    }
                }).catch(err => {
                    console.log(err)
                })
                .then(_ => {
                    setSaving(false)
                })
        }
    }



    return <ThemeProvider theme={theme}>
        <>
            {appointment.operations?.length === 0 && (
                <Card style={{ marginBottom: 20 }} className={classes.subSections}>
                    <CardContent style={{
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'relative'
                    }}>
                        <Typography
                            variant='body1'
                            noWrap
                            style={{ marginBottom: '16px' }}>
                            No ha registrado información de la cita
                        </Typography>

                        <div style={{ textAlign: 'right' }}>
                            <Button size='small'
                                variant='outlined'
                                style={{
                                    width: 'fit-content',
                                    borderColor: 'red'
                                }}
                                onClick={() => {
                                    setStep(2)
                                    history.push(`/appointment/step/2?appointmentId=${appointment.appointment.id}`)
                                }}>
                                Completar información
                            </Button>
                        </div>
                    </CardContent>
                </Card>
            )}

            {appointment.operations?.map((operation, i) => (
                <div key={i}>
                    <Card key={i} style={{ marginBottom: 20 }} className={classes.subSections}>
                        <CardContent
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '100%',
                                paddingBottom: 16
                            }}>
                            <Typography variant='h6' style={{ marginBottom: 0 }}>
                                {`${liner ? liner : ''}
                                ${operation?.operationType ? `- ${getOperationLonName(operation.operationType)}` : ''} `}
                            </Typography>
                        </CardContent>
                    </Card>



                    {operation.operationType ?
                        <Card style={{ marginBottom: 20 }} className={classes.subSections}>
                            <CardContent style={{
                                display: 'flex',
                                flexDirection: 'column',
                                position: 'relative'
                            }}>
                                <div style={{
                                    position: 'absolute',
                                    right: 16,
                                    top: 20
                                }}>
                                    <Button size='small'
                                        variant='outlined'
                                        onClick={() => {
                                            setStep(2)
                                            history.push(`/appointment/step/2?appointmentId=${appointment.appointment.id}&target=operation`)
                                        }}>
                                        Cambiar
                                    </Button>
                                </div>
                                {(operation.operationType === 'expo' || operation.operationType === 'impo') &&
                                    <>

                                        {operation.operationType === 'impo' &&
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <Typography variant='h6' style={{ marginBottom: 8 }}>
                                                    {'Comodato'}
                                                </Typography>
                                            </div>
                                        }
                                        {operation.containerNumber && (
                                            <Typography variant='body1'>
                                                {`${operation.containerNumber && operation.operationType === 'impo' ? operation.containerNumber : ''} ${operation.containerType ?
                                                    operation.containerType : operation.containerType}${operation.containerSize ? operation.containerSize : operation.containerSize}`}
                                            </Typography>
                                        )}

                                        {operation.bookingBlNumber && (
                                            <Typography variant='body1'>
                                                <span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>{operation.operationType === 'impo' ? 'BL#' : 'Booking Number'}</span> {` ${operation.bookingBlNumber}`}
                                            </Typography>
                                        )}


                                        {operation.returnYard && operation.operationType === 'impo' && (
                                            <Typography variant='body1'>
                                                <span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Sitio de devolución</span> {` ${operation.returnYard}`}
                                            </Typography>
                                        )}

                                        {operation.clientName && operation.clientRif && (
                                            <Typography variant='body1'>
                                                {` ${operation.clientName} ${operation.clientRif}`}
                                            </Typography>
                                        )}

                                        {operation.operationType === 'impo' &&
                                            <div style={{ display: 'flex', flexDirection: 'column', marginTop: 16 }}>
                                                {appointment.attachments && appointment.attachments.filter(attach => attach.fileType === 'comodato').length > 0 ?
                                                    appointment.attachments.filter(attach => attach.fileType === 'comodato').map((attach, i) => (
                                                        <Typography
                                                            key={i}
                                                            variant='body1'
                                                            noWrap
                                                            style={{ display: 'flex', alignItems: 'center' }}>

                                                            <a href={attach.fileUrlS3} title="Apointment Attach" target="_blank" rel="noopener noreferrer">
                                                                {decodeURI(attach.fileNameS3)}
                                                            </a>
                                                        </Typography>
                                                    )) : (
                                                        <div style={{ textAlign: 'right' }}>
                                                            <label htmlFor="upload_comodato" style={{ position: 'relative', }}>
                                                                <Button size='small'
                                                                    variant='outlined'
                                                                    style={{
                                                                        width: 220,
                                                                        borderColor: 'red'
                                                                    }}
                                                                    disabled={saving}
                                                                    component="span"
                                                                >
                                                                    Falta el Comodato
                                                          {saving && <CircularProgress size={24} />}
                                                                </Button>
                                                            </label>
                                                            <input
                                                                accept="image/*,image/jpeg,image/gif,image/png,application/pdf,image/x-eps,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                                style={{ display: 'none' }}
                                                                id="upload_comodato"
                                                                name='comodato'
                                                                type="file"
                                                                onChange={handleChooseFile}
                                                            />
                                                        </div>
                                                    )}
                                            </div>
                                        }

                                        {operation.operationType === 'expo' && (
                                            <Divider style={{ marginBottom: 20, marginTop: 16 }} />
                                        )}
                                    </>
                                }

                                {(operation.operationType === 'expo' || operation.operationType === 'repo') &&
                                    <>

                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Typography variant='h6' style={{ marginBottom: 8 }}>
                                                {'Orden de Cargue'}
                                            </Typography>
                                        </div>

                                        <Typography variant='body1'>
                                            <span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Placa </span> {appointment.appointment.truckPlate}
                                        </Typography>


                                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 16 }}>
                                            {appointment.attachments && appointment.attachments.filter(attach => attach.fileType === 'ordenDeCargue').length > 0 ?
                                                appointment.attachments.filter(attach => attach.fileType === 'ordenDeCargue').map((attach, i) => (
                                                    <Typography
                                                        key={i}
                                                        variant='body1'
                                                        noWrap
                                                        style={{ display: 'flex', alignItems: 'center' }}>

                                                        <a href={attach.fileUrlS3} title="Apointment Attach" target="_blank" rel="noopener noreferrer">
                                                            {decodeURI(attach.fileNameS3)}
                                                        </a>
                                                    </Typography>
                                                )) : (

                                                    <div style={{ textAlign: 'right' }}>
                                                        <label htmlFor="upload_ordenDeCargue" style={{ position: 'relative', }}>
                                                            <Button size='small'
                                                                variant='outlined'
                                                                style={{
                                                                    width: 220,
                                                                    borderColor: 'red'
                                                                }}
                                                                disabled={savingoc}
                                                                component="span"
                                                            >
                                                                Falta la Orden de Cargue
                                                          {savingoc && <CircularProgress size={24} />}
                                                            </Button>
                                                        </label>
                                                        <input
                                                            accept="image/*,image/jpeg,image/gif,image/png,application/pdf,image/x-eps,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                            style={{ display: 'none' }}
                                                            id="upload_ordenDeCargue"
                                                            name='ordenDeCargue'
                                                            type="file"
                                                            onChange={handleChooseFile}
                                                        />
                                                    </div>


                                                )}
                                        </div>

                                        {operation.operationType === 'expo' && (
                                            <Divider style={{ marginBottom: 20, marginTop: 16 }} />
                                        )}
                                    </>
                                }


                                {operation.operationType === 'expo' &&
                                    <>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Typography variant='h6' style={{ marginBottom: 8 }}>
                                                {'Carta cliente'}
                                            </Typography>
                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 16 }}>
                                            {appointment.attachments && appointment.attachments.filter(attach => attach.fileType === 'cartaCliente').length > 0 ?
                                                appointment.attachments.filter(attach => attach.fileType === 'cartaCliente').map((attach, i) => (
                                                    <Typography
                                                        key={i}
                                                        variant='body1'
                                                        noWrap
                                                        style={{ display: 'flex', alignItems: 'center' }}>

                                                        <a href={attach.fileUrlS3} title="Apointment Attach" target="_blank" rel="noopener noreferrer">
                                                            {decodeURI(attach.fileNameS3)}
                                                        </a>
                                                    </Typography>
                                                )) : (

                                                    <div style={{ textAlign: 'right' }}>
                                                        <label htmlFor="upload_cartaCliente" style={{ position: 'relative', }}>
                                                            <Button size='small'
                                                                variant='outlined'
                                                                style={{
                                                                    width: 220,
                                                                    borderColor: 'red'
                                                                }}
                                                                disabled={savingcc}
                                                                component="span"
                                                            >
                                                                Falta la Carta Cliente
                                                          {savingcc && <CircularProgress size={24} />}
                                                            </Button>
                                                        </label>
                                                        <input
                                                            accept="image/*,image/jpeg,image/gif,image/png,application/pdf,image/x-eps,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                            style={{ display: 'none' }}
                                                            id="upload_cartaCliente"
                                                            name='cartaCliente'
                                                            type="file"
                                                            onChange={handleChooseFile}
                                                        />
                                                    </div>
                                                )}
                                        </div>
                                    </>
                                }
                            </CardContent>
                        </Card>
                        :
                        <Card style={{ marginBottom: 20 }} className={classes.subSections}>
                            <CardContent style={{
                                display: 'flex',
                                flexDirection: 'column',
                                position: 'relative'
                            }}>
                                <Typography
                                    variant='body1'
                                    noWrap
                                    style={{ marginBottom: '16px' }}>

                                    No ha registrado información de la cita
                                </Typography>

                                <div style={{ textAlign: 'right' }}>
                                    <Button size='small'
                                        variant='outlined'
                                        style={{
                                            width: 'fit-content',
                                            borderColor: 'red'
                                        }}
                                        onClick={() => {
                                            setStep(2)
                                            history.push(`/appointment/step/2?appointmentId=${appointment.appointment.id}`)
                                        }}>
                                        Completar información
                            </Button>
                                </div>
                            </CardContent>
                        </Card>
                    }

                </div>
            ))}




        </>
    </ThemeProvider>
}

export default withWidth()(OperationReadOnly)