import React, { useState } from 'react';
import {
    useDispatch,
    useSelector
} from 'react-redux'

import { Row, Col } from 'reactstrap';

import {
    Button,
    makeStyles,
    CircularProgress,
    Card,
    CardContent,
    Typography,
    Dialog,
    DialogTitle,
    DialogActions
} from '@material-ui/core'
import {
    CloudUpload as CloudUploadIcon,
    RemoveCircleOutline
} from '@material-ui/icons'
import { green } from '@material-ui/core/colors';
import API from 'services/api'

import {
    addAppointmentAttachments,
    deleteAppointmentAttachment as deleteAppointmentAttachmentAction
} from 'actions/appointmentActions'


const useStyles = makeStyles((theme) => ({
    subSections:theme.subSections,
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 150,
    },
}));



const UploadRow = (props) => {
    const [saving, setSaving] = useState(false),
        [deleting, setDeleting] = useState(false),
        [selectedAttach, setSelectedAttach] = useState(null),
        appointment = useSelector(state => state.appointment),
        classes = useStyles(),
        dispatch = useDispatch(),
        name = 'cartaCliente';

    const handleChooseFile = (e) => {
        e.stopPropagation()

        if (e.target.files && e.target.files.length > 0 && appointment) {
            setSaving(true)

            API.Appointment.uploadAppointmentAttachments({
                documentType: name,
                filesAttachments: e.target.files,
                appointmentId: appointment.appointment.id
            })
                .then(response => {
                    if (response.success) {
                        dispatch(addAppointmentAttachments(response.data))
                        setSaving(false)
                    }
                })
                .catch(err => {
                    console.log(err)
                })
                .then(_ => {
                    setSaving(false)
                })
        }
    }

    const confirmDeleteAppointmentAttachment = (attach) => {
        setSelectedAttach(attach)
    }

    const deleteAppointmentAttachment = () => {
        setDeleting(true)

        API.Appointment.deleteAppointment({
            fileId: selectedAttach.id,
            appointmentId: appointment.appointment.id
        })
            .then(response => {
                if (response.success) {
                    dispatch(deleteAppointmentAttachmentAction(selectedAttach.id))
                    setDeleting(false)
                    setSelectedAttach(null)
                }
            }).catch(err => {
                console.log(err)
            })
            .then(_ => {
                setDeleting(false)
            })
    }

    return <>
        <Row style={{ marginBottom: 20 }}>
            <Col lg="3"></Col>
            <Col lg="6">
                <Card className={classes.subSections}>
                    <CardContent style={{
                        display: 'flex',
                        flexDirection: 'column', 
                    }}>

                        {appointment.attachments && appointment.attachments.filter(attach => attach.fileType === name).map(attach => {
                            return (
                                <Typography noWrap style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                    <RemoveCircleOutline
                                        disabled={saving}
                                        onClick={() => confirmDeleteAppointmentAttachment(attach)}
                                        style={{ marginRight: 6, marginLeft: 0, color: 'red', cursor: 'pointer' }} />

                                    <a href={attach.fileUrlS3} title="Apointment Attach" target="_blank" rel="noopener noreferrer">
                                        {decodeURI(attach.fileNameS3)}
                                    </a>
                                </Typography>
                            )
                        })}

                        <label htmlFor="contained-button-file-carta-cliente" style={{ position: 'relative', }}>
                            <Button variant="contained"
                                color="secondary"
                                disabled={saving}
                                style={{ width: "210px" }}
                                startIcon={<CloudUploadIcon style={{ position: 'absolute', top: '7px', left: "13px" }} />}
                                component="span"
                            >
                                Carta Cliente
                        </Button>
                            {saving && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </label>

                        <input
                            accept="image/*,image/jpeg,image/gif,image/png,application/pdf,image/x-eps,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            style={{ display: 'none' }}
                            id="contained-button-file-carta-cliente"
                            // multiple
                            type="file"
                            onChange={handleChooseFile}
                        />

                        {props.children}

                    </CardContent>
                </Card>
            </Col>
            <Col lg="3"></Col>
        </Row>

        <Dialog
            open={selectedAttach ? true : false}
            onClose={() => {
                setSelectedAttach(null);
            }}
        >
            <DialogTitle id="alert-dialog-title">
                {`¿Estás seguro de querer eliminar el archivo ${selectedAttach ? decodeURI(selectedAttach.fileNameS3) : ''}?`}
            </DialogTitle>
            <DialogActions>
                <Button
                    disabled={deleting}
                    onClick={() => {
                        setSelectedAttach(null);
                    }} color="primary">
                    No, cancelar
                </Button>
                <Button
                    disabled={deleting}
                    onClick={deleteAppointmentAttachment}
                    color="primary"
                    autoFocus>
                    Eliminar
                    {deleting && <CircularProgress size={24} className={classes.buttonProgress} />}
                </Button>
            </DialogActions>
        </Dialog>

    </>
}


export default UploadRow