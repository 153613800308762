import React, { useState, useEffect } from 'react';
import {
    useDispatch,
    useSelector
} from 'react-redux'
import useReactRouter from 'use-react-router';
import { Row, Col } from 'reactstrap';
import { ThemeProvider } from '@material-ui/core/styles';
import { useTheme, makeStyles } from '@material-ui/core/styles';

import {
    Trucker
} from './components'

const useStyles = makeStyles((theme) => ({
    subSections: theme.subSections,

}));

const Step1 = (props) => {
    const { setStep } = props
    const theme = useTheme();
    const classes = useStyles();
    const { history, location } = useReactRouter()
    const appointment = useSelector(state => state.appointment);
    const patios = useSelector(state => state.generalParameters.patios);
    const [appointmentFetched, setAppointmentFetched] = useState(false)
    const [inputs, setInputs] = useState(appointment.trucker ? {
        camioneroNombre: appointment.trucker.firstName,
        camioneroApellido: appointment.trucker.lastName,
        camioneroCelular: appointment.trucker.phone
    } : {});

    const yardId = useSelector(state => state.appointment.yardId);
    const dispatch = useDispatch();

    useEffect(() => {
        window.scrollTo(0, 0);
    })

    useEffect(() => {
        if (!yardId) {
            history.push('/');
        }
        let queryParams = new URLSearchParams(location.search)

        if (queryParams.get("appointmentId") && appointment.trucker && !appointmentFetched) {
            setInputs({
                ...inputs,
                camioneroId: appointment.trucker.truckerId,
                camioneroCelular: appointment.trucker.phone,
                camioneroNombre: appointment.trucker.firstName,
                camioneroApellido: appointment.trucker.lastName
            })
            setAppointmentFetched(true);
        }
    }, [yardId, history, appointment, dispatch, inputs, location, appointmentFetched])


    return <ThemeProvider theme={theme}>
        <section className="section  bg-light" style={{ paddingTop: 0, minHeight: '73vh', }}>
            <div className="container" >

                <Row className="align-items-center">
                    <Col lg="3"></Col>
                    <Col lg="6">
                        <div className={`features-content ${classes.subSections}`}>
                            <div className="title-heading mb-5">
                                <h4 className="font-weight-normal text-dark mb-3 mt-4">Patio de {yardId ? patios.find(p => p.id === yardId).name : ''}</h4>
                                <h4 className="font-weight-normal text-dark mb-3 mt-4">Nueva Cita - Conductor</h4>
                                Paso 1 de 4
                                <div className="title-border-simple position-relative"></div>
                            </div>
                        </div>
                    </Col>

                </Row>
                <Row className="align-items-center">
                    <Col lg="3"></Col>
                    <Col lg="6">
                        <Trucker setStep={setStep} currentStep={1} />
                    </Col>
                </Row>

            </div>
        </section>
    </ThemeProvider>
}

export default Step1;