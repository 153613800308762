import React, { useEffect } from 'react';

import {
    useSelector
} from 'react-redux'
import queryString from 'query-string';

import { Row, Col } from 'reactstrap';
import { ThemeProvider } from '@material-ui/core/styles';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import useReactRouter from 'use-react-router';
import { Trucker, OperationInfoReadOnly } from './components'

import { TimeSlots } from './components'
import Scroll from 'react-scroll'
var Element = Scroll.Element;
var scroller = Scroll.scroller;

const useStyles = makeStyles((theme) => ({
    subSections: theme.subSections,

}));

const NewAppointment = (props) => {
    const { setStep } = props,
        classes = useStyles(),
        appointment = useSelector(state => state.appointment),
        { history } = useReactRouter(),
        yardId = useSelector(state => state.appointment.yardId),
        generalParameters = useSelector(state => state.generalParameters);
    let { patios } = generalParameters;


    useEffect(() => {
        let params = queryString.parse(window.location.search);
        if (params?.target) {
            scroller.scrollTo(params?.target, {
                duration: 800,
                delay: 100,
                smooth: true,
                offset: 200,
            })
        } else {
            window.scrollTo(0, 0);
        }
    })

    const theme = useTheme();

    return <ThemeProvider theme={theme}>
        <section className="section  bg-light" style={{ paddingTop: 0, minHeight: '73vh', }}>
            <div id='container' className="container">

                <Row className="align-items-center">
                    <Col lg="3"></Col>
                    <Col lg="6">
                        <div className={`features-content ${classes.subSections}`}>
                            <div className="title-heading mb-5">
                                <h4 className="font-weight-normal text-dark mb-3 mt-4">Patio de {yardId ? patios.find(p => p.id === yardId).name : ''}</h4>
                                <h4 className="font-weight-normal text-dark mb-3 mt-4">Nueva Cita - Horario</h4>
                                Paso 3 de 4
                                <div className="title-border-simple position-relative"></div>
                            </div>

                        </div>
                    </Col>

                </Row>

                <Element name="trucker">
                    <Row style={{ marginBottom: 20 }}>
                        <Col lg="3"></Col>
                        <Col className="col" lg="6">
                            <Trucker setStep={setStep} currentStep={3} />
                        </Col>
                        <Col lg="3"></Col>
                    </Row>
                </Element>

                <Element name="operation">
                    <Row>
                        <Col lg="3"></Col>
                        <Col className="col" lg="6">
                            <OperationInfoReadOnly
                                setStep={setStep}
                            />
                        </Col>
                        <Col lg="3"></Col>
                    </Row>
                </Element>


                <Element name="timeSlots">
                    <div id="timeSlots">
                        <TimeSlots
                            fnGotoStep4={(slot) => {
                                setStep(4)
                                history.push(`/appointment/step/4?appointmentId=${appointment.appointment.id}`)
                            }}
                        />
                    </div>
                </Element>
            </div>
        </section>
    </ThemeProvider>
}

export default NewAppointment