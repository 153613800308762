import {
    SET_APPOINTMENT_FETCHING,
    SET_APPOINTMENT_FETCHED,
    SET_APPOINTMENT_PARAMETERS,
    SET_NEW_APPOINTMENT_STEP,
    SET_APPOINTMENT_TRUCKER,
    CLEAR_APPOINTMENT_TRUCKER,
    CLEAR_APPOINTMENT_PARAMETERS,
    SET_APPOINTMENT_YARDID,
    SET_APPOINTMENT_ATTACHMENTS,
    SET_APPOINTMENT,
    CLEAR_APPOINTMENT_ATTACHMENTS,
    DELETE_APPOINTMENT_ATTACHMENT,
    SET_APPOINTMENT_TIME_SLOT,
    CLEAR_APPOINTMENT_YARD,
    SET_APPOINTMENT_DOCUMENTS_CONTACT
} from '../actions/appointmentActions'

function appointment(state = {}, action) {
    switch (action.type) {
        case SET_APPOINTMENT_FETCHING:
            return {
                ...state,
                fetching: true
            }
        case SET_APPOINTMENT_FETCHED:
            return {
                ...state,
                fetching: false
            }
        case SET_APPOINTMENT:
            return action.appointment
        case SET_APPOINTMENT_PARAMETERS:
            return {
                ...state,
                appointment: action.appointment,
                operations: action.appointment?.operations ? [...action.appointment.operations] : []
            }
        case SET_NEW_APPOINTMENT_STEP:
            return {
                ...state,
                step: action.step
            }
        case SET_APPOINTMENT_TRUCKER:
            return {
                ...state,
                trucker: action.trucker
            }
        case SET_APPOINTMENT_YARDID:
            return {
                ...state,
                yardId: action.yardId
            }
        case CLEAR_APPOINTMENT_YARD:
            return {
                ...state,
                yardId: null
            }
        case CLEAR_APPOINTMENT_TRUCKER:
            return {
                ...state,
                trucker: null
            }
        case CLEAR_APPOINTMENT_PARAMETERS:
            return {
                ...state,
                appointment: null
            }
        case SET_APPOINTMENT_ATTACHMENTS:
            return {
                ...state,
                attachments: action.attachments
            }
        case CLEAR_APPOINTMENT_ATTACHMENTS:
            return {
                ...state,
                attachments: null
            }
        case DELETE_APPOINTMENT_ATTACHMENT: {
            return {
                ...state,
                attachments: state.attachments.filter(a => a.id !== action.attachmentId)
            }
        }
        case SET_APPOINTMENT_TIME_SLOT: {
            return {
                ...state,
                dateTimeSlot: action.dateTimeSlot
            }
        }
        case SET_APPOINTMENT_DOCUMENTS_CONTACT: {
            return {
                ...state,
                appointmentDocumentsContact: action.contact
            }
        }
        default:
            return state;
    }
}

export default appointment