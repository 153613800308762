import config from 'config';
import { utils } from 'lib'
const Clients = {
    validateUser: () => {
        if (!localStorage.getItem('pcc_account_id')) {
            localStorage.removeItem('pcc_jwt_token')
            localStorage.removeItem('pcc_account_id')
            localStorage.removeItem('pcc_account_transportador_id')
            localStorage.removeItem('pcc_account_profileId')
            return window.location.href = `${config.web.url}`;
        }
        return true;
    },
    getClient: (args) => {
        Clients.validateUser();
        return new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/v1/clients/${args.clientNit}`;
            var headers = new Headers();
            headers.append("x-api-key", config.apiGateway.api_key);
            headers.append("Authorization", localStorage.getItem('pcc_jwt_token'));
            headers.append("x-user-id", localStorage.getItem('pcc_account_id'));

            fetch(url, {
                headers
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    utils.validateAndShowApiError(jsonResponse)
                    resolve(jsonResponse);
                }).catch(err => {
                    utils.showGeneralError({ message: err.message || "Unhandled error", error: err });
                    reject(err);
                });
        })
    }
}

export default Clients;