import React from 'react';

// import Preloader from '../components/Preloader';
import { Navbar, Footer } from '../components/layout';

const Layout = (props) => {
    return (
        <>
            {/* <Preloader /> */}
            <Navbar {...props} />
            <div style={{ marginTop: '40px' }}>
                {props.children}
            </div>
            <Footer />
        </>
    )
}

export default Layout;