const appVersion = '1.0.0';
const config = {
    appVersion,
    timezone:'America/Bogota',
    apiGateway: {
        url: process.env.REACT_APP_PCC_API,
        api_key: process.env.REACT_APP_PCC_KEY
    },
    web: {
        url: process.env.REACT_APP_PCC_WEBSITE//'http://localhost:3000'// "https://web.patioscol.net"//'http://localhost:3000'//
    }
}
module.exports = config;