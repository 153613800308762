import React from 'react'
import { Typography } from '@material-ui/core'
import config from 'config'



export default function SessionExpired() {

    const logout = (e) => {
        e.stopPropagation();
        e.preventDefault();
        localStorage.removeItem('pcc_jwt_token')
        localStorage.removeItem('pcc_account_id')
        localStorage.removeItem('pcc_account_transportador_id')
        localStorage.removeItem('pcc_account_profileId')
        window.location.href = `${config.web.url}`;
    }
    console.log(config)
    return (
        <div style={{
            height: '89vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
        }}>
            <Typography variant='h5'>Su sessión ha expirado</Typography>
            <a href='/' onClick={logout}>Volver al login.</a>
        </div>
    )
}
