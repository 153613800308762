import { SET_GENERAL_ERROR } from 'actions/generalErrorActions'
import store from 'store'
const utils = {
    uppercaseFirstLetter: (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    },
    stringIsANumber: (str) => {
        return /^\d+$/.test(str)
    },
    phoneIsValid: (celular) => {
        return `${celular}`.match(/^[3]\d{9}$/);
    },
    validateEmail: (email) => {
        var re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    },
    showGeneralError: ({ message, error }) => {
        store.dispatch({
            type: SET_GENERAL_ERROR,
            error: { message, showError: true }
        })
        console.error(error);
    },
    validateAndShowApiError: (jsonResponse) => {
        if (!jsonResponse.success) {
            utils.showGeneralError({
                message: jsonResponse.errors?.USER_MESSAGE || "Unhandled error"
            })
            console.error(jsonResponse);
        }
    }
}

export default utils;