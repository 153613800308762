import React, { useState } from 'react';
import {
    useSelector,
    useDispatch
} from 'react-redux'

import { Row, Col } from 'reactstrap';
import { ThemeProvider } from '@material-ui/core/styles';
import {
    Button,
    Dialog,
    CircularProgress,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    TextField,
    MenuItem
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles';
import 'moment/locale/es'
import moment from 'moment'
import { utils } from 'lib'
import useReactRouter from 'use-react-router';

import {
    Trucker,
    OperationInfoReadOnly,
    TimeSlotSelected
} from '../components'
import { Typography, makeStyles } from '@material-ui/core';
import API from 'services/api'
import {
    setAppointmentParameters,
    clearTrucker,
    clearAppointmentYard
} from 'actions/appointmentActions'


moment.locale('es');
const useStyles = makeStyles((theme) => ({
    subSections: theme.subSections
}));

const AppointmentSummary = (props) => {
    const { setStep } = props,
        [loading, setLoading] = useState(false),
        { history } = useReactRouter(),
        [showConfirmCancelAppointment, setShowConfirmCancelAppointment] = useState(false),
        appointment = useSelector(state => state.appointment),
        yardId = useSelector(state => state.appointment.yardId),
        generalParameters = useSelector(state => state.generalParameters),
        [selectedReason, setSelectedReason] = useState(''),
        [showError, setShowError] = useState(false),
        classes = useStyles(),
        dispatch = useDispatch();

    let { patios,
        cancelationTypes
    } = generalParameters;

    const theme = useTheme();

    const handleChangeCancelReason = e => {
        setSelectedReason(e.target.value)
        if (e.target.value) setShowError(false);
    }

    const cancelAppointment = () => {
        setShowError(false);
        if (selectedReason) {
            setLoading(true);
            API.Appointment.cancelAppointment({
                appointmentId: appointment.appointment.id,
                cancelReasonId: selectedReason
            })
                .then(response => {
                    if (response.success) {
                        history.push(`/`)
                        dispatch(clearTrucker())
                        dispatch(clearAppointmentYard())
                        dispatch(setAppointmentParameters(null))
                        setLoading(false)
                        setShowConfirmCancelAppointment(false)
                    }
                }).catch(err => {
                    console.log(err)
                })
                .then(_ => {
                    setLoading(false);
                })
        } else {
            setShowError(true);
        }
    }


    return <ThemeProvider theme={theme}>
        <section className="section  bg-light" style={{ paddingTop: 0 }}>
            <div className="container">

                <Row className="align-items-center">
                    <Col lg="3"></Col>
                    <Col lg="6">
                        <div className="features-content">
                            <div className={`title-heading ${classes.subSections}`}
                                style={{
                                    marginBottom: 20,
                                }}>
                                <h4 className="font-weight-normal text-dark mb-3 mt-4" style={{ marginBottom: 0 }}>{`Patio de ${yardId ? patios.find(p => p.id === yardId).name : ''}`}</h4>
                                {appointment && appointment.appointment && (
                                    <div style={{ display: 'flex' }}>
                                        <Typography variant='body2' color="textSecondary" style={{ marginRight: 5 }}>
                                            Creada
                                            </Typography>
                                        <Typography variant='body2'>
                                            {utils.uppercaseFirstLetter(moment(appointment.appointment.createTimestamp).format('dddd DD, hh:mm A'))}
                                        </Typography>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Col>

                </Row>

                <Row>
                    <Col lg="3"></Col>
                    <Col className="col" lg="6" >
                        <TimeSlotSelected
                            setStep={setStep}
                        />
                    </Col>
                    <Col lg="3"></Col>
                </Row>

                <Row style={{ marginBottom: 20 }}>
                    <Col lg="3"></Col>
                    <Col className="col" lg="6">
                        <Trucker setStep={setStep} currentStep={3} />
                    </Col>
                    <Col lg="3"></Col>
                </Row>

                <Row>
                    <Col lg="3"></Col>
                    <Col className="col" lg="6">
                        <OperationInfoReadOnly
                            setStep={setStep}
                        />
                    </Col>
                    <Col lg="3"></Col>
                </Row>

                <Row>
                    <Col lg="3"></Col>
                    <Col lg="6" style={{ display: 'flex' }} className={`col ${classes.subSections}`}>
                        <Button
                            variant='outlined'
                            onClick={() => { setShowConfirmCancelAppointment(true); }}
                            style={{ marginLeft: 'auto' }}>
                            Cancelar cita
                            </Button>
                    </Col>
                    <Col lg="3"></Col>
                </Row>
            </div>

            <Dialog
                open={showConfirmCancelAppointment}
                onClose={() => {
                    setShowConfirmCancelAppointment(false)
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    Cancelar Cita
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {`Esta seguro de que desea cancelar esta cita?\n Luego de cancelada es posible que el horario seleccionado ya no este disponible.`}
                    </DialogContentText>
                    <div>
                        <TextField
                            variant="outlined"
                            style={{
                                width: "100%",
                                maxWidth: 300,
                                marginTop: 20,
                                marginBottom: 20
                            }}
                            label="Motivo para cancelar"
                            name="size"
                            value={selectedReason}
                            onChange={handleChangeCancelReason}
                            fullWidth
                            select
                            required
                            error={showError ? true : false}
                            InputLabelProps={{
                                //shrink: true,
                            }}>
                            {cancelationTypes.map(type => (
                                <MenuItem key={type.value}
                                    value={type.value}>{type.name}</MenuItem>
                            ))}
                        </TextField>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={loading}
                        onClick={() => {
                            setShowConfirmCancelAppointment(false);
                            setShowError(false);
                        }} color="primary">
                        No, cancelar
                        </Button>
                    <Button
                        disabled={loading}
                        onClick={cancelAppointment}
                        color="primary"
                        autoFocus>
                        Confirmar
                        {loading && <CircularProgress size={24} />}
                    </Button>
                </DialogActions>
            </Dialog>
        </section>
    </ThemeProvider>
}

export default AppointmentSummary