import React, { useState, useEffect } from 'react'
import {
    Typography,
    Link,
    Grid,
    TextField,
    CircularProgress
} from '@material-ui/core'
import { useSelector } from 'react-redux'
import API from 'services/api'
import { utils } from 'lib'
import { useDispatch } from 'react-redux'
import { setAppointmentDocumentsContact } from 'actions/appointmentActions'

const DocumentContact = (props) => {
    const [openContactDialog, setOpenContactDialog] = useState(false),
        [saving, setSaving] = useState(false),
        [inputs, setInputs] = useState({}),
        [errors, setErrors] = useState({}),
        [showErrors, setShowErrors] = useState(false),
        appointment = useSelector(state => state.appointment),
        dispatch = useDispatch();

    useEffect(() => {

        if (appointment.appointmentDocumentsContact) {
            setInputs({
                lastName: appointment.appointmentDocumentsContact.documentContactLastName,
                firstName: appointment.appointmentDocumentsContact.documentContactFirstName,
                mobile: appointment.appointmentDocumentsContact.documentContactPhone,
                email: appointment.appointmentDocumentsContact.documentContactEmail
            })
        }

    }, [appointment.appointmentDocumentsContact])

    const handleInputChange = (e) => {
        setInputs({
            ...inputs,
            [e.target.name]: e.target.value
        })
    }

    const handleOpenDialog = () => {
        props.onShow(true);
        setOpenContactDialog(true)
    }

    const handleCloseDialog = () => {
        props.onShow(false);
        setOpenContactDialog(false)
        setShowErrors(false)
        setErrors({})
    }
    const validateInputs = () => {
        let valid = true,
            newErrors = {};
        setShowErrors(false)
        if (!inputs?.lastName?.trim() || inputs?.lastName?.trim() === '') {
            valid = false;
            newErrors.lastName = true;
        }
        if (!inputs?.firstName?.trim() || inputs?.firstName?.trim() === '') {
            valid = false;
            newErrors.firstName = true;
        }
        if (!inputs.mobile || !utils.phoneIsValid(inputs.mobile)) {
            valid = false;
            newErrors.mobile = true;
        }
        if (!inputs?.email?.trim() || inputs?.email?.trim() === '' || !utils.validateEmail(inputs?.email?.trim())) {
            valid = false;
            newErrors.email = true;
        }
        if (!valid) {
            setShowErrors(true)
            setErrors(newErrors)
        }
        return valid;
    }

    const handleSaveContact = () => {
        if (validateInputs()) {
            setSaving(true);
            API.Appointment.createContact({
                fistName: inputs.firstName,
                lastName: inputs.lastName,
                email: inputs.email,
                mobile: parseInt(inputs.mobile),
                yardOperationId: appointment.appointment.id,
            }).then(result => {
                props.onSuccess && props.onSuccess()
                dispatch(setAppointmentDocumentsContact(result.data));
                setSaving(false)
                setOpenContactDialog(false)
                setShowErrors(false)
                setErrors({})
                setInputs({})
            })
        }
    }
    
    
    return (
        <div>
            {appointment.appointmentDocumentsContact && !openContactDialog ? (
                <>
                    <h6 style={{ marginBottom: 10 }}>{`Otro contacto para documentación`}</h6>
                    <p>
                        Si usted no tiene estos documentos, puede darnos los datos de contacto de la persona que nos los puede facilitar.
                    </p>
                    <span>{appointment.appointmentDocumentsContact.documentContactFirstName} {appointment.appointmentDocumentsContact.documentContactLastName}</span>
                    <br />
                    <span>{appointment.appointmentDocumentsContact.documentContactEmail}</span>
                    <br />
                    <span>{appointment.appointmentDocumentsContact.documentContactPhone}</span>
                    <br />
                    <br />
                    <input type='button' onClick={handleOpenDialog} value={'Actualizar Datos Persona Contacto'} />
                    <br />
                </>
            ) : (


                    <>
                        {!openContactDialog && (
                            <Typography variant='body1'
                                style={{ marginBottom: 20, borderTopColor: '#c0c0c0', borderTopWidth: 1 }}>
                                Si no tiene estos documentos, puede darnos los datos de la persona contacto
                                <Link
                                    component="span"
                                    variant="body1"
                                    underline='always'
                                    onClick={handleOpenDialog}
                                    style={{
                                        marginLeft: 5,
                                        marginRight: 5,
                                        cursor: 'pointer'
                                    }}
                                >{'haciendo clic aquí'}</Link>
                            </Typography>
                        )}
                        {openContactDialog && (
                            <>
                                <Grid container spacing={2} style={{
                                    width: '100%',
                                    margin: 0
                                }}>
                                    <Grid item xs={12}>
                                        <Typography variant='h6'>
                                            Datos de persona contacto para obtener los documentos
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <TextField
                                            variant='outlined'
                                            fullWidth
                                            disabled={saving}
                                            label='Nombre *'
                                            name='firstName'
                                            margin='normal'
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            value={inputs.firstName}
                                            onChange={handleInputChange}
                                            error={showErrors && errors.firstName ? true : false}
                                        />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <TextField
                                            variant='outlined'
                                            fullWidth
                                            disabled={saving}
                                            label='Apellido *'
                                            name='lastName'
                                            margin='normal'
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            value={inputs.lastName}
                                            onChange={handleInputChange}
                                            error={showErrors && errors.lastName ? true : false}
                                        />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <TextField
                                            variant='outlined'
                                            fullWidth
                                            disabled={saving}
                                            label='Correo *'
                                            name='email'
                                            type='email'
                                            margin='normal'
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            value={inputs.email}
                                            onChange={handleInputChange}
                                            error={showErrors && errors.email ? true : false}
                                        />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <TextField
                                            variant='outlined'
                                            fullWidth
                                            disabled={saving}
                                            label='Celular *'
                                            name='mobile'
                                            margin='normal'
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            onInput={(e) => {
                                                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                            }}
                                            type='number'
                                            value={inputs.mobile}
                                            onChange={handleInputChange}
                                            error={showErrors && errors.mobile ? true : false}
                                        />
                                    </Grid>
                                    {showErrors && (
                                        <Grid item xs={12}>
                                            <Typography variant='body2' style={{ color: 'red' }}>
                                                * Campos requeridos
                                        </Typography>
                                        </Grid>
                                    )}
                                    <Grid item xs={6}>
                                        <input type='button' value='Cancelar' onClick={handleCloseDialog} />
                                    </Grid>
                                    <Grid item xs={6} style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end'
                                    }}>
                                        <input type='button' value='Aceptar' onClick={handleSaveContact} />
                                        {saving && (<CircularProgress size={24} />)}
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </>
                )}
        </div>
    )
}

export default DocumentContact;