import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import config from 'config'
import API from 'services/api'
import {
    useDispatch,
    useSelector
} from 'react-redux'
import 'moment/locale/es'
import moment from 'moment'
import { utils } from 'lib'
import {
    Typography,
    Button,
    CircularProgress,
    Grid,
    Divider,
    makeStyles
} from '@material-ui/core';
import { Inbox } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import {
    setAppointment,
    setAppointmentYard,
} from 'actions/appointmentActions'
import {
    setAppointments
} from 'actions/appointmentsActions'

moment.locale('es');

const useStyles = makeStyles((theme) => ({
    'appointment__status--confirmed': {
        padding: 5,
        border: 'solid thin green',
        borderRadius: 5,
        width: 100,
        textAlign: 'center',
        marginLeft: 'auto'
    },
    'appointment__status--incompleted': {
        padding: 5,
        border: 'solid thin red',
        borderRadius: 5,
        width: 100,
        textAlign: 'center',
        marginLeft: 'auto'
    },
    missingDocs: {
        marginTop: 10,
        textAlign: 'right',
        border: 'solid thin red',
        borderRadius: 5,
        marginLeft: 'auto',
        padding: 8,
        width: 'fit-content'
    }
}));

const Home = props => {

    const appointments = useSelector(state => state.appointments);
    const patios = useSelector(state => state.generalParameters.patios);
    const [fetchingAppointments, setFetchingAppointments] = useState(true);
    const [cancelledAppointmentsCounters, setCancelledAppointmentsCounters] = useState(null);
    const [accountDataSaved, setAccountDataSaved] = useState(false)
    const history = useHistory();
    let params = queryString.parse(props.location.search),
        classes = useStyles(),
        dispatch = useDispatch();

    useEffect(() => {
        window.scrollTo(0, 0);
    })

    useEffect(() => {
        if (params && params.token) {
            localStorage.setItem('pcc_jwt_token', params.token)
            localStorage.setItem('pcc_account_id', params.id)
            localStorage.setItem('pcc_account_transportador_id', params.transportadorId)
            localStorage.setItem('pcc_account_profileId', params.profileId)
            props.history.push('/');
            setAccountDataSaved(true);
        } else {
            if (!localStorage.getItem('pcc_account_id')) {
                localStorage.removeItem('pcc_jwt_token')
                localStorage.removeItem('pcc_account_id')
                localStorage.removeItem('pcc_account_transportador_id')
                localStorage.removeItem('pcc_account_profileId')
                return window.location.href = `${config.web.url}`;
            } else {
                let profileId = parseInt(localStorage.getItem('pcc_account_profileId'));
                if (profileId === 1) {
                    props.history.push('/dashboard');
                }
                setAccountDataSaved(true);
            }
        }
    }, [params, props.history])


    useEffect(() => {
        if (fetchingAppointments && accountDataSaved) {
            API.Appointment.getAppointmentsList()
                .then(appointmentsResponse => {
                    if (appointmentsResponse.success) {
                        dispatch(setAppointments(appointmentsResponse.appointments))
                        setCancelledAppointmentsCounters(appointmentsResponse.cacelledAppointmentsCounters)
                    }
                })
                .catch(err => {
                    console.log(err)
                })
                .then(_ => {
                    setFetchingAppointments(false)
                })
        }
    }, [fetchingAppointments, dispatch, accountDataSaved])


    const nuevaCita = (idPatio) => {
        dispatch(setAppointment({}))
        dispatch(setAppointmentYard(idPatio));
        props.history.push("/appointment/step/1");
    }

    const renderDateFormatted = (date, { hideHour, hideDate } = {}) => {
        return (
            <>
                {!hideDate && (
                    <>
                        {`${utils.uppercaseFirstLetter(moment(date).format("dddd,"))} ${utils.uppercaseFirstLetter(moment(date).format("MMMM DD"))}`}
                        <br />
                    </>
                )}
                {!hideHour ? utils.uppercaseFirstLetter(moment(date).format("hh:mm A")) : null}
            </>
        )
    }

    const renderAppointmentStatus = (appointment) => {
        let status = '',
            subStatus = '';

        if (appointment.confirmed/*&& appointment.step === 5 */) {
            status = 'Confirmada'
        } else {
            status = 'Pendiente'
        }

        if (appointment.operationType) {
            switch (appointment.operationType) {
                case "impo":
                    if ((appointment.files?.length === 0)) {
                        subStatus = 'Falta Documentación'
                    } else if (!appointment.files.find(f => f.fileType === 'comodato')) {
                        subStatus = 'Falta Documentación'
                    }
                    break;
                case "expo":
                    if (appointment.files?.length === 0) {
                        subStatus = 'Falta Documentación'
                    } else if (!appointment.files.find(f => f.fileType === 'comodato') || !appointment.files.find(f => f.fileType === 'ordenDeCargue') || appointment.files.find(f => f.fileType === 'cartaCliente')) {
                        subStatus = 'Falta Documentación'
                    }
                    break;
                default:
                    break;
            }
        }

        return (
            <>
                <div className={appointment.confirmed ? classes['appointment__status--confirmed'] : classes['appointment__status--incompleted']}>
                    {status}
                </div>
                {subStatus && (
                    <div className={classes.missingDocs}>
                        <Typography variant='body2' color='textSecondary'> {subStatus}</Typography>
                    </div>
                )}
            </>
        )
    }

    const isMissingDocs = (appointment) => {
        let missing = false;
        if (appointment.operationType) {
            switch (appointment.operationType) {
                case "impo":
                    if (appointment.files?.length === 0) {
                        missing = true;
                    } else if (!appointment.files.find(f => f.fileType === 'comodato')) {
                        missing = true;
                    }
                    break;
                case "expo":
                    if (appointment.files?.length === 0) {
                        missing = true;
                    } else if (!appointment.files.find(f => f.fileType === 'comodato') || !appointment.files.find(f => f.fileType === 'ordenDeCargue') || appointment.files.find(f => f.fileType === 'cartaCliente')) {
                        missing = true;
                    }
                    break;
                default:
                    break;
            }
            return missing;
        }
    }

    const renderYardAppointments = (yard) => {
        let filteredAppointments = appointments.length > 0 && appointments.filter(app => app.yardId === patios.find(p => p.name === yard).id).length > 0 ? appointments.filter(app => app.yardId === patios.find(p => p.name === yard).id) : [],
            cancelledCounters = cancelledAppointmentsCounters.length > 0 && cancelledAppointmentsCounters.find(c => c.yardId === patios.find(p => p.name === yard).id) ? cancelledAppointmentsCounters.find(c => c.yardId === patios.find(p => p.name === yard).id) : null;
        if (appointments.length > 0 && appointments.filter(app => app.yardId === patios.find(p => p.name === yard).id).length > 0) {
            return (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='h6'>{yard}</Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} >
                        {filteredAppointments.map(appointment => (
                            <div key={appointment.id}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginBottom: 15,
                                    cursor: 'pointer',
                                    padding: 10,
                                    backgroundColor: isMissingDocs(appointment) ? '#fffcec' : 'unset'
                                }}
                                onClick={() => {
                                    history.push(`appointment/summary?appointmentId=${appointment.id}`)
                                }}>
                                <Grid container spacing={1}>
                                    <Grid item sm={3} xs={12} >
                                        <Typography variant='body2' >
                                            {`${appointment.firstName} ${appointment.lastName}`}
                                            <br />
                                            {appointment.truckPlate && (
                                                <>
                                                    {appointment.truckPlate}<br />
                                                </>
                                            )}
                                        </Typography>
                                    </Grid>
                                    {appointment?.operations?.length === 0 ? (
                                        <>
                                            <Grid item sm={3} xs={4} >
                                                <Typography variant='body2' >
                                                    {renderDateFormatted(appointment.dateTimestamp ? appointment.dateTimestamp : appointment.createTimestamp)}
                                                </Typography>
                                            </Grid>
                                            <Grid item sm={3} xs={4} >
                                            </Grid>
                                        </>
                                    ) : (
                                            <Grid item sm={6} xs={8}>
                                                {appointment.operations.map((operation, i) => (
                                                    <Grid key={i}
                                                        container spacing={1}>
                                                        <Grid item xs={6} >
                                                            <Typography variant='body2' >
                                                                {!operation.dateTimestamp ? (i === 0 ? renderDateFormatted(appointment.createTimestamp, { hideHour: true }) : '') : renderDateFormatted(operation.dateTimestamp, { hideDate: (i > 0 ? true : false) })}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography variant='body2' >
                                                                {operation.operationType && (
                                                                    <>
                                                                        {operation.operationType.toUpperCase()}<br />
                                                                    </>
                                                                )}
                                                                {operation.linerCode && (
                                                                    <>
                                                                        {operation.linerCode}<br />
                                                                    </>
                                                                )}
                                                                {operation.containerNumber && (
                                                                    <>
                                                                        {operation.containerNumber}<br />
                                                                    </>
                                                                )}
                                                                {operation.containerType && (
                                                                    <>
                                                                        {`${operation.containerType}${operation.containerSize}`}<br />
                                                                    </>
                                                                )}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        )}
                                    <Grid item sm={3} xs={4} style={{ alignText: 'right' }}>
                                        {renderAppointmentStatus(appointment)}
                                    </Grid>
                                </Grid>


                            </div>
                        ))}
                    </Grid>
                    {cancelledCounters && (
                        <Grid item xs={12} style={{ marginBottom: 20, marginTop: -20 }}>
                            <Typography variant='body2' color='textSecondary'>
                                {cancelledCounters.canceladas} citas canceladas
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            )
        } else {
            return null;
        }

    }

    return (
        <>
            <section className="section bg-features bg-light" id="features">
                <div className="container">
                    <Row>
                        <Col className="col-lg-12">
                            <div className="title-heading mb-5">
                                <h3 className="text-dark mb-1 font-weight-light text-uppercase">Patio de Contenedores de Colombia</h3>
                                <div className="title-border-simple position-relative"></div>
                            </div>
                        </Col>
                    </Row>
                    <Row >
                        <Col lg="12">
                            <div className="features-content">
                                <div style={{ display: 'flex', alignItems: 'center ', marginBottom: '1em' }}>
                                    <div className="features-icon" style={{ marginRight: 20 }}>
                                        <i className="pe-7s-car text-primary"></i>
                                    </div>
                                    <h4 className="font-weight-normal text-dark mb-3 mt-4" style={{ margin: 0 }}>
                                        Nueva Cita
                                    </h4>
                                </div>
                                <Row className="align-items-center" style={{ marginBottom: 10 }}>
                                    <Col lg="12">
                                        <div className="features-content">
                                            <Typography style={{ marginBottom: '10px' }}>
                                                Seleccione el patio
                                            </Typography>
                                            <div >
                                                {patios.map((patio, i) => (
                                                    <Button key={i}
                                                        variant='outlined'
                                                        color="primary"
                                                        disabled={patio.disabled ? true : false}
                                                        style={{ marginRight: '8px' }}
                                                        onClick={() => {
                                                            nuevaCita(patio.id)
                                                        }}>
                                                        {patio.name}
                                                    </Button>
                                                ))}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col lg="12" style={{ marginTop: 10, marginBottom: 20 }}>
                            <Divider />
                        </Col>
                        <Col lg="12" >
                            <div className="features-content">
                                {appointments.length > 0 && (
                                    <div style={{ display: 'flex', alignItems: 'center ', marginBottom: '1em' }}>
                                        <div className="features-icon" style={{ marginRight: 20 }}>
                                            <i className="pe-7s-date text-primary"></i>
                                        </div>
                                        <h4 className="font-weight-normal text-dark mb-3 mt-4" style={{ margin: 0 }}>Citas</h4>
                                    </div>
                                )}
                                <div style={{
                                    marginTop: appointments.length > 0 ? 'unset' : 80,
                                    minHeight: appointments.length > 0 ? 150 : 'unset',
                                }}>
                                    {fetchingAppointments ?
                                        (
                                            <CircularProgress />
                                        ) : (
                                            <div>
                                                {appointments.length > 0 && (
                                                    <>
                                                        {renderYardAppointments("Bogotá")}
                                                        {renderYardAppointments("Cali")}
                                                        {renderYardAppointments("Medellín")}
                                                        {renderYardAppointments("Pruebas")}
                                                    </>
                                                )}
                                                {appointments.length === 0 && (
                                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                        <Inbox size='large' style={{ fontSize: 40 }} color='textSecondary' />
                                                        <Typography style={{ marginBottom: '10px', textAlign: 'center' }}>
                                                            No tiene citas para los próximos dias
                                                        </Typography>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>

            <section className="section bg-features">
                <div className="container">
                    <Row className="align-items-center">
                        <Col lg="6">
                            <div className="features-content mt-32">
                                <div className="features-icon">
                                    <i className="pe-7s-display1 text-primary"></i>
                                </div>
                                <h4 className="font-weight-normal text-dark mb-3 mt-4">Reportes</h4>
                                <p className="text-muted f-14">Itaque earum rerum hic tenetur sapiente delectut reiciendis voluptatibus perspiciatis unde omnis iste natus error sit maiores alias consequatur perferendisthat doloribus asperiores repellat.</p>
                                <p className="text-muted f-14">Nam libero tempore cum soluta nobis eligendi optio cumque nihil impedit minusidquod maxime placeat facere possimus.</p>
                                <p className="mb-0 text-uppercase f-13"><Link to="/" className="text-primary">learn more <span className="right-arrow ml-1">&#x21FE;</span></Link></p>
                            </div>
                        </Col>
                        <Col lg="6">
                            <div className="features-content mt-32">
                                <div className="features-icon">
                                    <i className="pe-7s-shuffle text-primary"></i>
                                </div>
                                <h4 className="font-weight-normal text-dark mb-3 mt-4">Soluciones Estratégicas</h4>
                                <p className="text-muted f-14">Itaque earum rerum hic tenetur sapiente delectut reiciendis voluptatibus perspiciatis unde omnis iste natus error sit maiores alias consequatur perferendisthat doloribus asperiores repellat.</p>
                                <p className="text-muted f-14">Nam libero tempore cum soluta nobis eligendi optio cumque nihil impedit minusidquod maxime placeat facere possimus.</p>
                                <p className="mb-0 text-uppercase f-13"><Link to="/" className="text-primary">learn more <span className="right-arrow ml-1">&#x21FE;</span></Link></p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>

        </>
    );

}
export default Home;