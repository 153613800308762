import React, { useEffect } from 'react';
import {
    useSelector,
    useDispatch
} from 'react-redux'

import useReactRouter from 'use-react-router';

import { Row, Col } from 'reactstrap';

import clsx from 'clsx';
import { useForm } from "react-hook-form";
import Button from '@material-ui/core/Button';

import { ThemeProvider } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {
    Trucker,
    OperationInfoReadOnly,
    TimeSlotSelected
} from './components'
import API from 'services/api'
import {
    setAppointmentParameters
} from 'actions/appointmentActions'


const useStyles = makeStyles((theme) => ({
    subSections: theme.subSections,
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }
}));



const NewAppointment = (props) => {
    const { setStep } = props
    const classes = useStyles();
    const { handleSubmit } = useForm();
    const { history } = useReactRouter();
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const appointment = useSelector(state => state.appointment),
        dispatch = useDispatch(),
        yardId = useSelector(state => state.appointment.yardId),
        generalParameters = useSelector(state => state.generalParameters);

    let { patios } = generalParameters;


    const buttonClassname = clsx({
        [classes.buttonSuccess]: success,
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    })

    const onSubmitStep4 = data => {

        if (!loading) {
            setSuccess(false);
            setLoading(true);
            if (!appointment.appointment.confirmed) {

                API.Appointment.confirmAppointment({
                    appointmentId: appointment.appointment.id
                })
                    .then(response => {
                        if (response.success) {
                            dispatch(setAppointmentParameters(response.appointment))
                            setSuccess(false);
                            setLoading(false);
                            setStep(5)
                            history.push(`/appointment/step/5?appointmentId=${appointment.appointment.id}`)
                        }
                    }).catch(err => {
                        console.log(err)
                    })
                    .then(_ => {
                        setSuccess(false);
                        setLoading(false);
                    })
            } else {
                setLoading(false);
                setStep(5)
                history.push(`/appointment/step/5?appointmentId=${appointment.appointment.id}`)
            }

        }
    }

    const theme = useTheme();

    return <ThemeProvider theme={theme}>
        <section className="section  bg-light" style={{ paddingTop: 0, minHeight: '73vh', }}>
            <div className="container">

                <Row className="align-items-center">
                    <Col lg="3"></Col>
                    <Col lg="6">
                        <div className={`features-content ${classes.subSections}`}>
                            <div className="title-heading mb-5" >
                                <h4 className="font-weight-normal text-dark mb-3 mt-4">Patio de {yardId ? patios.find(p => p.id === yardId).name : ''}</h4>
                                <h4 className="font-weight-normal text-dark mb-3 mt-4">
                                    {!appointment?.appointment.confirmed ? 'Confirmar Cita' : 'Cita Confirmada'}
                                </h4>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <div>
                                        Paso 4 de 4
                                    <div className="title-border-simple position-relative"></div>
                                    </div>
                                    <div className={classes.root}>
                                        <div className={classes.wrapper}>
                                            <form onSubmit={handleSubmit(onSubmitStep4)} autoComplete="off">
                                                {!appointment?.appointment.confirmed && (
                                                    <>
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            style={{ width: 210 }}
                                                            className={buttonClassname}
                                                            disabled={loading}
                                                            type="submit"
                                                            endIcon={<CheckCircleIcon />}
                                                        >
                                                            Confirmar Cita
                                                        </Button>
                                                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                                    </>
                                                )}
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Col>

                </Row>

                <Row>
                    <Col lg="3"></Col>
                    <Col lg="6">
                        <TimeSlotSelected
                            setStep={setStep}
                        />
                    </Col>
                    <Col lg="3"></Col>
                </Row>

                <Row style={{ marginBottom: 20 }}>
                    <Col lg="3"></Col>
                    <Col lg="6" >
                        <Trucker setStep={setStep} currentStep={3} />
                    </Col>
                    <Col lg="3"></Col>
                </Row>

                <Row>
                    <Col lg="3"></Col>
                    <Col lg="6" >
                        <OperationInfoReadOnly
                            setStep={setStep}
                        />
                    </Col>
                    <Col lg="3"></Col>
                </Row>



                <form onSubmit={handleSubmit(onSubmitStep4)} autoComplete="off">
                    <Row style={{ marginTop: 20 }}>
                        <Col lg="3"></Col>
                        <Col lg="6" className={classes.subSections}>
                            <Row style={{ padding: '0 8px', justifyContent: 'flex-end' }}>

                                <div className={classes.root}>
                                    <div className={classes.wrapper}>
                                        {!appointment?.appointment.confirmed ? (
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                style={{ width: 210 }}
                                                className={buttonClassname}
                                                disabled={loading}
                                                type="submit"
                                                endIcon={<CheckCircleIcon />}
                                            >
                                                Confirmar Cita
                                            </Button>
                                        ) : (
                                                <Button
                                                    variant="contained"
                                                    color='primary'
                                                    style={{ width: 210 }}
                                                    className={buttonClassname}
                                                    disabled={loading}
                                                    type="submit"
                                                >
                                                    Continuar
                                                </Button>
                                            )}
                                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                    </div>
                                </div>

                            </Row>
                        </Col>
                        <Col lg="3"></Col>
                    </Row>
                </form>

            </div>
        </section>
    </ThemeProvider>
}

export default NewAppointment