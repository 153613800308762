import React, { useEffect, useState } from 'react'
import {
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    CircularProgress,
    makeStyles
} from '@material-ui/core'
import 'moment/locale/es'
import moment from 'moment'
import { utils } from 'lib'

import {
    useDispatch,
    useSelector
} from 'react-redux'
import API from 'services/api'

import {
    setAppointments
} from 'actions/appointmentsActions'

moment.locale('es');

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    tableContainer: {
        maxWidth: 800,
        margin: 'auto'
    }
}));

const AdminDashboard = (props) => {
    const appointments = useSelector(state => state.appointments);

    const [fetchingAppointments, setFetchingAppointments] = useState(true),
        classes = useStyles(),
        dispatch = useDispatch();

    useEffect(() => {
        if (fetchingAppointments) {
            API.Appointment.getAppointmentsListAdmin()
                .then(appointmentsResponse => {
                    if (appointmentsResponse.success) {
                        dispatch(setAppointments(appointmentsResponse.appointments))
                    }
                })
                .catch(err => {
                    console.log(err)
                })
                .then(_ => {
                    setFetchingAppointments(false)
                })
        }
    }, [fetchingAppointments, dispatch])

    return (
        <div style={{
            minHeight: '90vh'
        }}>
            <section
                className="section bg-features"
                id="features">
                <div>


                    {fetchingAppointments ?
                        (
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                minHeight: 700
                            }}>
                                <CircularProgress />
                            </div>
                        ) : (
                            <div style={{ maxWidth: 800, margin: 'auto' }}>
                                <Typography variant='h6' style={{ marginBottom: 20 }}>
                                    Citas Activas
                                </Typography>
                                <TableContainer component={Paper}
                                    className={classes.tableContainer}>
                                    <Table className={classes.table} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell># Cita</TableCell>
                                                <TableCell align="left">Patio</TableCell>
                                                <TableCell align="left">Transportista</TableCell>
                                                <TableCell align="left">Camionero</TableCell>
                                                <TableCell align="left">Estatus</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {appointments.length > 0 && appointments.map(appointment => (
                                                <TableRow key={appointment.id}>
                                                    <TableCell component="th" scope="row">
                                                        {appointment.id}
                                                    </TableCell>
                                                    <TableCell align="left">{appointment.yardName}</TableCell>
                                                    <TableCell align="left">{appointment.transportadorName}</TableCell>
                                                    <TableCell align="left">{`${appointment.firstName} ${appointment.lastName}`}</TableCell>
                                                    <TableCell align="left"> {

                                                        appointment.step === 2 ? "Falta llenar informacion" : (
                                                            appointment.step === 3 ? "Falta elegir horario" : (
                                                                appointment.step === 4 ? "Falta confirmar cita" : "Cita programada"
                                                            )
                                                        )
                                                    }
                                                        {appointment.dateTimestamp && (
                                                            <>
                                                                <br />
                                                                {utils.uppercaseFirstLetter(moment.utc(appointment.dateTimestamp).format("MMMM DD HH:mm"))}
                                                            </>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                            {appointments.length === 0 && (
                                                <TableRow>
                                                    <TableCell colSpan='5'>
                                                        <div style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            minHeight: 200
                                                        }}>
                                                            <Typography style={{ marginBottom: '10px' }}>
                                                                No hay citas
                                                            </Typography>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                            </div>
                        )}
                </div>
            </section>
        </div>
    )
}

export default AdminDashboard;
