import React, { useState } from 'react'
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Card,
    CardContent,
    Typography,
    CircularProgress,
    makeStyles
} from '@material-ui/core'
import 'moment/locale/es'
import moment from 'moment-timezone'
import { utils } from 'lib'
import {
    useDispatch,
    useSelector
} from 'react-redux'
import { Row, Col } from 'reactstrap';
import { setAppointmentTimeSlot, setAppointmentParameters } from 'actions/appointmentActions'
import API from 'services/api'
import config from 'config'

moment.locale('es');

const useStyles = makeStyles((theme) => ({
    subSections: theme.subSections,
}));

const TimeSlot = ({ slotId, slot, day, dateTimestamp, available, onClick }) => {
    return (
        <Button
            key={slot}
            variant="contained"
            style={{
                margin: "0 5px 10px 5px",
                width: 'auto',
                padding: '5px 30px'
            }}
            disabled={!available}
            color="secondary"
            onClick={() => {
                onClick({ slotId, day, slot, dateTimestamp })
            }}
        >
            <div style={{ maxWidth: 50 }}>
                {slot}
            </div>
        </Button>
    )
}

const blocks = [{
    date: utils.uppercaseFirstLetter(moment().format('dddd DD')),
    hours: [
        {
            hour: '8 AM',
            slots: [
                {
                    available: true,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).hour(8).minute(0).second(0).format()).format(),
                    slot: "08:00 08:14"
                },
                {
                    available: false,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).hour(8).minute(15).second(0).format()).format(),
                    slot: "08:15 08:29"
                },
                {
                    available: false,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).hour(8).minute(30).second(0).format()).format(),
                    slot: "08:30 08:44"
                },
                {
                    available: true,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).hour(8).minute(45).second(0).format()).format(),
                    slot: "08:45 08:59"
                },
            ]
        },
        {
            hour: '9 AM',
            slots: [
                {
                    available: false,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).hour(8).minute(0).second(0).format()).format(),
                    slot: "09:00 09:14"
                },
                {
                    available: false,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).hour(9).minute(15).second(0).format()).format(),
                    slot: "09:15 09:29"
                },
                {
                    available: true,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).hour(9).minute(30).second(0).format()).format(),
                    slot: "09:30 09:44"
                },
                {
                    available: false,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).hour(9).minute(45).second(0).format()).format(),
                    slot: "09:45 09:59"
                },
            ]
        },
        {
            hour: '10 AM',
            slots: [
                {
                    available: true,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).hour(10).minute(0).second(0).format()).format(),
                    slot: "10:00 10:14"
                },
                {
                    available: false,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).hour(10).minute(15).second(0).format()).format(),
                    slot: "10:15 10:29"
                },
                {
                    available: true,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).hour(10).minute(30).second(0).format()).format(),
                    slot: "10:30 10:44"
                },
                {
                    available: false,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).hour(10).minute(45).second(0).format()).format(),
                    slot: "10:45 10:59"
                },
            ]
        },
        {
            hour: '11 AM',
            slots: [
                {
                    available: false,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).hour(11).minute(0).second(0).format()).format(),
                    slot: "11:00 11:14"
                },
                {
                    available: false,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).hour(11).minute(15).second(0).format()).format(),
                    slot: "11:15 11:29"
                },
                {
                    available: false,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).hour(11).minute(30).second(0).format()).format(),
                    slot: "11:30 11:44"
                },
                {
                    available: true,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).hour(11).minute(45).second(0).format()).format(),
                    slot: "11:45 11:59"
                },
            ]
        },
        {
            hour: '12 AM',
            slots: [
                {
                    available: true,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).hour(12).minute(0).second(0).format()).format(),
                    slot: "12:00 12:14"
                },
                {
                    available: false,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).hour(12).minute(15).second(0).format()).format(),
                    slot: "12:15 12:29"
                },
                {
                    available: false,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).hour(12).minute(30).second(0).format()).format(),
                    slot: "12:30 12:44"
                },
                {
                    available: false,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).hour(12).minute(45).second(0).format()).format(),
                    slot: "12:45 12:59"
                },
            ]
        }
    ]
},
{
    date: utils.uppercaseFirstLetter(moment().add(1, 'day').format('dddd DD')),
    hours: [
        {
            hour: '8 AM',
            slots: [
                {
                    available: true,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).add(1, 'day').hour(8).minute(0).second(0).format()).format(),
                    slot: "08:00 08:14"
                },
                {
                    available: true,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).add(1, 'day').hour(8).minute(15).second(0).format()).format(),
                    slot: "08:15 08:29"
                },
                {
                    available: false,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).add(1, 'day').hour(8).minute(30).second(0).format()).format(),
                    slot: "08:30 08:44"
                },
                {
                    available: true,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).add(1, 'day').hour(8).minute(45).second(0).format()).format(),
                    slot: "08:45 08:59"
                },
            ]
        },
        {
            hour: '9 AM',
            slots: [
                {
                    available: false,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).add(1, 'day').hour(9).minute(0).second(0).format()).format(),
                    slot: "09:00 09:14"
                },
                {
                    available: false,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).add(1, 'day').hour(9).minute(15).second(0).format()).format(),
                    slot: "09:15 09:29"
                },
                {
                    available: true,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).add(1, 'day').hour(9).minute(30).second(0).format()).format(),
                    slot: "09:30 09:44"
                },
                {
                    available: true,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).add(1, 'day').hour(9).minute(45).second(0).format()).format(),
                    slot: "09:45 09:59"
                },
            ]
        },
        {
            hour: '10 AM',
            slots: [
                {
                    available: false,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).add(1, 'day').hour(10).minute(0).second(0).format()).format(),
                    slot: "10:00 10:14"
                },
                {
                    available: true,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).add(1, 'day').hour(10).minute(15).second(0).format()).format(),
                    slot: "10:15 10:29"
                },
                {
                    available: true,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).add(1, 'day').hour(10).minute(30).second(0).format()).format(),
                    slot: "10:30 10:44"
                },
                {
                    available: false,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).add(1, 'day').hour(10).minute(45).second(0).format()).format(),
                    slot: "10:45 10:59"
                },
            ]
        },
        {
            hour: '11 AM',
            slots: [
                {
                    available: true,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).add(1, 'day').hour(11).minute(0).second(0).format()).format(),
                    slot: "11:00 11:14"
                },
                {
                    available: false,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).add(1, 'day').hour(11).minute(15).second(0).format()).format(),
                    slot: "11:15 11:29"
                },
                {
                    available: false,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).add(1, 'day').hour(11).minute(30).second(0).format()).format(),
                    slot: "11:30 11:44"
                },
                {
                    available: false,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).add(1, 'day').hour(11).minute(45).second(0).format()).format(),
                    slot: "11:45 11:59"
                },
            ]
        },
        {
            hour: '12 AM',
            slots: [
                {
                    available: false,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).add(1, 'day').hour(12).minute(0).second(0).format()).format(),
                    slot: "12:00 12:14"
                },
                {
                    available: false,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).add(1, 'day').hour(12).minute(15).second(0).format()).format(),
                    slot: "12:15 12:29"
                },
                {
                    available: true,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).add(1, 'day').hour(12).minute(30).second(0).format()).format(),
                    slot: "12:30 12:44"
                },
                {
                    available: false,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).add(1, 'day').hour(12).minute(45).second(0).format()).format(),
                    slot: "12:45 12:59"
                },
            ]
        }
    ]
},
{
    date: utils.uppercaseFirstLetter(moment().add(2, 'day').format('dddd DD')),
    hours: [
        {
            hour: '8 AM',
            slots: [
                {
                    available: true,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).add(2, 'day').hour(8).minute(0).second(0).format()).format(),
                    slot: "08:00 08:14"
                },
                {
                    available: false,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).add(2, 'day').hour(8).minute(15).second(0).format()).format(),
                    slot: "08:15 08:29"
                },
                {
                    available: true,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).add(2, 'day').hour(8).minute(30).second(0).format()).format(),
                    slot: "08:30 08:44"
                },
                {
                    available: false,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).add(2, 'day').hour(8).minute(45).second(0).format()).format(),
                    slot: "08:45 08:59"
                },
            ]
        },
        {
            hour: '9 AM',
            slots: [
                {
                    available: true,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).add(2, 'day').hour(9).minute(0).second(0).format()).format(),
                    slot: "09:00 09:14"
                },
                {
                    available: false,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).add(2, 'day').hour(9).minute(15).second(0).format()).format(),
                    slot: "09:15 09:29"
                },
                {
                    available: false,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).add(2, 'day').hour(9).minute(30).second(0).format()).format(),
                    slot: "09:30 09:44"
                },
                {
                    available: true,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).add(2, 'day').hour(9).minute(45).second(0).format()).format(),
                    slot: "09:45 09:59"
                },
            ]
        },
        {
            hour: '10 AM',
            slots: [
                {
                    available: false,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).add(2, 'day').hour(10).minute(0).second(0).format()).format(),
                    slot: "10:00 10:14"
                },
                {
                    available: true,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).add(2, 'day').hour(10).minute(15).second(0).format()).format(),
                    slot: "10:15 10:29"
                },
                {
                    available: false,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).add(2, 'day').hour(10).minute(30).second(0).format()).format(),
                    slot: "10:30 10:44"
                },
                {
                    available: true,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).add(2, 'day').hour(10).minute(45).second(0).format()).format(),
                    slot: "10:45 10:59"
                },
            ]
        },
        {
            hour: '11 AM',
            slots: [
                {
                    available: false,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).add(2, 'day').hour(11).minute(0).second(0).format()).format(),
                    slot: "11:00 11:14"
                },
                {
                    available: true,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).add(2, 'day').hour(11).minute(15).second(0).format()).format(),
                    slot: "11:15 11:29"
                },
                {
                    available: false,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).add(2, 'day').hour(11).minute(30).second(0).format()).format(),
                    slot: "11:30 11:44"
                },
                {
                    available: true,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).add(2, 'day').hour(11).minute(45).second(0).format()).format(),
                    slot: "11:45 11:59"
                },
            ]
        },
        {
            hour: '12 AM',
            slots: [
                {
                    available: false,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).add(2, 'day').hour(12).minute(0).second(0).format()).format(),
                    slot: "12:00 12:14"
                },
                {
                    available: true,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).add(2, 'day').hour(12).minute(15).second(0).format()).format(),
                    slot: "12:15 12:29"
                },
                {
                    available: false,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).add(2, 'day').hour(12).minute(30).second(0).format()).format(),
                    slot: "12:30 12:44"
                },
                {
                    available: true,
                    id: 1,
                    dateTimestamp: moment.utc(moment().tz(config.timezone).add(2, 'day').hour(12).minute(45).second(0).format()).format(),
                    slot: "12:45 12:59"
                },
            ]
        }
    ]
}
]


const TimeSlots = (props) => {
    const { fnGotoStep4 } = props;
    const classes = useStyles();
    const [fetching, setFetching] = useState(false);
    const [open, setOpen] = useState(false);
    const [selectedSlot, setSelectedSlot] = useState("");
    const appointment = useSelector(state => state.appointment);
    const [daySlot, setDaySlot] = useState({});

    const dispatch = useDispatch();

    const handleClickOpen = ({ slotId, day, slot, dateTimestamp }) => {
        setSelectedSlot(day + " " + slot)
        setDaySlot({ slotId, day, slot, dateTimestamp })
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const confirmSlotSelected = () => {
        setFetching(true)
        API.Appointment.saveAppointmentDateTimeSlot({
            appointmentId: appointment.appointment.id,
            date: daySlot.day,
            slot: daySlot.slot,
            slotId: daySlot.slotId,
            dateTimestamp: daySlot.dateTimestamp
        })
            .then(response => {
                if (response.success) {
                    fnGotoStep4(selectedSlot);
                    dispatch(setAppointmentParameters(response.appointment))
                    dispatch(setAppointmentTimeSlot(daySlot))
                }
                setFetching(false)
            })
            .catch(err => {
                console.log(err)
            }).then(_ => {
                handleClose();
                setFetching(false)
            })
    }

    return (<>


        <Row>

            <Col lg="3"></Col>

            <Col className="col" style={{
                margin: 'auto',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: "space-between"
            }} >

                <Card style={{ width: '100%' }} className={classes.subSections}>
                    <Typography variant='h6'
                        style={{
                            marginBottom: 8,
                            marginTop: 16,
                            textAlign: 'center'
                        }}>
                        {'Seleccione el día y la hora de su cita'}
                    </Typography>
                    <CardContent style={{
                        display: "flex",
                        flexDirection: "row"
                    }}>
                        {blocks.map((block, i) => (
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                flex: 1
                            }}
                                key={`i-${i}`}>
                                <h6 className="font-weight-normal mb-3" style={{ marginLeft: 8 }}>{block.date}</h6>

                                {block.hours.map((hour, j) => (
                                    <div key={`j-${j}`}>
                                        <h6 className="font-weight-normal mb-3" style={{ marginLeft: 8 }}>{hour.hour}</h6>

                                        {hour.slots.map((slot, k) => (
                                            <div key={`k-${k}`}>
                                                <TimeSlot
                                                    key={k}
                                                    available={slot.available}
                                                    onClick={handleClickOpen}
                                                    day={block.date}
                                                    slotId={slot.id}
                                                    dateTimestamp={slot.dateTimestamp}
                                                    slot={slot.slot}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </CardContent></Card>
            </Col>
            <Col lg="3"></Col>
        </Row>

        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Selección de Hora"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" component='div'>
                    Ha seleccionado entregar el contenedor el día<br />
                    <Typography variant='body1' className="font-weight-normal mb-3">{selectedSlot}</Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={fetching}
                    onClick={handleClose} color="primary">
                    No
                </Button>
                <Button
                    disabled={fetching}
                    onClick={confirmSlotSelected} color="primary" autoFocus>
                    De Acuerdo
                    {fetching && <CircularProgress size={16} style={{ marginLeft: 5 }} />}
                </Button>
            </DialogActions>
        </Dialog>
    </>
    )
}


export default TimeSlots;