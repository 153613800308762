import React from 'react'
import {
    useSelector,
} from 'react-redux'
import useReactRouter from 'use-react-router';
import {
    Card,
    CardContent,
    Typography,
    Button,
    makeStyles
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    subSections: theme.subSections
}));


const TimeSlotSelected = (props) => {
    const dateTimeSlot = useSelector(state => state.appointment.dateTimeSlot),
        appointment = useSelector(state => state.appointment),
        { setStep } = props,
        classes = useStyles(),
        { history } = useReactRouter();


    if (!dateTimeSlot) return null;


    return (
        <Card style={{ marginBottom: 20 }} className={classes.subSections}>
            <CardContent style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                paddingBottom: 16
            }}>
                <Typography variant='h6' style={{ marginBottom: 0 }}>
                    {dateTimeSlot.day} - {dateTimeSlot.slot}
                </Typography>
                <Button size='small'
                    variant='outlined'
                    onClick={() => {
                        setStep(2)
                        history.push(`/appointment/step/3?appointmentId=${appointment.appointment.id}&target=timeSlots`)
                    }}>
                    Cambiar
                </Button>
            </CardContent>
        </Card>
    )
}

export default TimeSlotSelected;