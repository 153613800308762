import React, { useEffect, useState } from 'react';
import useReactRouter from 'use-react-router';
import { ThemeProvider } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import API from 'services/api'
import {
    CircularProgress,
} from '@material-ui/core';
import {
    useDispatch,
    useSelector
} from 'react-redux'

import {
    setAppointment,
    setFetchingAppointment,
    setFetchedAppointment
} from 'actions/appointmentActions'

//New Appointment STEPS
import {
    Step1,
    Step2,
    Step3,
    Step4,
    Step5
} from '../components/newAppointment'

const useStyles = makeStyles((theme) => ({
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }
}));


const NewAppointment = () => {
    const [step, setStep] = React.useState(1);
    const theme = useTheme(),
        { location, match, history } = useReactRouter(),
        [loading, setLoading] = useState(true),
        [fetching, setFetching] = useState(false),
        classes = useStyles(),
        appointment = useSelector(state => state.appointment),

        dispatch = useDispatch();


    useEffect(() => {

        let queryParams = new URLSearchParams(location.search)
        let appointmentId = queryParams.get("appointmentId") || null;
        let isMounted = true;
        if (isMounted && (!appointment.appointment ||
            (appointment && appointment.appointment && appointmentId && parseInt(appointment.appointment.id) !== parseInt(appointmentId)))) {
            if (appointmentId) {
                if (match.params.stepId && !fetching) {
                    setLoading(true)
                    setFetching(true)
                    dispatch(setFetchingAppointment())
                    API.Appointment.getAppointment({
                        appointmentId: appointmentId
                    })
                        .then(response => {
                            if (response.success && response.data) {
                                dispatch(setAppointment(response.data))
                                setLoading(false)
                                setStep(parseInt(match.params.stepId));
                                history.push(`/appointment/step/${match.params.stepId}?appointmentId=${response.data.appointment.id}`)
                                dispatch(setFetchedAppointment())
                            } else if (!response.data) {
                                history.push(`/`)
                            }
                            setFetching(false)
                        }).catch(err => {
                            console.log(err)
                        })
                        .then(_ => {
                            setFetching(false)
                        })
                }
            } else {
                if (match.params.stepId) {
                    setStep(parseInt(match.params.stepId))
                }
                setFetching(false)
                setLoading(false)
            }
        }
        else {
            setLoading(false)
            setFetching(false)
        }
        return () => {
            isMounted = false;
        }
    }, [location, setStep, match, dispatch, appointment, history, fetching])

    useEffect(() => {
        window.scrollTo(0, 0);
    })

    useEffect(() => {
        if (match.params.stepId && parseInt(match.params.stepId) !== step) {
            setStep(parseInt(match.params.stepId))
        }
    }, [match.params.stepId, step])

    const setNewStep = (step) => {
        setStep(step)
    }


    return <ThemeProvider theme={theme}>
        <section className="section  bg-light">
            <div className="container">

                {loading ?
                    <div style={{
                        minHeight: '75vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <CircularProgress size={24} className={classes.buttonProgress} />
                    </div>
                    : <>
                        {step === 1 && <>
                            <Step1
                                setStep={setNewStep}
                            />
                        </>}


                        {step === 5 && <>
                            <Step5
                                setStep={setNewStep}
                            />
                        </>}


                        {step === 4 && <>
                            <Step4
                                setStep={setNewStep}
                            />
                        </>}


                        {step === 3 && <>
                            <Step3
                                setStep={setNewStep}
                            />
                        </>}


                        {step === 2 && <>
                            <Step2
                                setStep={setNewStep}
                            />
                        </>}
                    </>}

            </div>
        </section>
    </ThemeProvider>
}

export default NewAppointment