import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
    subSections: {
        maxWidth: 450,
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: 0
    },
    overrides: {
        MuiOutlinedInput: {
            root: {
                "&$error": {
                    "backgroundColor": "#ffffa3"
                }
            }
        },
    }
})

export default theme