import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import queryString from 'query-string';
import config from 'config'
import {
    Home,
    Appointment,
    Layout,
    AdminDashboard,
    SessionExpired,
    AppointmentSummary,
    TruckerUploadAppointmentDocs,
    TruckerUploadAppointmentDocsV2,
    Help
} from './pages';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import store from './store'
import { MuiThemeProvider } from '@material-ui/core/styles'
import theme from 'theme';

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
    <Route {...rest} render={props => (
        <Layout>
            <Component {...props} />
        </Layout>
    )} />
)

class Root extends React.Component {
    componentDidMount = () => {
        let params = queryString.parse(window.location.search);
        if (params && params.token) {
            localStorage.setItem('pcc_jwt_token', params.token)
            localStorage.setItem('pcc_account_id', params.id)
            localStorage.setItem('pcc_account_transportador_id', params.transportadorId)
            localStorage.setItem('pcc_account_profileId', params.profileId)
            parseInt(params.profileId) === 1 ? window.history.pushState({}, document.title, '/dashboard') : window.history.pushState({}, document.title, '/');
        } else {
            if (!params.appointmentId && !localStorage.getItem('pcc_account_id') && 
            (window.location.pathname.indexOf('/documentos/') < 0 && 
            window.location.pathname.indexOf('/help') < 0)) {
                localStorage.removeItem('pcc_jwt_token')
                localStorage.removeItem('pcc_account_id')
                localStorage.removeItem('pcc_account_transportador_id')
                localStorage.removeItem('pcc_account_profileId')
                return window.location.href = `${config.web.url}`;
            }
        }
    }


    render() {

        return (
            <Provider store={store}>
                <MuiThemeProvider theme={theme}>
                    <BrowserRouter basename={'/'} >
                        <Switch>
                            <AppRoute exact path={`${process.env.PUBLIC_URL}/`} layout={Layout} component={Home} />
                            <AppRoute exact path={`${process.env.PUBLIC_URL}/`} layout={Layout} component={Home} />
                            <AppRoute exact path={`${process.env.PUBLIC_URL}/dashboard`} layout={Layout} component={AdminDashboard} />
                            <AppRoute exact path={`${process.env.PUBLIC_URL}/appointment/step/:stepId`} layout={Layout} component={Appointment} />
                            <AppRoute exact path={`${process.env.PUBLIC_URL}/appointment/summary`} layout={Layout} component={AppointmentSummary} />
                            <AppRoute exact path={`${process.env.PUBLIC_URL}/sessionExpired`} layout={Layout} component={SessionExpired} />
                            <Route exact path={`${process.env.PUBLIC_URL}/documentos/:appointmentId`} component={TruckerUploadAppointmentDocs} />
                            <Route exact path={`${process.env.PUBLIC_URL}/documentos/:truckerId/sms/:codigo`} component={TruckerUploadAppointmentDocsV2} />
                            <Route exact path={`${process.env.PUBLIC_URL}/uploadDocs`} component={TruckerUploadAppointmentDocs} />
                            <Route exact path={`${process.env.PUBLIC_URL}/Help`} component={Help} />
                            <Redirect from='*' to="/" />
                        </Switch>
                    </BrowserRouter>
                </MuiThemeProvider>
            </Provider>
        );
    }
}

ReactDOM.render(<Root />, document.getElementById('root'));
serviceWorker.unregister();
