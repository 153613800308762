import React, { useEffect, useState } from 'react'
import useReactRouter from 'use-react-router';
import {
    useDispatch,
    useSelector
} from 'react-redux'
import {
    setAppointment,
    setFetchingAppointment,
    setFetchedAppointment
} from 'actions/appointmentActions'
import { ThemeProvider } from '@material-ui/core/styles';
import {
    CircularProgress,
    makeStyles,
    useTheme
} from '@material-ui/core';
import { AppointmentSummary as AppointmentSummaryComponent } from 'components/newAppointment/components'

import API from 'services/api'

const useStyles = makeStyles((theme) => ({
}));


const AppointmentSummary = (props) => {
    const { location, match, history } = useReactRouter(),
        [loading, setLoading] = useState(true),
        [fetching, setFetching] = useState(false),
        classes = useStyles(),
        appointment = useSelector(state => state.appointment),
        theme = useTheme(),
        dispatch = useDispatch();


    useEffect(() => {
        window.scrollTo(0, 0);
    })

    useEffect(() => {

        let queryParams = new URLSearchParams(location.search)
        let appointmentId = queryParams.get("appointmentId") || null;
        let isMounted = true;

        if (isMounted && (!appointment.appointment ||
            (appointment && appointment.appointment && appointmentId && parseInt(appointment.appointment.id) !== parseInt(appointmentId)))) {
            if (appointmentId) {
                if (!fetching) {
                    setLoading(true)
                    setFetching(true)
                    dispatch(setFetchingAppointment())
                    API.Appointment.getAppointment({
                        appointmentId: appointmentId
                    })
                        .then(response => {
                            if (response.success && response.data) {
                                dispatch(setAppointment(response.data))
                                setLoading(false)
                                dispatch(setFetchedAppointment())
                            } else if (!response.data) {
                                history.push(`/`)
                            }
                            setFetching(false)
                        }).catch(err => {
                            console.log(err)
                        }).then(_ => {
                            setFetching(false)
                        })
                }
            } else {
                setFetching(false)
                setLoading(false)
            }
        }
        else {
            setLoading(false)
            setFetching(false)
        }
        return () => {
            isMounted = false;
        }
    }, [location, match, dispatch, appointment, history, fetching])
    return (
        <ThemeProvider theme={theme}>
            <section className="section  bg-light" style={{ minHeight: '90vh', }}>
                <div className="container" >
                    {loading ?
                        <div style={{
                            minHeight: '75vh',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <CircularProgress size={24} className={classes.buttonProgress} />
                        </div>
                        : <>
                            <div>
                                <AppointmentSummaryComponent setStep={() => { }} />
                            </div>
                        </>
                    }
                </div>
            </section>
        </ThemeProvider>
    )
}


export default AppointmentSummary;