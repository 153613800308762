import React, { useState, useEffect } from 'react'
import {
    Typography,
    makeStyles,
    CircularProgress,
} from '@material-ui/core'
import {
    useDispatch,
} from 'react-redux'
import {
    DocumentContact
} from '.'
import {
    addAppointmentAttachments,
} from 'actions/appointmentActions'
import API from 'services/api'
import { animateScroll as scroll } from 'react-scroll'

const useStyles = makeStyles((theme) => ({
    subSections: theme.subSections,
    document__link: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textDecoration: 'underline !important',
        marginBottom: 10
    },
    card__content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        position: 'relative',
        paddingBottom: '16px !important'
    },
    add__document__button: {
        cursor: 'pointer',
        padding: 10,
        borderRadius: 5,
        border: 'solid thin #007bff',
        '&:hover': {
            backgroundColor: 'rgba(0,133,255,0.3)'
        }
    }
}));



const AppointmentTruckerDocs = (props) => {
    const { appointment, showActionResult } = props,
        classes = useStyles(),
        dispatch = useDispatch(),
        [showSuccessUpload, setShowSuccessUpload] = useState(false),
        [saving, setSaving] = useState(null),
        [successMessage, setSuccessMessage] = useState(''),
        [uploadedDocLinkText, setUploadedDocLinkText] = useState(null),
        [uploadedDoc, setUploadedDoc] = useState(null),
        [showDocumentContact, setShowDocumentContact] = useState(false),
        [showLoadingDocument, setShowLoadingDocument] = useState(false),
        [uploadingDocument, setUploadingDocument] = useState(''),
        { yardOperationDocumentChecklist } = appointment;



    const scrollToTop = function () {
        scroll.scrollToTop({ duration: 400 })
    }



    useEffect(() => {
        navigator.getMedia = (navigator.getUserMedia ||
            navigator.webkitGetUserMedia ||
            navigator.mozGetUserMedia ||
            navigator.msGetUserMedia);
        if (!navigator.getMedia) {
            console.error("Your browser doesn't have support for the navigator.getUserMedia interface.");
        }
    }, [])



    const handleChooseFile = (e) => {
        e.stopPropagation();
        scrollToTop()
        if (e.target.files && e.target.files.length > 0 && appointment) {
            let documentTypeId = null,
                message = '',
                linkText = '';
            let requiredFile = appointment?.requiredDocuments.find(d => d.type === e.target.name);


            setShowLoadingDocument(true)
            setUploadingDocument(requiredFile.name)
            showActionResult(true)
            setUploadedDocLinkText(null)
            setSaving(e.target.name);
            message = `Gracias por subir la foto de ${requiredFile.name}`
            linkText = requiredFile.name;
            documentTypeId = requiredFile.documentTypeId;

            API.Appointment.uploadYardOperationAttachments({
                documentType: e.target.name,
                filesAttachments: e.target.files,
                appointmentId: appointment.appointment.id,
                documentTypeId,
                truckerId: appointment.trucker.truckerId
            })
                .then(response => {
                    if (response.success) {
                        dispatch(addAppointmentAttachments(response.data))
                        setSaving(null)
                        setShowSuccessUpload(true)
                        setSuccessMessage(message)
                        showActionResult(true)
                        let attachments = response.data;
                        setUploadedDocLinkText(linkText)
                        setUploadedDoc(attachments.find(a => a.idDocumentType === documentTypeId));
                        setShowLoadingDocument(false)
                        setUploadingDocument('');
                        if (attachments.find(doc => doc.idDocumentType === 11) && attachments.find(doc => doc.idDocumentType === 13) && attachments.find(doc => doc.idDocumentType === 10)) {
                            API.Appointment.saveDocumentAction({
                                yardOperationId: appointment.appointment.id,
                                allFilesUploaded: true
                            })
                        }
                    }
                }).catch(err => {
                    console.log(err)
                })
                .then(_ => {
                    setSaving(false)
                })
        }
    }

    const showContactAddedSuccessfully = () => {
        let message = 'Gracias por la información de contacto para conseguir los documentos.'
        setShowSuccessUpload(true)
        setSuccessMessage(message)
        showActionResult(true)
    }

    const renderDocumentComponent = (requiredDoc, index) => {
        return (
            <div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 20,
                    maxWidth: '100%'
                }}>

                    {appointment?.attachments && appointment.attachments.filter(attach => attach.idDocumentType === requiredDoc.documentTypeId).length === 0 && (
                        <>
                            <span>{requiredDoc.name}</span>
                            <br />
                        </>
                    )}

                    {appointment?.attachments && appointment.attachments.filter(attach => attach.idDocumentType === requiredDoc.documentTypeId).length > 0 &&
                        [{ ...appointment.attachments.filter(attach => attach.idDocumentType === requiredDoc.documentTypeId)[0] }].map((attach, i) => (
                            <Typography
                                key={i}
                                variant='body1'
                                noWrap
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginBottom: 10
                                }}>
                                <a href={attach.location}
                                    className={classes.document__link}
                                    style={{ marginRight: 5 }}
                                    title="Apointment Attach"
                                    target="_blank" rel="noopener noreferrer">
                                    {requiredDoc.name}
                                </a>
                            </Typography>
                        ))}


                    {yardOperationDocumentChecklist.find(d => d.documentRequirementId === requiredDoc.documentTypeId && d.checked === 1) && (
                        <span style={{ color: 'green', marginBottom: 20 }}>
                            El documento ha sido revisado y aprobado.
                        </span>
                    )}

                    {yardOperationDocumentChecklist.find(d => d.documentRequirementId === requiredDoc.documentTypeId && d.checked === -1) && (
                        <>
                            <div style={{ color: 'red' }}>
                                El documento tiene problemas.
                                    </div>
                            <div style={{ color: 'red', marginBottom: 20 }}>
                                Estamos trabajando en la solución.
                                    </div>
                        </>
                    )}

                    {appointment.attachments.filter(attach => attach.idDocumentType === requiredDoc.documentTypeId).length > 0 && (
                        <>
                            {yardOperationDocumentChecklist.find(d => d.documentRequirementId === requiredDoc.documentTypeId && (d.checked === 0 || d.checked === null)) && (
                                <>
                                    <span>
                                        El documento está pendiente por revisar.
                                    </span>
                                    <span style={{ marginBottom: 20 }}>
                                        Le avisaremos cuando esté aprobado.
                                    </span>
                                </>
                            )}
                        </>
                    )}


                    {(yardOperationDocumentChecklist.find(d =>

                        d.documentRequirementId === requiredDoc.documentTypeId && d.checked < 1)
                        || yardOperationDocumentChecklist.length === 0
                        || !yardOperationDocumentChecklist.find(
                            d => d.documentRequirementId === requiredDoc.documentTypeId))

                        && (
                            <div>
                                <label htmlFor={`upload_file${requiredDoc.type}`}
                                    style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                                    <input type='button'
                                        style={{ width: '100%' }}
                                        disabled={saving === requiredDoc.type} onClick={e => {
                                            document.getElementById(`upload_file${requiredDoc.type}`).click();
                                        }} value={appointment?.attachments && appointment.attachments.filter(attach => attach.idDocumentType === requiredDoc.documentTypeId).length > 0 ? `Agregar Otra Foto de ${requiredDoc.name}` : `Agregar Foto de ${requiredDoc.name}`} />
                                    {saving === requiredDoc.type && <CircularProgress size={24} style={{ marginTop: 10 }} />}
                                </label>
                                <input
                                    accept="image/*,image/jpeg,image/gif,image/png,application/pdf,image/x-eps,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                    style={{ display: 'none' }}
                                    id={`upload_file${requiredDoc.type}`}
                                    name={requiredDoc.type}
                                    type="file"
                                    onChange={handleChooseFile}
                                />
                            </div>
                        )}
                </div>
                <div style={{ marginTop: 5, marginBottom: 20 }} />
            </div>
        )
    }


    let notUploadedDocuments = appointment?.requiredDocuments ?
        appointment
            .requiredDocuments
            .filter(rd =>
                (appointment
                    .attachments
                    .filter(attach => attach.idDocumentType === rd.documentTypeId))
                    .length === 0 ||
                yardOperationDocumentChecklist
                    .find(d => d.documentRequirementId === rd.documentTypeId
                        && d.checked === -1)
            ) :
        [],
        uploadedDocuments = appointment && appointment.requiredDocuments ?
            appointment.requiredDocuments.filter(rd =>
                (appointment.attachments.filter(attach =>
                    (attach.idDocumentType === rd.documentTypeId)).length > 0
                    && (yardOperationDocumentChecklist.length === 0 ? true : yardOperationDocumentChecklist.find(d =>
                        d.documentRequirementId === rd.documentTypeId && d.checked !== -1
                    ))
                )) : [],
        pendingRequiredDocuments = notUploadedDocuments.length > 0 ? true : false,
        allDocumentsUploaded = notUploadedDocuments.length === 0 ? true : false,
        allDocumentsApproved = yardOperationDocumentChecklist.length === 0 ? false : (yardOperationDocumentChecklist.filter(d => d.checked !== 1).length > 0 ? false : true);




    return (
        <div>

            {showLoadingDocument && (
                <>
                    <div style={{ textAlign: 'center', marginTop: 80 }}>
                        <h5>Subiendo foto de {uploadingDocument}</h5>
                        <div>
                            <CircularProgress size={40} style={{ marginTop: 20 }} />
                        </div>
                    </div>
                </>
            )}

            {!showLoadingDocument && (
                <>
                    {showSuccessUpload ? (
                        <div style={{ textAlign: 'center' }}>
                            <br />
                            <br />
                            <span style={{ textAlign: 'center' }}>
                                {successMessage}
                            </span>
                            <br />
                            <br />
                            {uploadedDoc && (
                                <>
                                    <a href={uploadedDoc.location}
                                        className={classes.document__link}
                                        title={uploadedDocLinkText}
                                        target="_blank" rel="noopener noreferrer">
                                        {uploadedDocLinkText}
                                    </a>
                                </>
                            )}
                            <br />
                            <br />
                            {/* Solicitamos de una vez los archivos faltantes para que no tenga que ir atras. */}

                            {notUploadedDocuments.filter(doc => doc.documentTypeId !== uploadedDoc.idDocumentType &&
                                yardOperationDocumentChecklist.find(dclst =>
                                    dclst.documentRequirementId === doc.documentTypeId && dclst.checked !== -1
                                )).length > 0 && (
                                    <>
                                        <span style={{ textAlign: 'center' }}>
                                            {'Necesitamos todavía los siguientes documentos:'}
                                        </span>
                                        <br />
                                        <br />
                                    </>
                                )}
                            {notUploadedDocuments.filter(doc => doc.documentTypeId !== uploadedDoc.idDocumentType &&
                                yardOperationDocumentChecklist.find(dclst =>
                                    dclst.documentRequirementId === doc.documentTypeId && dclst.checked !== -1
                                )).map((doc, indx) => (
                                    <div key={indx}>
                                        <div>
                                            <label htmlFor={`upload_file${doc.type}`}
                                                style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                                                <input type='button'
                                                    style={{ width: '100%' }}
                                                    disabled={saving === doc.type} onClick={e => {
                                                        document.getElementById(`upload_file${doc.type}`).click();
                                                    }} value={`Agregar Foto de ${doc.name}`} />
                                                {saving === doc.type && <CircularProgress size={24} style={{ marginTop: 10 }} />}
                                            </label>
                                            <input
                                                accept="image/*,image/jpeg,image/gif,image/png,application/pdf,image/x-eps,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                style={{ display: 'none' }}
                                                id={`upload_file${doc.type}`}
                                                name={doc.type}
                                                type="file"
                                                onChange={handleChooseFile}
                                            />
                                        </div>
                                    </div>
                                ))}

                            <br />
                            <input onClick={() => {
                                setShowSuccessUpload(false)
                                showActionResult(false)
                                setUploadedDoc(null)
                                setShowDocumentContact(false)
                            }} style={{ margin: 'auto', background: 'transparent', border: 'solid thin #333333' }}
                                type='button'
                                value='Regresar'
                            />
                        </div>
                    ) : (
                            <>
                                {appointment?.appointment && !showDocumentContact && (
                                    <div >
                                        <div className={classes.subSections}>
                                            {(allDocumentsApproved) ?
                                                (
                                                    <>
                                                        <h6>
                                                            Gracias, ya tenemos toda la documentación requerida.
                                                            Estamos atentos a su llegada al patio.
                                                    </h6>
                                                    </>
                                                )
                                                :
                                                <>

                                                    <span style={{ marginBottom: 20 }}>
                                                        Por su bioseguridad y agilidad en la atención, solicitamos por favor tome fotos y nos envíe los siguientes documentos.
                                                    </span>
                                                    <br />
                                                    <div>
                                                        <a href='/help' style={{ marginTop: 10, textDecoration: 'underline !important' }} target='_blank' rel="noopener noreferrer">Más Ayuda</a>
                                                    </div>
                                                </>
                                            }

                                        </div>
                                        <hr />

                                        {pendingRequiredDocuments && (
                                            <h6>
                                                Documentos Requeridos
                                            </h6>
                                        )}

                                        {notUploadedDocuments.map((requiredDoc, i) => (
                                            <div key={i}>
                                                {renderDocumentComponent(requiredDoc, i)}
                                            </div>
                                        ))}

                                        {uploadedDocuments.length > 0 && (
                                            <h6>
                                                Documentos Recibidos
                                            </h6>
                                        )}

                                        {uploadedDocuments.map((requiredDoc, i) => (
                                            <div key={i}>
                                                {renderDocumentComponent(requiredDoc, i)}
                                            </div>
                                        ))}



                                    </div>
                                )}

                                {!(allDocumentsUploaded || allDocumentsApproved) && (
                                    <div className={classes.subSections} style={{ marginTop: 0, borderTopColor: "#c0c0c0", borderTopWidth: 1 }}>
                                        <DocumentContact
                                            onShow={(show) => {
                                                setShowDocumentContact(show);
                                                showActionResult(show);
                                            }}
                                            onSuccess={showContactAddedSuccessfully}
                                        />
                                    </div>
                                )}


                            </>
                        )
                    }
                </>
            )}


        </div>
    )
}

export default AppointmentTruckerDocs;