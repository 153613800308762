import React, { useState, useEffect } from 'react'
import {
    Snackbar
} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert';
import { useSelector, useDispatch } from 'react-redux';
import { clearGeneralError } from 'actions/generalErrorActions'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const GeneralError = () => {
    const [open, setOpen] = useState(false),
        generalError = useSelector(state => state.generalError),
        dispatch = useDispatch();

    useEffect(() => {
        if (generalError?.showError) {
            setOpen(true);
        }
    }, [generalError])


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    const onExited = () => {
        dispatch(clearGeneralError());
    }
    if (!generalError) return null;

    return (
        <Snackbar open={open} autoHideDuration={6000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={handleClose}
            onExited={onExited}>
            <Alert onClose={handleClose}
                severity="error">
                {generalError.message}
            </Alert>
        </Snackbar>
    )
}
export default GeneralError 