import React, { useState, useEffect } from 'react';
import {
    useDispatch,
    useSelector
} from 'react-redux'

import { Row, Col } from 'reactstrap';
import ContainerValidator from 'container-validator'
import useReactRouter from 'use-react-router';
import clsx from 'clsx';
import { useForm } from "react-hook-form";
import {
    Grid,
    Button,
    TextField,
    ThemeProvider,
    useTheme,
    makeStyles,
    MenuItem,
    CircularProgress,
    Card,
    CardContent,
    Typography,
    withWidth,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    //InputAdornment,
    Link,
    IconButton
} from '@material-ui/core'
import { green } from '@material-ui/core/colors';
import {
    NavigateNext as NavigateNextIcon,
    Warning,
    Search
} from '@material-ui/icons'
import {
    UploadAttachmentComodato,
    UploadAttachmentCartaCliente,
    UploadAttachmentOrdenCargue
} from '../../apointmentAttachment'
import API from 'services/api'
import {
    setAppointmentParameters
} from 'actions/appointmentActions'


const useStyles = makeStyles((theme) => ({
    subSections: theme.subSections,
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
        width: 'fit-content'
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 150,
    },
    root__input: {
        '& label': {
            color: 'green',
        },
        '& label.Mui-focused': {
            color: 'green',
        },
        '& input:valid + fieldset': {
            borderColor: 'green',
            borderWidth: 2,
        },
        '& input:invalid + fieldset': {
            borderColor: 'red',
            borderWidth: 2,
        },
        '& input:valid:focus + fieldset': {
            borderColor: 'green',
            borderWidth: 2,
        },
    },
    warning__message__icon: {
        marginRight: theme.spacing(),
        fontSize: '14pt',
        color: 'orange',
    },
}));



const OperationComponent = (props) => {
    const { setStep, width } = props,
        theme = useTheme(),
        classes = useStyles(),
        { history } = useReactRouter(),
        { handleSubmit, errors, setError, clearError } = useForm(),
        appointment = useSelector(state => state.appointment),
        generalParameters = useSelector(state => state.generalParameters),
        [fetchingClient, setFetchingClient] = useState(false),
        [clientNameError, setClientNameError] = useState(''),
        [errorMessage, setErrorMessage] = useState([]),
        [openInfo, setOpenInfo] = useState(false),
        [loading, setLoading] = useState(false),
        [validate, setValidate] = useState(false),
        [tipoOperacion, setTipoOperacion] = useState(appointment.appointment && appointment.appointment.operationType ? appointment.appointment.operationType : null),
        [liner, setLiner] = useState(appointment.appointment && appointment.appointment.linerCode ? appointment.appointment.linerCode : null),
        [selectedSize, setSelectedSize] = useState(appointment.appointment && appointment.appointment.containerSize ? appointment.appointment.containerSize : ''),
        [showfields, setShowfields] = useState(appointment.appointment && appointment.appointment.operationType ? true : false),
        [selectedContainerType, setSelectedContainerType] = useState(appointment.operations?.length > 0 ? `${appointment.operations[0].containerType}${appointment.operations[0].containerSize}` : ''),
        [selectedContainerStatus, setSelectedContainerStatus] = useState(appointment.operations?.length > 0 ? appointment.operations[0].containerStatusId : ''),
        [appointmentPopulated, setAppointmentPopulated] = useState(false),
        [inputs, setInputs] = useState({
            containerNumber: (appointment.appointment && appointment.appointment.containerNumber ? appointment.appointment.containerNumber : ''),
            clientName: (appointment.operations?.length > 0 && appointment.operations[0].clientName ? appointment.operations[0].clientName : ''),
            clientRif: (appointment.operations?.length > 0 && appointment.operations[0].clientRif ? appointment.operations[0].clientRif : ''),
            impoDevolucion: (appointment.operations?.length > 0 && appointment.operations[0].impoDevolucion ? appointment.operations[0].impoDevolucion : ''),
            containerType: (appointment.operations?.length > 0 && appointment.operations[0].containerType ? appointment.operations[0].containerType : null),
            containerStatus: (appointment.operations?.length > 0 && appointment.operations[0].containerStatus ? appointment.operations[0].containerStatus : null),
            size: (appointment.operations?.length > 0 && appointment.operations[0].containerSize ? appointment.operations[0].containerSize : null),
            truckPlate: (appointment.appointment && appointment.appointment.truckPlate ? appointment.appointment.truckPlate : ''),
        }),
        dispatch = useDispatch();


    let {
        liners,
        containerTypes,
        operationsTypes,
        containerStatuses } = generalParameters;



    useEffect(() => {
        if (appointment.appointment && !appointmentPopulated) {
            setAppointmentPopulated(true)
            setTipoOperacion(appointment.appointment.operationType || null)
            setLiner(appointment.appointment.linerCode || null)
            if (appointment.appointment.operationType && appointment.appointment.linerCode) {
                setTipoOperacion(appointment.appointment.operationType);
                setLiner(appointment.appointment.linerCode);

                setInputs({
                    containerNumber: (appointment.appointment && appointment.appointment.containerNumber ? appointment.appointment.containerNumber : ''),
                    clientName: (appointment.operations?.length > 0 && appointment.operations[0].clientName ? appointment.operations[0].clientName : ''),
                    clientRif: (appointment.operations?.length > 0 && appointment.operations[0].clientRif ? appointment.operations[0].clientRif : ''),
                    impoDevolucion: (appointment.operations?.length > 0 && appointment.operations[0].impoDevolucion ? appointment.operations[0].impoDevolucion : ''),
                    containerType: (appointment.appointment && appointment.appointment.containerType ? appointment.appointment.containerType : null),
                    size: (appointment.operations?.length > 0 && appointment.operations[0].containerSize ? appointment.operations[0].containerSize : null),
                    truckPlate: (appointment.appointment && appointment.appointment.truckPlate ? appointment.appointment.truckPlate : ''),
                    bookingBlNumber: (appointment.appointment && appointment.appointment.bookingBlNumber ? appointment.appointment.bookingBlNumber : ''),
                });

                switch (appointment.appointment.operationType) {
                    case 'impo':
                        if (appointment.appointment.containerNumber ||
                            appointment.appointment.bookingBlNumber ||
                            appointment.operations[0].containerType ||
                            appointment.operations[0].containerSize ) {
                            setSelectedContainerType(appointment.operations?.length > 0 && appointment.operations[0].containerType ? `${appointment.operations[0].containerType}${appointment.operations[0].containerSize}` : null)
                            setSelectedSize(appointment.operations?.length > 0 && appointment.operations[0].containerSize ? appointment.operations[0].containerSize : null);
                            setSelectedContainerStatus(appointment.operations?.length > 0 ? appointment.operations[0].containerStatusId : '');
                            setShowfields(true);
                        }
                        break;
                    case 'expo':
                        if (appointment.appointment.containerNumber ||
                            appointment.appointment.bookingBlNumber ||
                            appointment.operations[0].containerType ||
                            appointment.operations[0].containerSize  ||
                            appointment.appointment.truckPlate) {
                            setSelectedContainerType(appointment.operations?.length > 0 && appointment.operations[0].containerType ? `${appointment.operations[0].containerType}${appointment.operations[0].containerSize}` : null)
                            setSelectedSize(appointment.operations?.length > 0 && appointment.operations[0].containerSize ? appointment.operations[0].containerSize : null)
                            setSelectedContainerStatus(appointment.operations?.length > 0 ? appointment.operations[0].containerStatusId : '');
                            setShowfields(true);
                        }
                        break;
                    case 'repo':
                        if (appointment.appointment.truckPlate) {
                            setShowfields(true);
                        }
                        break;

                    default:
                        break;
                }

            }
        }
    }, [appointment, appointmentPopulated])

    let validator = new ContainerValidator();


    const handleChangeContainerStatus = event => {
        setSelectedContainerStatus(event.target.value);
    }

    const handleChangeContainerType = (event) => {
        setSelectedContainerType(event.target.value)


        if (event.target.value) {
            let type = event.target.value.slice(0, 2),
                size = event.target.value.slice(2);
            setSelectedSize(size);

            setInputs({
                ...inputs,
                containerType: type,
                size: size
            })
        } else {
            setInputs({
                ...inputs,
                containerType: null,
                size: null
            })
        }
    }

    const buttonClassname = clsx({
        [classes.buttonSuccess]: false,
    });

    const validateParameters = () => {
        let valid = true,
            newErrorMessage = [];

        setErrorMessage([])

        if (tipoOperacion === 'impo') {
            if (!inputs.containerNumber || !validator.isValid(inputs.containerNumber)) {
                valid = false;
                newErrorMessage.push(!inputs.containerNumber ? 'Número de contenerdor requerido' : 'Número de contenedor inválido')
                setError("contenedorNumeroInvalido", "invalid", "Número de contenedor inválido");
            } else {
                clearError("contenedorNumeroInvalido");
            }

            if (!inputs.containerType) {
                valid = false;
                newErrorMessage.push('Tipo de Contenedor es requerido')
                setError("containerType", "invalid", "");
            } else {
                clearError("containerType");
            }

            if (!inputs.bookingBlNumber) {
                valid = false;
                newErrorMessage.push('Booking Number es requerido')
                setError("bookingBlNumber", "invalid", "");
            } else {
                clearError("bookingBlNumber");
            }
        }

        if (tipoOperacion === 'expo') {


            if (!inputs.containerType) {
                valid = false;
                newErrorMessage.push('Tipo de Contenedor es requerido')
                setError("containerType", "invalid", "");
            } else {
                clearError("containerType");
            }

            if (!inputs.bookingBlNumber) {
                valid = false;
                newErrorMessage.push('BL es requerido')
                setError("bookingBlNumber", "invalid", "");
            } else {
                clearError("bookingBlNumber");
            }
        }

        if (!inputs.clientName) {
            valid = false;
            newErrorMessage.push('Nombre del Cliente es requerido')
            setError("clientName", "invalid", "");
        } else {
            clearError("clientName");
        }
        if (!inputs.clientRif) {
            valid = false;
            newErrorMessage.push('NIT del Cliente es requerido')
            setError("clientRif", "invalid", "");
        } else {
            clearError("clientRif");
        }

        if (!inputs.truckPlate || (inputs.truckPlate && inputs.truckPlate.length < 5)) {
            valid = false;
            newErrorMessage.push(!inputs.truckPlate ? 'La placa del camión es requerida' : 'El número de placa debe tener entre 5 y 7 caracteres')
            setError("truckPlate", "invalid", "");
            if ((inputs.truckPlate && inputs.truckPlate.length < 5)) setError("truckPlateDigits", "invalid", "")
        } else {
            clearError("truckPlate");
            clearError("truckPlateDigits");
        }

        if (!valid) {
            setErrorMessage(newErrorMessage)
            setOpenInfo(true)
        }

        return valid;
    }

    const handleChangeInput = (e) => {
        setInputs({
            ...inputs,
            [e.target.name]: e.target.value
        })
    }

    const validateClientRif = () => {
        let valid = true;
        if (!inputs?.clientRif?.trim()) {
            valid = false;
            setError("clientRif");
        } else {
            clearError("clientRif");
        }
        if (!valid) {
            setErrorMessage(['NIT del Cliente es requerido'])
            setOpenInfo(true)
        }
        return valid;
    }

    const getClient = () => {
        clearError("clientRif");
        setErrorMessage([])
        if (validateClientRif()) {
            setFetchingClient(true)
            setClientNameError('')
            API.Clients.getClient({ clientNit: inputs.clientRif })
                .then(response => {
                    if (response.data) {
                        setInputs({
                            ...inputs,
                            clientName: response.data.name
                        })
                    } else {
                        setClientNameError('No hay cliente registrado con este NIT')
                    }
                    setFetchingClient(false)
                }).catch(err => {
                    console.log(err)
                })
                .then(_ => {
                    setFetchingClient(false);
                })
        }
    }

    const onSubmitStep2 = data => {
        if (validateParameters()) {
            setLoading(true);
            API.Appointment.updateAppointment({
                parameters: {
                    ...data,
                    ...inputs,
                    linerCode: liner,
                    truckerId: (appointment.trucker.truckerId ? appointment.trucker.truckerId : appointment.trucker.id),
                    containerSize: selectedSize,
                    operationType: tipoOperacion,
                    containerStatusId: selectedContainerStatus
                },
                appointmentId: appointment.appointment.id
            })
                .then(response => {
                    setValidate(false)
                    if (response.success) {

                        dispatch(setAppointmentParameters(response.appointment))
                        setLoading(false)
                        setStep(3);
                        if (!response.appointment.confirmed) {
                            history.push(`/appointment/step/3?appointmentId=${response.appointment.id}`)
                        } else {
                            history.push(`/appointment/summary?appointmentId=${response.appointment.id}`)
                        }
                    }
                })
                .catch(err => {
                    console.log(err)
                })
                .then(_ => {
                    setLoading(false)
                })
        }
    }

    const handleChangeOperation = (opt) => {
        setValidate(true)
        if (opt.code !== tipoOperacion) {
            if (tipoOperacion == null) {
                setTipoOperacion(opt.code)
                setShowfields(true)
            } else {
                //TODO: ("CONFIRMA QUE SE QUIERE CAMBIAR PARA NO PERDER DATA")
                setTipoOperacion(opt.code)
                setShowfields(true)
            }
        } else {
            setTipoOperacion(null)
        }
    }

    const renderClientFields = () => {
        return (
            <>
                <Grid item xs={12} sm={6}
                    style={{
                        paddingTop: 0, paddingBottom: 0
                    }}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <TextField
                            variant="outlined"
                            style={{ width: "100%" }}
                            label="NIT del cliente *"
                            name="clientRif"
                            fullWidth
                            margin="normal"
                            onChange={(e) => {
                                if (e.target.value.length >= 11) {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    return
                                }
                                handleChangeInput(e)
                            }}
                            value={inputs.clientRif}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onBlur={getClient}
                            error={errors.clientRif}
                        />
                        <div style={{ marginLeft: 4 }}>
                            <IconButton onClick={getClient} size='small'>
                                <Search />
                            </IconButton>
                        </div>
                    </div>
                </Grid>

                <Grid item xs={10}
                    style={{ position: 'relative', paddingTop: 0, paddingBottom: 0 }}>
                    <TextField
                        variant="outlined"
                        style={{ width: "100%" }}
                        label="Nombre del cliente *"
                        name="clientName"
                        //placeholder="Nombre"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={fetchingClient}
                        onChange={handleChangeInput}
                        value={inputs.clientName}
                        error={errors.clientName}
                    />
                    {fetchingClient && <CircularProgress size={24} className={classes.buttonProgress} />}
                </Grid>
                {clientNameError &&
                    <div style={{ padding: '0 8px', display: 'flex', alignItems: 'center' }}>
                        <Warning className={classes.warning__message__icon} />
                        <Typography style={{ fontSize: 12 }}>
                            {clientNameError}
                        </Typography>
                    </div>
                }
            </>
        )
    }


    const renderOperationFields = () => {
        return (
            <Grid container spacing={2}
                style={{ marginTop: 10 }}>
                {/* Numero contenedor */}
                {tipoOperacion === 'impo' && (
                    <Grid item xs={12} sm={5}>
                        <TextField
                            variant="outlined"
                            style={{
                                width: "100%",
                            }}
                            classes={{
                                root: validator.isValid(inputs.containerNumber) ? classes.root__input : undefined//
                            }}
                            label="Contenedor *"
                            name="containerNumber"
                            onChange={e => {
                                let value = e.target.value;
                                value = value.replace(/[^a-zA-Z0-9]/g, '')
                                if (value.length > 11) return;
                                setInputs({
                                    ...inputs,
                                    containerNumber: value.toUpperCase()
                                })
                                if (validator.isValid(value)) {
                                    clearError("contenedorNumeroInvalido")
                                    clearError("containerNumber")
                                }
                            }}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={inputs.containerNumber}
                            error={errors.containerNumber || errors.contenedorNumeroInvalido}
                        />
                    </Grid>
                )}


                {/* BL/booking number y tipo contenedor */}
                {tipoOperacion !== 'repo' && (
                    <>
                        <Grid item xs={12} sm={5} >
                            <TextField
                                variant="outlined"
                                style={{ width: "100%" }}
                                label={tipoOperacion === 'impo' ? 'BL *' : 'Booking Number *'}
                                name="bookingBlNumber"
                                fullWidth
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => {
                                    setInputs({
                                        ...inputs,
                                        bookingBlNumber: e.target.value ? e.target.value.toUpperCase() : ''
                                    })
                                }}
                                value={inputs.bookingBlNumber}
                                error={tipoOperacion === 'impo' || tipoOperacion === 'expo' ? errors.bookingBlNumber : false}
                            />
                        </Grid>

                        {tipoOperacion === 'expo' && (
                            <Grid item xs={5} sm={5} ></Grid>
                        )}

                        <Grid item xs={5} sm={5} >
                            <TextField
                                variant="outlined"
                                style={{
                                    width: "100%",
                                    //marginTop: tipoOperacion === 'impo' ? 'unset' : 16 
                                }}
                                label="Tipo contenedor *"
                                name="size"
                                value={selectedContainerType}
                                onChange={handleChangeContainerType}
                                fullWidth
                                select
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={errors.containerType}
                            >
                                {containerTypes.map(type => (
                                    <MenuItem key={type.code}
                                        value={type.code}>{type.name}</MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                        <Grid item xs={5} sm={5} >
                            <TextField
                                variant="outlined"
                                style={{
                                    width: "100%",
                                }}
                                label="Uso del Contenedor"
                                name="size"
                                value={selectedContainerStatus}
                                onChange={handleChangeContainerStatus}
                                fullWidth
                                select
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={errors.containerStatus}
                            >
                                {containerStatuses.filter(s => s.active).map(type => (
                                    <MenuItem key={type.value}
                                        value={type.value}>{type.name}</MenuItem>
                                ))}
                            </TextField>
                        </Grid>


                    </>
                )}

                {(tipoOperacion === 'impo') && (
                    <>
                        <Grid item xs={12} sm={5}>
                            <TextField
                                variant="outlined"
                                style={{
                                    width: "100%"
                                }}
                                label="Sitio devolución"
                                name="impoDevolucion"
                                placeholder=""
                                fullWidth
                                onChange={handleChangeInput}
                                value={inputs.impoDevolucion}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={5} sm={5} ></Grid>
                    </>
                )}

                {renderClientFields()}


                <Grid item xs={10}
                    style={{ position: 'relative', paddingTop: 0 }}
                >
                    <TextField
                        variant="outlined"
                        style={{ width: "140px" }}
                        label="Placa Vehículo *"
                        name="truckPlate"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={e => {
                            if (e.target.value.length >= 7) {
                                e.preventDefault()
                                e.stopPropagation()
                                return
                            }
                            setInputs({
                                ...inputs,
                                truckPlate: e.target.value.toUpperCase()
                            })
                        }}
                        value={inputs.truckPlate}
                        error={errors.truckPlate}
                    />

                    {appointment?.trucker?.licensePlate && (appointment?.trucker?.licensePlate !== inputs?.truckPlate) && (
                        <>
                            <Typography variant='body2' color='textSecondary'>
                                El camionero tiene citas anteriores con el camión de placa  <Link
                                    component="button"
                                    color='primary'
                                    style={{
                                        marginRight: 5
                                    }}
                                    underline='hover'
                                    onClick={() => {
                                        setInputs({
                                            ...inputs,
                                            truckPlate: appointment.trucker.licensePlate.toUpperCase()
                                        })
                                    }}>{appointment?.trucker?.licensePlate} </Link>

                                Click <Link component="button" color='primary' underline='hover' onClick={() => {
                                    setInputs({
                                        ...inputs,
                                        truckPlate: appointment.trucker.licensePlate.toUpperCase()
                                    })
                                }}>aqui</Link> para usar esta placa.
                            </Typography>
                        </>
                    )}


                    {errors.truckPlateDigits && (
                        <Typography variant='body2' color='error' style={{ fontSize: '0.7em' }}>
                            {'Mínimo 5 caracteres'}
                        </Typography>
                    )}
                </Grid>


            </Grid>
        )
    }

    return <ThemeProvider theme={theme}>
        <div className="row">
            <div className="container">
                <form onSubmit={handleSubmit(onSubmitStep2)} autoComplete="off">
                    <Row style={{ marginBottom: 20 }}>
                        <Col lg="3"></Col>
                        <Col lg="6">
                            <Card className={classes.subSections}>
                                <CardContent style={{ display: 'flex', alignItems: 'center' }}>
                                    <Grid container spacing={2} style={{ marginTop: 10 }}>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                {liners.map(line => (
                                                    <Grid key={line.code}
                                                        item xs={6} style={{
                                                            display: 'flex',
                                                            justifyContent: 'center'
                                                        }}>
                                                        <Button
                                                            key={line.code}
                                                            variant={liner === line.code ? 'contained' : 'outlined'}
                                                            color='secondary'
                                                            style={{
                                                                width: '100%',
                                                                height: 60
                                                            }}
                                                            onClick={() => {
                                                                if (line.code !== liner) {
                                                                    setLiner(line.code)
                                                                } else {
                                                                    setLiner(null)
                                                                }
                                                            }}>
                                                            {width !== 'xs' ? line.name : line.code}
                                                        </Button>
                                                    </Grid>
                                                ))}
                                                <Grid item xs={12}>
                                                    <Typography variant='body2' color='error' style={{ fontSize: '0.7em' }}>
                                                        {tipoOperacion && (tipoOperacion === 'impo' || tipoOperacion === 'expo') && !liner ? "Línea es requerida" : ""}
                                                    </Typography>
                                                    <Divider />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                {operationsTypes.map(opt => (
                                                    <Grid key={opt.code} item xs={4} style={{
                                                        display: 'flex',
                                                        justifyContent: 'center'
                                                    }}>
                                                        <Button
                                                            key={opt.code}
                                                            variant={tipoOperacion === opt.code ? 'contained' : 'outlined'}
                                                            color='primary'
                                                            style={{
                                                                width: width !== 'xs' ? 120 : 90,
                                                                height: 60
                                                            }}
                                                            onClick={() => {
                                                                handleChangeOperation(opt)
                                                            }}>
                                                            {width !== 'xs' ? opt.name : opt.code}
                                                        </Button>
                                                    </Grid>
                                                ))}
                                                {!tipoOperacion && (
                                                    <Grid item xs={12}>
                                                        {validate && (
                                                            <Typography variant='body2' color='error' style={{ fontSize: '0.7em', marginBottom: 5 }}>
                                                                {!tipoOperacion ? "Tipo de operación requerido" : ""}
                                                            </Typography>
                                                        )}
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </CardContent>
                            </Card>
                        </Col>
                        <Col lg="3"></Col>
                    </Row>

                    {showfields && <>

                        {tipoOperacion && (
                            <Row style={{ marginBottom: 20 }}>
                                <Col lg="3" />
                                <Col lg="6">
                                    <Card className={classes.subSections}>
                                        <CardContent style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}>
                                            {renderOperationFields()}
                                        </CardContent>
                                    </Card>
                                </Col>
                                <Col lg="3" />
                            </Row>
                        )}

                        {(tipoOperacion === 'impo') && <>
                            <UploadAttachmentComodato
                                appointment={appointment}
                            >
                                <div id='comodato' />
                            </UploadAttachmentComodato>
                        </>}

                        {(tipoOperacion === 'repo' || tipoOperacion === 'expo') && <>
                            <UploadAttachmentOrdenCargue
                                appointment={appointment}
                            >
                                <div id='orderCargue' />
                            </UploadAttachmentOrdenCargue>
                        </>}

                        {(tipoOperacion === 'expo') && <>
                            <UploadAttachmentCartaCliente
                                appointment={appointment}
                            >
                                <div id="cartaCliente" />
                            </UploadAttachmentCartaCliente>

                        </>}

                        {tipoOperacion && <>
                            <Row style={{ marginTop: 20 }}>
                                <Col lg="3"></Col>
                                <Col className={`col ${classes.subSections}`}>
                                    <Row style={{ padding: '0 16px' }}>
                                        <div />
                                        <div className={classes.root}>
                                            <div className={classes.wrapper}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    style={{ width: 210 }}
                                                    className={buttonClassname}
                                                    disabled={loading}
                                                    type="submit"
                                                    endIcon={<NavigateNextIcon />}
                                                >
                                                    Continuar
                                                </Button>
                                                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                            </div>
                                        </div>
                                    </Row>
                                </Col>
                                <Col lg="3"></Col>

                            </Row>
                        </>}
                    </>}
                </form>
            </div>
            <Dialog
                open={openInfo}
                onClose={() => {
                    setOpenInfo(false)
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Warning className={classes.warning__message__icon} />
                    {"Los siguientes campos deben ser revisados"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {errorMessage.length > 0 && errorMessage.map((error, i) => (
                            <Typography key={i}>
                                - {error}
                            </Typography>
                        ))}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setOpenInfo(false)
                        }} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    </ThemeProvider>
}

export default withWidth()(OperationComponent)