import config from 'config';
import { utils } from 'lib'
const Appointment = {
    validateUser: () => {
        if (!localStorage.getItem('pcc_account_id')) {
            localStorage.removeItem('pcc_jwt_token')
            localStorage.removeItem('pcc_account_id')
            localStorage.removeItem('pcc_account_transportador_id')
            localStorage.removeItem('pcc_account_profileId')
            return window.location.href = `${config.web.url}`;
        }
        return true;
    },
    getAppointmentsList: () => {
        Appointment.validateUser();
        return new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/v1/citas`;
            var headers = new Headers();
            headers.append("x-api-key", config.apiGateway.api_key);
            headers.append("Authorization", localStorage.getItem('pcc_jwt_token'));
            headers.append("x-user-id", localStorage.getItem('pcc_account_id'));

            fetch(url, {
                headers
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    utils.validateAndShowApiError(jsonResponse)
                    resolve(jsonResponse);
                }).catch(err => {
                    utils.showGeneralError({ message: err.message || "Unhandled error", error: err });
                    reject(err);
                });
        })
    },
    getAppointmentsListAdmin: () => {
        Appointment.validateUser();
        return new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/v1/citas/admin`;
            var headers = new Headers();
            headers.append("x-api-key", config.apiGateway.api_key);
            headers.append("Authorization", localStorage.getItem('pcc_jwt_token'));
            headers.append("x-user-id", localStorage.getItem('pcc_account_id'));

            fetch(url, {
                headers
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    utils.validateAndShowApiError(jsonResponse)
                    resolve(jsonResponse);
                }).catch(err => {
                    utils.showGeneralError({ message: err.message || "Unhandled error", error: err });
                    reject(err);
                });
        })
    },
    createAppointment: ({ yardId, camioneroId }) => {
        Appointment.validateUser();
        return new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/v1/citas/camionero/cita`;
            var headers = new Headers();
            headers.append("x-api-key", config.apiGateway.api_key);
            headers.append("Authorization", localStorage.getItem('pcc_jwt_token'));
            headers.append("x-user-id", localStorage.getItem('pcc_account_id'));
            let transportistaId = localStorage.getItem('pcc_account_transportador_id');

            let parameters = {
                truckerId: camioneroId,
                yardId,
                transportadorId: transportistaId,
            };

            fetch(url, {
                headers,
                body: JSON.stringify(parameters),
                method: 'POST',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    utils.validateAndShowApiError(jsonResponse)
                    resolve(jsonResponse);
                }).catch(err => {
                    utils.showGeneralError({ message: err.message || "Unhandled error", error: err });
                    reject(err);
                });
        })
    },
    updateAppointment: ({ appointmentId, parameters }) => {
        Appointment.validateUser();
        return new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/v1/citas/${appointmentId}`;
            var headers = new Headers();
            headers.append("x-api-key", config.apiGateway.api_key);
            headers.append("Authorization", localStorage.getItem('pcc_jwt_token'));
            headers.append("x-user-id", localStorage.getItem('pcc_account_id'));
            let transportistaId = localStorage.getItem('pcc_account_transportador_id');

            parameters.transportadorId = transportistaId

            fetch(url, {
                headers,
                body: JSON.stringify(parameters),
                method: 'PUT',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    utils.validateAndShowApiError(jsonResponse)
                    resolve(jsonResponse);
                }).catch(err => {
                    utils.showGeneralError({ message: err.message || "Unhandled error", error: err });
                    reject(err);
                });
        })
    },
    getAppointment: ({ appointmentId }) => {
        Appointment.validateUser();
        return new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/v1/citas/${appointmentId}`;
            var headers = new Headers();
            headers.append("x-api-key", config.apiGateway.api_key);
            headers.append("Authorization", localStorage.getItem('pcc_jwt_token'));
            headers.append("x-user-id", localStorage.getItem('pcc_account_id'));

            fetch(url, {
                headers
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    utils.validateAndShowApiError(jsonResponse)
                    resolve(jsonResponse);
                }).catch(err => {
                    utils.showGeneralError({ message: err.message || "Unhandled error", error: err });
                    reject(err);
                });
        })
    },
    getAppointmentPublic: ({ appointmentId }) => {
        return new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/v1/citas/${appointmentId}/public`;
            var headers = new Headers();
            headers.append("x-api-key", config.apiGateway.api_key);
            fetch(url, {
                headers
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    utils.validateAndShowApiError(jsonResponse)
                    resolve(jsonResponse);
                }).catch(err => {
                    utils.showGeneralError({ message: err.message || "Unhandled error", error: err });
                    reject(err);
                });
        })
    },
    uploadAppointmentAttachments: ({ documentType, filesAttachments, appointmentId }) => {
        Appointment.validateUser();
        return new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/v1/citas/${appointmentId}/files`;
            var headers = new Headers();
            headers.append("x-api-key", config.apiGateway.api_key);
            headers.append("Authorization", localStorage.getItem('pcc_jwt_token'));
            headers.append("x-user-id", localStorage.getItem('pcc_account_id'));

            let body = {
                documentType
            }
            var data = new FormData()
            Object.keys(body).forEach(key => {
                if (body[key]) {
                    data.append(key, typeof body[key] === 'object' ? JSON.stringify(body[key]) : body[key])
                }
            })

            if (filesAttachments) {
                for (const file of filesAttachments) {
                    data.append('files', file, encodeURI(file.name))
                }
            }

            fetch(url, {
                body: data,
                method: 'POST',
                headers
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    utils.validateAndShowApiError(jsonResponse)
                    resolve(jsonResponse);
                }).catch(err => {
                    utils.showGeneralError({ message: err.message || "Unhandled error", error: err });
                    reject(err);
                });
        })
    },
    deleteAppointment: ({ appointmentId, fileId }) => {
        Appointment.validateUser();
        return new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/v1/citas/${appointmentId}/files/${fileId}`;
            var headers = new Headers();
            headers.append("x-api-key", config.apiGateway.api_key);
            headers.append("Authorization", localStorage.getItem('pcc_jwt_token'));
            headers.append("x-user-id", localStorage.getItem('pcc_account_id'));

            fetch(url, {
                headers,
                method: 'DELETE',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    utils.validateAndShowApiError(jsonResponse)
                    resolve(jsonResponse);
                }).catch(err => {
                    utils.showGeneralError({ message: err.message || "Unhandled error", error: err });
                    reject(err);
                });
        })
    },
    saveAppointmentDateTimeSlot: ({ appointmentId, date, slotId, slot, dateTimestamp }) => {
        Appointment.validateUser();
        return new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/v1/citas/${appointmentId}/dateTimeSlot`;
            var headers = new Headers();
            headers.append("x-api-key", config.apiGateway.api_key);
            headers.append("Authorization", localStorage.getItem('pcc_jwt_token'));
            headers.append("x-user-id", localStorage.getItem('pcc_account_id'));
            let parameters = {
                date, slot,
                slotId: slotId,//TODO: <<<< Enviar el id del slot seleccionado
                dateTimestamp: dateTimestamp //TODO:<<<<< Enviar la fecha del slot seleccionado
            }

            fetch(url, {
                headers,
                body: JSON.stringify(parameters),
                method: 'POST',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    utils.validateAndShowApiError(jsonResponse)
                    resolve(jsonResponse);
                }).catch(err => {
                    utils.showGeneralError({ message: err.message || "Unhandled error", error: err });
                    reject(err);
                });
        })
    },
    confirmAppointment: ({ appointmentId }) => {
        Appointment.validateUser();
        return new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/v1/citas/${appointmentId}/confirm`;
            var headers = new Headers();
            headers.append("x-api-key", config.apiGateway.api_key);
            headers.append("Authorization", localStorage.getItem('pcc_jwt_token'));
            headers.append("x-user-id", localStorage.getItem('pcc_account_id'));

            fetch(url, {
                headers,
                method: 'POST',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    utils.validateAndShowApiError(jsonResponse)
                    resolve(jsonResponse);
                }).catch(err => {
                    utils.showGeneralError({ message: err.message || "Unhandled error", error: err });
                    reject(err);
                });
        })
    },
    cancelAppointment: ({ appointmentId, cancelReasonId }) => {
        Appointment.validateUser();
        return new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/v1/citas/${appointmentId}/cancel`;
            var headers = new Headers();
            headers.append("x-api-key", config.apiGateway.api_key);
            headers.append("Authorization", localStorage.getItem('pcc_jwt_token'));
            headers.append("x-user-id", localStorage.getItem('pcc_account_id'));

            fetch(url, {
                headers,
                method: 'PATCH',
                body: JSON.stringify({
                    cancelReasonId
                })
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    utils.validateAndShowApiError(jsonResponse)
                    resolve(jsonResponse);
                }).catch(err => {
                    utils.showGeneralError({ message: err.message || "Unhandled error", error: err });
                    reject(err);
                });
        })
    },
    uploadAppointmentPhoto: ({
        documentTypeId,
        truckerId,
        documentType,
        photoDataString,
        appointmentId
    }) => {
        return new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/v1/citas/${appointmentId}/photo`;
            var headers = new Headers();
            headers.append("x-api-key", config.apiGateway.api_key);

            let body = {
                documentType,
                photo: photoDataString,
                userId: 44,//TODO: Temporalmente se suben con el id del usuario Sistema. Se debe cambiar cuando se cmabie la logica del camionero
                truckerId,
                documentTypeId
            }

            fetch(url, {
                body: JSON.stringify(body),
                method: 'POST',
                headers
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    utils.validateAndShowApiError(jsonResponse)
                    resolve(jsonResponse);
                }).catch(err => {
                    utils.showGeneralError({ message: err.message || "Unhandled error", error: err });
                    reject(err);
                });
        })
    },
    uploadYardOperationAttachments: ({ documentType, documentTypeId, truckerId, filesAttachments, appointmentId }) => {
        return new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/v1/citas/${appointmentId}/yardOperationDocument`;
            var headers = new Headers();
            headers.append("x-api-key", config.apiGateway.api_key);

            let body = {
                documentType,
                truckerId,
                documentTypeId
            }
            var data = new FormData()
            Object.keys(body).forEach(key => {
                if (body[key]) {
                    data.append(key, typeof body[key] === 'object' ? JSON.stringify(body[key]) : body[key])
                }
            })

            if (filesAttachments) {
                for (const file of filesAttachments) {
                    data.append('files', file, encodeURI(file.name))
                }
            }

            fetch(url, {
                body: data,
                method: 'POST',
                headers
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    utils.validateAndShowApiError(jsonResponse)
                    resolve(jsonResponse);
                }).catch(err => {
                    utils.showGeneralError({ message: err.message || "Unhandled error", error: err });
                    reject(err);
                });
        })
    },
    createContact: ({
        fistName,
        lastName,
        email,
        mobile,
        yardOperationId }) => {
        return new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/v1/citas/yardOperation/publicDocumentsContact`;
            var headers = new Headers();
            headers.append("x-api-key", config.apiGateway.api_key);

            let body = {
                fistName,
                lastName,
                email,
                mobile,
                yardOperationId
            }

            fetch(url, {
                body: JSON.stringify(body),
                method: 'POST',
                headers
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    utils.validateAndShowApiError(jsonResponse)
                    resolve(jsonResponse);
                }).catch(err => {
                    utils.showGeneralError({ message: err.message || "Unhandled error", error: err });
                    reject(err);
                });
        })
    },
    saveDocumentAction: ({
        yardOperationId,
        linkViewed,
        allFilesUploaded
    }) => {
        return new Promise((resolve, reject) => {
            let url = `${config.apiGateway.url}/v1/citas/yardOperation/publicDocumentsContact/actions`;
            var headers = new Headers();
            headers.append("x-api-key", config.apiGateway.api_key);

            let body = {
                yardOperationId,
                linkViewed,
                allFilesUploaded
            }

            fetch(url, {
                body: JSON.stringify(body),
                method: 'POST',
                headers
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    utils.validateAndShowApiError(jsonResponse)
                    resolve(jsonResponse);
                }).catch(err => {
                    utils.showGeneralError({ message: err.message || "Unhandled error", error: err });
                    reject(err);
                });
        })
    }
}

export default Appointment;