import React, { useState, useEffect } from 'react'
import queryString from 'query-string';
import {
    CircularProgress,
    makeStyles,
    ThemeProvider,
    useTheme
} from '@material-ui/core'
import {
    useDispatch,
    useSelector
} from 'react-redux'
import { useParams } from 'react-router-dom'
import 'moment/locale/es'
import moment from 'moment'

import API from 'services/api'
import { utils } from 'lib'
import {
    setAppointment,
    setFetchedAppointment
} from 'actions/appointmentActions'


import { AppointmentTruckerDocs } from 'components/appointment'

moment.locale('es');

const useStyles = makeStyles(theme => ({
    subSections: theme.subSections,
    loading__container: {
        width: '100vw',
        height: '75vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    error__container: {
        width: '100vw',
        height: '75vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    link: { textDecoration: 'underline !important', }
}))

const TruckerUploadAppointmentDocsV2 = (props) => {
    const [fetching, setFetching] = useState(true),
        [showError, setShowError] = useState(false),
        parameters = useParams(),
        classes = useStyles(),
        theme = useTheme(),
        [verified, setVerified] = useState(false),
        dispatch = useDispatch(),
        [showActionResult, setShowActionResult] = useState(false),
        generalParameters = useSelector(state => state.generalParameters),
        appointment = useSelector(state => state.appointment);


    let { patios } = generalParameters;

    useEffect(() => {
        let params = queryString.parse(window.location.search),
            appointmentId = null;

        if (params?.operationId || params?.appointmentToken) {
            try {
                appointmentId = params?.operationId ? params?.operationId : JSON.parse(atob(params?.appointmentToken)).appointmentId
            } catch (error) {
                console.log(error);
            }
        }

        // if (params?.appointmentId || params?.appointmentToken) {
        //     try {
        //         appointmentId = params?.appointmentId ? params?.appointmentId : JSON.parse(atob(params?.appointmentToken)).appointmentId
        //     } catch (error) {
        //         console.log(error);
        //     }
        // } else if (props?.match?.params?.appointmentId) {
        //     appointmentId = props.match.params.appointmentId;
        // }
        if (appointmentId) {
            setShowError(false);
            setFetching(false);
            API.Appointment.getAppointmentPublic({
                appointmentId
            })
                .then(response => {
                    if (response.success && response.data) {
                        dispatch(setAppointment(response.data))
                        dispatch(setFetchedAppointment())
                        API.Appointment.saveDocumentAction({
                            yardOperationId: response.data.appointment.id,
                            linkViewed: true
                        })
                    } else if (!response.data) {
                        setShowError(true);
                    }
                    setFetching(false);
                }).catch(err => {
                    console.log(err)
                })
                .then(_ => {
                    setFetching(false)
                })
        } else {
            setFetching(false)
            setShowError(true);
        }

    }, [setShowError, setFetching, dispatch, props, parameters])

    useEffect(() => {
        let isSubscribed = true;
        let params = queryString.parse(window.location.search);
        if (!verified && params.operationId) {
            API.Trucker.validatePhone({
                truckerId: parameters.truckerId,
                code: parameters.codigo,
                operationId: params.operationId
            }).then(_ => {
                if (isSubscribed) {
                    setVerified(true)
                }
            })
        }
        return () => {
            isSubscribed = false
        }
    }, [verified, parameters])


    const handleShowActionResult = (show) => {
        setShowActionResult(show)
    }

    return (
        <ThemeProvider theme={theme}>
            <section className=""
                style={{ minHeight: '100vh' }}>
                <div style={{
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex'
                }}>
                    <img src="/images/pcc_logo_whitebg.png" alt="Patio de Contenedores de Colombia Logo" height="80" />
                </div>

                {fetching && (
                    <div className={classes.loading__container}>
                        <CircularProgress />
                    </div>
                )}

                {!fetching && showError && (
                    <div className={classes.error__container}>
                        La página solicitada es inválida.
                    </div>
                )}

                {!fetching && appointment?.appointment && (
                    <section className="section" style={{
                        paddingTop: 0,
                        maxWidth: 400,
                        margin: 'auto'
                    }}>
                        {appointment.appointment.statusId < 3 ? (

                            <div className="container">
                                {!showActionResult && (
                                    <div className="features-content">
                                        <div className={`title-heading ${classes.subSections}`}
                                            style={{
                                                marginBottom: 20,
                                            }}>
                                            <h5 className="font-weight-normal text-dark mb-3 mt-4" style={{ marginBottom: 0 }}>{`Patio de ${appointment.appointment.yardId ? patios.find(p => p.id === appointment.appointment.yardId).name : ''}`}</h5>
                                            {/* <span>Llaménos al <a href="tel:+12028023130">(202)8023130</a></span>
                                            <br />
                                            <span>Escríbanos por <a href={`whatsapp://send?phone=+12028023130&text=Cita ${utils.uppercaseFirstLetter(moment(appointment.appointment.appointment_dateTime).format('dddd DD, hh:mm A'))}`}>Whatsapp</a></span>
                                            <br />
                                            <hr /> */}
                                            {appointment && appointment.appointment && (
                                                <div style={{ display: 'flex' }}>
                                                    <span>
                                                        Cita {utils.uppercaseFirstLetter(moment(appointment.appointment.appointment_dateTime).format('dddd DD MMMM, hh:mm A'))}
                                                    </span>
                                                </div>
                                            )}
                                            {appointment.trucker?.firstName ? (
                                                <span style={{ marginBottom: 0 }}>
                                                    {`${appointment.trucker.firstName} ${appointment.trucker.lastName || ''}`}
                                                </span>
                                            ) : (
                                                    <span>
                                                        Sin camionero asignado
                                                    </span>
                                                )}
                                            <br />
                                            <span>
                                                Placa {appointment.trucker.licensePlate.toUpperCase()}
                                            </span>
                                            <hr />
                                        </div>
                                    </div>

                                )}
                                <AppointmentTruckerDocs appointment={appointment}
                                    showActionResult={handleShowActionResult} />

                                {!showActionResult && (
                                    <>
                                        <hr />
                                        <div style={{ marginTop: 50 }}>
                                            <span style={{ fontSize: 12 }}>Al leer, navegar o usar este sitio web usted declara que ha leído y acepta las condiciones de nuestra POLITICA DE TRATAMIENTO DE DATOS PERSONALES. Acepta que NAUTISERVICIOS SAS procese su información personal para los propósitos de nuestro objeto social, de acuerdo a nuestra política publicada en <a href='http://www.patioscol.com' target='_blank' rel="noopener noreferrer" className={classes.link}>www.patioscol.com</a></span>
                                        </div>

                                    </>
                                )}
                            </div>
                        ) : (
                                <div className="container">
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        minHeight: '80vh'
                                    }}>
                                        <span>Esta cita ya fue atendida en el patio.</span>
                                    </div>
                                </div>
                            )}
                    </section>
                )}
            </section>
        </ThemeProvider>
    )
}

export default TruckerUploadAppointmentDocsV2;
