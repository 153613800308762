import React, { useState, useRef } from 'react'
import {
    Dialog,
    DialogContent,
    makeStyles,
    Typography,
    CircularProgress,
    DialogTitle,
    IconButton,
} from '@material-ui/core'
import {
    Close,
    Camera,
    Replay,
    Backup
} from '@material-ui/icons'
import { useSelector, useDispatch } from 'react-redux'
import {
    addAppointmentAttachments,
} from 'actions/appointmentActions'

import API from 'services/api'

const useStyles = makeStyles(theme => ({
    video__container: {
        width: '100%',
        height: "100%",
        position: 'relative'
    },
    video: {
        width: '100%'
    },
    loading__container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: 300
    },
    camera__controls: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        left: 0,
        background: 'rgba(0,0,0,0.5)',
        color: '#fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    camera__control: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: 100
    },
    header__container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    add__document__button: {
        maginTop: 10,
        cursor: 'pointer',
        padding: 10,
        borderRadius: 5,
        border: 'solid thin #007bff',
        '&:hover': {
            backgroundColor: 'rgba(0,133,255,0.3)'
        }
    }
}))

var localStream = null;

const CaptureCamera = (props) => {
    const [error, setError] = useState(null),
        [openCameraDialog, setOpenCameraDialog] = useState(false),
        [photoTaken, setPhotoTaken] = useState(false),
        [loading, setLoading] = useState(true),
        [uploading, setUploading] = useState(false),
        [snap, setSnap] = useState(null),
        { document, appointment, documentTypeId } = props,
        classes = useStyles(),
        video = useRef(),
        canvas = useRef(),
        dispatch = useDispatch(),
        generalParameters = useSelector(state => state.generalParameters),
        { documentTypes } = generalParameters,
        documentType = documentTypes.find(doc => doc.value === document);


    const takePhoto = () => {
        var context = canvas.current.getContext('2d'),
            width = video.current.videoWidth,
            height = video.current.videoHeight;
        if (width && height) {

            // Setup a canvas with the same dimensions as the video.
            canvas.current.width = width;
            canvas.current.height = height;
            canvas.current.display = 'block';

            // Make a copy of the current frame in the video on the canvas.
            context.drawImage(video.current, 0, 0, width, height);

            // Turn the canvas image into a dataURL that can be used as a src for our photo.
            var snapShot = canvas.current.toDataURL('image/jpeg');
            setSnap(snapShot)
            setPhotoTaken(true)
            deactivateCamera()
        }

    }
    const retakePhoto = () => {
        setLoading(true)
        setSnap(null)
        setPhotoTaken(false)
        activeCamera()
    }

    const activeCamera = () => {
        navigator.getMedia(
            {
                video: true,
            }, (stream) => {
                video.current.srcObject = stream;
                localStream = stream;
                video.current.play();
            }, (err) => {
                setError("There was an error with accessing the camera stream: " + err.name)
            });
    }

    const deactivateCamera = () => {
        if (video?.current) {
            video.current.src = "";
        }
        localStream && localStream.getTracks && localStream.getTracks().forEach((track) => {
            track.stop();
        });
        localStream = null;
    }


    const openCamera = () => {
        setOpenCameraDialog(true)
        activeCamera()
    }
    const closeCamera = () => {
        setOpenCameraDialog(false)
    }
    const onExited = () => {
        setLoading(true)
        setPhotoTaken(false)
        deactivateCamera()
        setSnap(null)
        setUploading(false)
    }

    const uploadPhoto = () => {
        setUploading(true)

        API.Appointment.uploadAppointmentPhoto({
            documentType: document,
            photoDataString: snap,
            appointmentId: appointment.appointment.id,
            documentTypeId,
            truckerId: appointment.trucker.truckerId
        }).then(response => {
            if (response.success) {
                dispatch(addAppointmentAttachments(response.data))
            }
        }).catch(err => {
            console.log(err)
        })
            .then(_ => {
                closeCamera()
            })
    }

    return (
        <div>
            <input type='button' onClick={openCamera} value={`Tomar foto de ${documentType.name}`} />
            <Dialog
                fullScreen={true}
                open={openCameraDialog}
                onClose={closeCamera}
                onExited={onExited}
            >
                {!loading && (
                    <DialogTitle>
                        <div className={classes.header__container}>
                            <Typography variant='h6'>
                                Capturar foto de {documentType.name}
                            </Typography>
                            <IconButton
                                size='small'
                                onClick={closeCamera}>
                                <Close />
                            </IconButton>
                        </div>
                    </DialogTitle>
                )}
                <DialogContent>

                    <div
                        className={classes.video__container}
                        style={{
                            height: !loading ? 'auto' : 0
                        }}>

                        {/* Video camara */}
                        <video ref={video}
                            style={{
                                display: !photoTaken ? 'block' : 'none',
                            }}
                            className={classes.video}
                            onCanPlay={() => {
                                setLoading(false)
                            }} />

                        {/* Foto tomada */}
                        <canvas
                            ref={canvas}
                            className={classes.video}
                            style={{
                                display: photoTaken ? 'block' : 'none'
                            }} />



                        {!loading && (
                            <div className={classes.camera__controls}>
                                {!uploading ? (
                                    <>
                                        <div className={classes.camera__control}>
                                            <IconButton onClick={!photoTaken ? takePhoto : retakePhoto}>
                                                {!photoTaken ?
                                                    <Camera style={{ color: '#fff' }} />
                                                    :
                                                    <Replay style={{ color: '#fff' }} />
                                                }
                                            </IconButton>
                                            <Typography>
                                                {!photoTaken ? 'Tomar foto' : 'Retomar foto'}
                                            </Typography>
                                        </div>
                                        {photoTaken && (
                                            <div className={classes.camera__control}
                                                style={{
                                                    marginLeft: 20
                                                }}>
                                                <IconButton onClick={uploadPhoto}>
                                                    <Backup style={{ color: '#fff' }} />
                                                </IconButton>
                                                <Typography>
                                                    {'Subir foto'}
                                                </Typography>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                        <div className={classes.camera__control}
                                            style={{
                                                width: 'auto'
                                            }}>
                                            <CircularProgress />
                                            <Typography>
                                                {'Subiendo foto...'}
                                            </Typography>
                                        </div>
                                    )}
                            </div>
                        )}
                    </div>
                    {loading && (
                        <div className={classes.loading__container}>
                            <CircularProgress />
                            <Typography variant='body1' color='textSecondary'>Cargando cámara...</Typography>
                        </div>
                    )}
                    {error && (
                        <Typography style={{ color: 'red' }}>
                            {error}
                        </Typography>
                    )}
                </DialogContent>
            </Dialog>
        </div>
    )
}
export default CaptureCamera;